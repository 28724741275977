<template>
  <div class="h-full pt-16 text-white">
    <div class="flex flex-col items-center">
      <h2 class="my-8 text-xl">Fahrzeugtyp wählen</h2>
      <button @click="selectDummyCar('LIMOUSINE')" class="bg-fiasco-blue rounded-xl p-4 w-48 mb-6">
        <img src="@/assets/gfx/icon_car_limousine.svg" />
        Limousine
      </button>
      <button @click="selectDummyCar('COUPE')" class="bg-fiasco-blue rounded-xl p-4 w-48 mb-6">
        <img src="@/assets/gfx/icon_car_coupe.svg" />
        Coupe
      </button>
      <button @click="selectDummyCar('SUV')" class="bg-fiasco-blue rounded-xl p-4 w-48 mb-6">
        <img src="@/assets/gfx/icons_autos_01.svg" />
        Kombi / SUV
      </button>
    </div>
    <div
      class="absolute bottom-4 left-4 w-14 h-14 p-1 rounded-full bg-transparent border-2 border-white"
      @click="goBack()"
    >
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateRequestCar",
  data() {
    return {
      editRequest: null,
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestCar" })
    },
    selectDummyCar(carType) {
      this.$store.commit("SET_DAMAGE_DUMMY_CAR", carType)
      this.$router.push({ name: "CreateRequestDamage" })
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      user: "users/user",
      damageTypes: "requests/damageTypes",
      damageLocations: "requests/damageLocations",
      damageLocationName: "requests/damageLocationName",
      damageTypeName: "requests/damageTypeName",
      selectedRequest: "requests/selectedRequest",
      damageDummyCar: "damageDummyCar",
    }),
  },
  watch: {
    carSelected() {
      console.log("WATCH carSelected")
      console.log(this.carSelected)
      /*
      if (!this.carSelected) {
        if (this.scene) {
          this.scene.dispose();
        }
      }
      */
    },
  },
  mounted() {
    this.getEditData()
    if (this.user) {
      this.selectDummyCar("LIMOUSINE")
    }
  },
}
</script>
