<template>
  <div class="absolute z-10 left-0 right-0 top-0 bottom-0 bg-slate-900">
    <vue-camera
      ref="webcam"
      class="vue-camera w-full h-full"
      :device-id="deviceId"
      @started="onStarted"
      @stopped="onStopped"
      @error="onError"
      @video-live="onVideoLive"
      @cameras="onCameras"
      :resolution="{ width: 1920, height: 1920 }"
    />
    <div class="absolute top-0 left-0 z-10 w-full h-full">
      >
      <slot></slot>
    </div>
    <div class="absolute z-20 bottom-0 w-full flex justify-center items-center">
      <button
        class="w-14 h-14 rounded-full bg-fiasco-blue m-2 p-3 disabled:opacity-50"
        :disabled="cameraPhotos.length >= 12 || cameraError"
        @click="switchCamera()"
      >
        <img src="@/assets/gfx/icon_flip_camera.png" />
      </button>
      <button
        class="w-16 h-16 rounded-full bg-fiasco-purple m-2 p-2 disabled:opacity-50"
        :disabled="cameraPhotos.length >= 12 || cameraError"
        @click="takePhoto"
      >
        <img src="@/assets/gfx/icon_camera.png" />
      </button>
      <div class="w-14 h-14 rounded-full bg-fiasco-blue m-2 p-3">
        <label for="file-select" class="input-camera disabled:opacity-50">
          <img class="input-camera-icon" src="@/assets/gfx/icon_gallery.png" />
        </label>
        <input
          id="file-select"
          class="w-0 h-0"
          type="file"
          accept="image/*"
          multiple
          :disabled="cameraPhotos.length >= 12"
          @change="onFileSelect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import VueCamera from "./VueCamera.vue"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Camera",
  components: {
    VueCamera,
  },
  data() {
    return {
      img: null,
      camera: null,
      cameraIndex: 0,
      deviceId: null,
      devices: [],
      cameraStarted: false,
      streamAvailable: false,
      cameraError: false,
    }
  },
  methods: {
    takePhoto() {
      if (!this.streamAvailable) return
      const photo = this.$refs.webcam.capture()
      this.$emit("grab", photo)
    },
    async onFileSelect(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.$store.commit("SET_BUSY", true)
      try {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const blob = await this.$store.dispatch("fileToSizedBlob", { file })
          await this.$store.commit("requests/ADD_CAMERA_PHOTO", blob)
          this.$store.commit("error/SET_ERROR", false)
        }
      } catch (err) {
        console.error("Error storing photos for later upload", err)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Error storing photos for later upload", err)
      }
      this.$store.commit("SET_BUSY", false)
    },
    // This event is fired after the camera has been started and when the video stream is available.
    onVideoLive() {
      this.streamAvailable = true
    },
    onStarted(stream) {
      console.log("On Started Event", stream)
      this.$store.commit("SET_BUSY", false)
      this.cameraStarted = true
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream)
      this.cameraStarted = false
      this.streamAvailable = false
    },
    stopCamera() {
      this.$refs.webcam.stop()
    },
    startCamera() {
      console.log("On Start")
      this.$refs.webcam.start()
    },
    onError(error) {
      console.log("On Error Event", error)
      this.cameraError = true
      this.$store.commit("SET_BUSY", false)
    },
    onCameras(cameras) {
      // Filter out "special" cameras and non-video media devices.
      this.devices = cameras.filter((d) => !d.label.includes("infrared") && d.kind == "videoinput")
      console.debug("On Cameras Event", cameras)
      this.tryToSelectBackfacingCamera()
    },
    tryToSelectBackfacingCamera() {
      const environmentCameras = this.devices.filter((d) =>
        /(environment|back|rück)/i.test(d.label)
      )
      if (environmentCameras.length > 0) {
        this.camera = environmentCameras[0]
      } else if (this.devices.length > 0) {
        this.camera = this.devices[0]
      }
      console.log("tryToSelectBackfacingCamera", this.camera)
      this.startCamera()
    },
    selectCamera(cam) {
      if (this.camera != cam) this.streamAvailable = false
      this.camera = cam
      console.log("On Camera Change Event", cam)
    },
    switchCamera() {
      this.cameraIndex++
      if (this.cameraIndex >= this.devices.length) {
        this.cameraIndex = 0
      }
      this.selectCamera(this.devices[this.cameraIndex])
    },
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId)
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      selectedRequest: "requests/selectedRequest",
      cameraPhotos: "requests/cameraPhotos",
    }),
  },
  watch: {
    camera: function (cam) {
      this.deviceId = cam.deviceId
    },
  },
  mounted() {
    // this.initCam()
    this.$store.commit("SET_BUSY", true)
  },
  beforeUnmount() {
    this.stopCamera()
  },
}
</script>
