<template>
  <div class="w-full h-full flex items-center flex-col justify-center">
    <img
      class="absolute top-2 w-full h-16 object-contain z-10"
      v-if="!whitelabel"
      src="@/assets/gfx/fiasco-logo-white.png"
    />
    <div class="max-w-sm w-full flex flex-col p-4">
      <p class="text-white text-lg text-center mb-8">
        Code oder QR erhältst du<br />von deiner Werkstatt
      </p>

      <button
        class="rounded-full bg-fiasco-purple text-white text-lg mb-4 h-12"
        @click="goRegisterCode()"
      >
        Code eingeben
      </button>
      <button
        class="rounded-full bg-fiasco-blue text-white text-lg mb-4 h-12"
        @click="goRegisterQr()"
      >
        QR scannen
      </button>
      <button
        class="rounded-full border-2 border-white text-white text-lg mb-4 h-12"
        @click="goIntro()"
      >
        Abbrechen
      </button>
    </div>
    <div class="flex absolute bottom-2 w-full justify-center">
      <div class="mx-2 text-white text-xs opacity-50" @click="openCustomerInfo()">
        Kundeninformationen
      </div>
      <div class="mx-2 text-white text-xs opacity-50" @click="openImprint()">Impressum</div>
      <div class="mx-2 text-white text-xs opacity-50" @click="openPrivacy()">Datenschutz</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "RegisterMenu",
  data() {
    return {
      error: null,
      loginForm: {
        email: "",
        inviteCode: "",
      },
    }
  },
  methods: {
    goRegisterCode() {
      this.$router.push({ name: "registerCode" })
    },
    goRegisterQr() {
      this.$router.push({ name: "registerQr" })
    },
    goIntro() {
      this.$router.push({ name: "intro" })
    },
    retry() {
      this.error = false
      this.loginForm.inviteCode = ""
    },
    async onSubmit() {
      this.$store.commit("SET_BUSY", true)
      this.error = null
      try {
        await this.$store.dispatch("stations/getByCode", this.loginForm.inviteCode)
        if (this.customerStation) {
          console.log(this.customerStation)
          this.$store.commit("customers/TEMP_LOGIN")
          this.$emit("login")
        } else {
          this.error = "Keine Werkstatt für diesen Code gefunden."
        }
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to register", err)
      }
    },
  },
  computed: {
    submitAvailable() {
      return this.loginForm.inviteCode.length > 2
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerStation: "stations/customerStation",
      whitelabel: "whitelabel",
    }),
  },
  mounted() {
    console.log("REGI MENU MOUNTED")
    console.log(this.$route.name)
  },
}
</script>
