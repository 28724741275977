<template>
  <div class="h-full pt-16 px-2 text-white overflow-y-auto relative">
    <template v-if="status == 'start'">
      <h2 class="text-xl text-center mt-2">Moment!</h2>
      <p class="text-lg text-center">
        Bevor es losgeht, brauchen wir noch deine<br />E-Mail-Adresse:
      </p>
      <div class="relative mx-6">
        <img
          class="h-[480px] w-[320px] mx-auto object-contain relative z-10 pointer-events-none"
          src="@/assets/gfx/fiasco-fox-plate.png"
        />
        <input
          class="absolute z-1 top-[313px] rounded-full h-14 p-2 w-full text-gray"
          type="email"
          placeholder="ich@email.de"
          v-model="editCustomer.email"
          @keyup.enter="onSubmit()"
        />
      </div>
      <!--form class="form"
          @submit.prevent="onSubmit()"
      -->
      <!--/form-->
    </template>

    <template v-if="status == 'logged_in'">
      <p class="center">Anmeldung erfolgreich, lade weitere Daten ...</p>
    </template>

    <template v-if="status == 'checking_email_address'">
      <h2 class="center">Einen Moment bitte ...</h2>
    </template>

    <template v-if="status == 'email_address_not_registered'">
      <h2 class="center">Gleich geht es weiter ...</h2>
    </template>

    <template v-if="status == 'email_address_already_registered'">
      <img class="w-12 block mx-auto" src="@/assets/gfx/icon-alert.png" />
      <h2 class="text-center text-2xl">E-Mail-Adresse schon registriert.</h2>
      <p class="text-center my-2">
        Diese E-Mail-Adresse ist schon registriert. Wir haben dir einen Link zum Anmelden geschickt.
        Du kannst dieses Fenster nun schließen und den Link in der E-Mail öffnen. Anschließend
        kannst du deine Anfrage neu erstellen.
      </p>

      <p class="text-center mx-2">
        Falls dein Endgerät deine E-Mails nicht empfangen kannst, scanne den QR-Code.
      </p>

      <button
        class="bg-fiasco-blue rounded-full h-14 px-4 block mt-4 mx-auto"
        @click="showQrCodeScanner()"
      >
        QR-Code aus Anmelde-Mail scannen
      </button>
    </template>

    <template v-if="status == 'qr_code_scanner' || status == 'qr_code_login_error'">
      <p>Scanner hier den QR-Code aus der Anmelde-E-Mail mit der Kamera dieses Gerätes.</p>

      <template v-if="status == 'qr_code_login_error'">
        <p class="intro-error">Die Anmeldung per QR-Code hat leider nicht funktioniert.</p>

        <p class="center">
          Falls dein Endgerät deine E-Mails nicht empfangen kannst, scanne den QR-Code.
        </p>

        <button class="center" @click="showQrCodeScanner()">
          QR-Code aus Anmelde-Mail scannen
        </button>
      </template>

      <div class="qr-code-login">
        <qr-stream @decode="onQrCodeDecode" @init="onQrCodeScannerReady" />
      </div>

      <button @click="goBack()">Zurück</button>
    </template>

    <template v-if="status == 'qr_code_scanner_error'">
      <p class="intro-error">Der QR-Code-Scanner konnte nicht gestartet werden.</p>

      <button @click="goBack()">Zurück</button>
    </template>

    <button
      class="absolute bottom-4 left-4 w-14 h-14 p-1 rounded-full bg-transparent border-2 border-white"
      @click="goBack()"
    >
      <img src="@/assets/gfx/arrow-prev.png" />
    </button>

    <button
      class="absolute bottom-4 right-4 w-14 h-14 p-1 rounded-full bg-fiasco-purple disabled:opacity-50"
      @click="onSubmit()"
      :disabled="!isInputValid() || status != 'start'"
    >
      <img src="@/assets/gfx/arrow-next.png" />
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateRequestIntro",
  data() {
    return {
      status: "start",
      editCustomer: {
        email: "",
      },
    }
  },
  methods: {
    goBack() {
      if (this.customer._id) {
        this.$router.push({ name: "main" })
      } else {
        this.$router.push({ name: "intro" })
      }
    },

    isInputValid() {
      return (
        !this.editCustomer.email.includes(" ") && // Do not allow spaces.
        this.editCustomer.email.includes("@") && // Require the '@'.
        this.editCustomer.email.length >= 3 // Enforce a minimum of three characters.
      )
    },

    async onSubmit() {
      if (!this.isInputValid()) return

      this.status = "checking_email_address"
      this.$store.commit("SET_BUSY", true)

      // Try to find out if this email address has been registered, yet.
      const payload = {
        email: this.editCustomer.email,
        station: this.customerStation._id,
      }

      let emailRequested
      try {
        emailRequested = await this.$store.dispatch("customers/requestEmailLoginLink", payload)

        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to send login email", err)
      }

      this.$store.commit("SET_BUSY", false)

      if (emailRequested) {
        this.status = "email_address_already_registered"
      } else {
        this.status = "email_address_not_registered"
        this.updateState()
        this.$router.push({ name: "CreateRequestPhotosExample" })
      }
    },

    updateState() {
      this.$store.commit("customers/FORCE_CUSTOMER_DATA", this.editCustomer)
    },

    getEditData() {
      this.editCustomer.email = this.customer.email
    },

    showQrCodeScanner() {
      this.status = "qr_code_scanner"
    },

    async onQrCodeDecode(decodedString) {
      const prefix = `${process.env.VUE_APP_BASE_URL_APP}/#/mlog/`
      if (!decodedString.toLowerCase().startsWith(prefix.toLowerCase())) {
        return
      }

      const token = decodedString.substring(prefix.length)
      if (!token) {
        return
      }

      console.debug(`Found token in QR code: ${token}`)

      this.$store.commit("customers/SET_LOGIN_ERROR", null)
      this.$store.commit("SET_BUSY", true)

      let response
      try {
        response = await this.$store.dispatch("customers/authWithMagicToken", token)
      } catch (err) {
        this.$store.commit("customers/SET_LOGIN_ERROR", err)
        this.$store.commit("SET_BUSY", false)
        this.status = "qr_code_login_error"
        return
      }

      this.$store.commit("SET_BUSY", false)

      if (!response) {
        this.$store.commit("customers/SET_LOGIN_ERROR", "Could not authenticate against the server")
        this.status = "qr_code_login_error"
        return
      }

      this.status = "logged_in"
      this.$emit("login")
    },

    async onQrCodeScannerReady(promise) {
      this.$store.commit("SET_BUSY", true)

      try {
        await promise
        // successfully initialized
      } catch (error) {
        this.status = "qr_code_scanner_error"

        if (error.name === "NotAllowedError") {
          // user denied camera access permisson
        } else if (error.name === "NotFoundError") {
          // no suitable camera device installed
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
        }
      } finally {
        this.$store.commit("SET_BUSY", false)
      }
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerStation: "stations/customerStation",
    }),
  },
  mounted() {
    console.log("CREATE REQUEST INTRO MOUNTED")

    // For customers that are already logged in we don't need to ask for an email address.
    if (this.customer._id) {
      this.$router.push({ name: "CreateRequestPhotosExample" })
    } else {
      this.getEditData()
    }
  },
}
</script>
