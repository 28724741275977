<template>
  <div class="h-full p-4 text-white flex flex-col justify-center">
    <div class="intro-inner">
      <div class="intro-nav">
        <template v-if="status == 'logging_in'">
          <p class="text-center">Anmeldung läuft ...</p>
        </template>

        <template v-if="status == 'logged_in'">
          <p class="text-center">Anmeldung erfolgreich, lade weitere Daten ...</p>
        </template>

        <template v-if="status == 'login_error'">
          <p class="intro-error mb-2">
            Das hat nicht funktioniert. Vielleicht war der Link schon etwas zu alt.<br />
            Fordere bitte einen neuen Link an und versuche es erneut.
          </p>
        </template>

        <template v-if="status == 'requesting_magic_link'">
          <p class="text-center">Generiere neuen Anmelde-Link ...</p>
        </template>

        <template v-if="status == 'magic_link_requested'">
          <img
            class="w-14 h-14 block mx-auto mb-4 p-2 object-contain border-2 rounded-full"
            src="@/assets/gfx/icon_mail_white.png"
          />
          <p class="text-center text-lg mb-4">
            Dein neuer Anmelde-Link sollte schon in deinem Posteingang sein.<br />
            Du kannst diese Seite nun schließen und dich über den Link in der E-Mail anmelden.
          </p>

          <p class="text-center">
            Falls dein Endgerät deine E-Mails nicht empfangen kannst, scanne den QR-Code.
          </p>

          <button
            class="bg-fiasco-blue p-4 h-14 rounded-full my-4 w-full"
            @click="showQrCodeScanner()"
          >
            QR-Code aus Anmelde-Mail scannen
          </button>

          <p>&nbsp;</p>

          <p class="text-center">Keine E-Mail bekommen?</p>
        </template>

        <template v-if="status == 'magic_link_request_error'">
          <p class="text-center">Das hat nicht funktioniert. Versuche es bitte noch einmal.</p>
        </template>

        <template v-if="status == 'qr_code_scanner' || status == 'qr_code_login_error'">
          <p class="text-center mb-4">Scanne hier den QR-Code aus der Anmelde-E-Mail:</p>

          <template v-if="status == 'qr_code_login_error'">
            <p class="intro-error">Die Anmeldung per QR-Code hat leider nicht funktioniert.</p>
          </template>

          <div class="relative p-4 aspect-square">
            <div
              class="absolute top-0 left-0 w-8 h-8 border-t-4 border-l-4 border-fiasco-purple"
            ></div>
            <div
              class="absolute top-0 right-0 w-8 h-8 border-t-4 border-r-4 border-fiasco-purple"
            ></div>
            <div
              class="absolute bottom-0 right-0 w-8 h-8 border-b-4 border-r-4 border-fiasco-purple"
            ></div>
            <div
              class="absolute bottom-0 left-0 w-8 h-8 border-b-4 border-l-4 border-fiasco-purple"
            ></div>
            <div class="rounded w-full aspect-square overflow-hidden">
              <qr-stream @decode="onQrCodeDecode" @init="onQrCodeScannerReady" />
            </div>
          </div>

          <button class="border-2 h-14 w-full my-4 rounded-full" @click="goHome()">
            Zurück zur Startseite
          </button>
        </template>

        <template v-if="status == 'qr_code_scanner_error'">
          <p class="intro-error">Der QR-Code-Scanner konnte nicht gestartet werden.</p>

          <button @click="goHome()">Zurück zur Startseite</button>
        </template>

        <template
          v-if="
            status == 'start' ||
            status == 'login_error' ||
            status == 'magic_link_requested' ||
            status == 'magic_link_request_error'
          "
        >
          <form
            @submit.prevent="requestMagicLink()"
            class="login-form"
            :class="{
              error: status == 'login_error' || status == 'magic_link_request_error',
            }"
          >
            <input
              class="rounded-full h-12 px-4 my-2 text-gray-dark w-full"
              type="text"
              v-model="emailAddress"
              placeholder="E-Mail"
            />
            <input
              class="rounded-full h-12 my-2 mx-auto w-full block bg-fiasco-blue text-lg"
              v-if="status != 'magic_link_requested'"
              type="submit"
              value="Anmelde-Link erneut anfordern"
            />
            <input
              class="border-2 rounded-full px-4 h-14 my-2 mx-auto w-full"
              v-if="status == 'magic_link_requested'"
              type="submit"
              value="Anmelde-Link noch einmal anfordern"
              :disabled="!allow_resend_yet"
            />
          </form>

          <button class="border-2 rounded-full px-4 h-12 my-2 mx-auto w-full" @click="goHome()">
            Zurück zur Startseite
          </button>
        </template>
      </div>
    </div>
    <div class="flex absolute bottom-2 w-full justify-center">
      <div class="mx-2 text-white text-xs opacity-50" @click="openCustomerInfo()">
        Kundeninformationen
      </div>
      <div class="mx-2 text-white text-xs opacity-50" @click="openImprint()">Impressum</div>
      <div class="mx-2 text-white text-xs opacity-50" @click="openPrivacy()">Datenschutz</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "MagicLinkLogin",

  props: {
    token: {
      type: String,
    },

    email: {
      type: String,
    },
  },

  data() {
    return {
      status: "",
      emailAddress: "",
      allow_resend_yet: false,
      allow_resend_timer: null,
    }
  },

  methods: {
    goHome() {
      this.$router.push({ name: "intro" })
    },

    goMain() {
      this.$router.push({ name: "main" })
    },

    async requestMagicLink() {
      this.status = "requesting_magic_link"

      const payload = {
        email: this.emailAddress,
      }

      this.$store.commit("customers/SET_LOGIN_ERROR", null)
      this.$store.commit("SET_BUSY", true)

      let response
      try {
        response = await this.$store.dispatch("customers/requestEmailLoginLink", payload)

        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        // this.status = "magic_link_request_error";

        if (err?.response?.status === 401) {
          this.$store.commit("users/SET_LOGIN_ERROR", "Passwort nicht korrekt")
        } else if (err?.response?.status === 404) {
          this.$store.commit("users/SET_LOGIN_ERROR", "Benutzer nicht gefunden")
        } else {
          if (err.response?.status === 401) {
            this.onLogout()
          }
        }
        return
      }

      // Reset the input data.
      this.emailAddress = ""

      this.$store.commit("SET_BUSY", false)

      if (!response) {
        console.error(
          "requestMagicLink error: Did not get a successful response from the server",
          response
        )
        this.status = "magic_link_request_error"
        return
      }

      if (this.allow_resend_timer) {
        clearTimeout(this.allow_resend_timer)
      }
      this.allow_resend_yet = false
      setTimeout(
        function () {
          this.allow_resend_yet = true
        }.bind(this),
        2000
      )

      this.status = "magic_link_requested"
    },

    showQrCodeScanner() {
      this.status = "qr_code_scanner"
    },

    async onQrCodeDecode(decodedString) {
      const prefix = `${process.env.VUE_APP_BASE_URL_APP}/#/mlog/`
      if (!decodedString.toLowerCase().startsWith(prefix.toLowerCase())) {
        return
      }

      const token = decodedString.substring(prefix.length)
      if (!token) {
        return
      }

      console.debug(`Found token in QR code: ${token}`)

      this.$store.commit("customers/SET_LOGIN_ERROR", null)
      this.$store.commit("SET_BUSY", true)

      let response
      try {
        response = await this.$store.dispatch("customers/authWithMagicToken", token)

        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        // this.status = "qr_code_login_error";

        if (err?.response?.status === 401) {
          this.$store.commit("users/SET_LOGIN_ERROR", "Passwort nicht korrekt")
        } else if (err?.response?.status === 404) {
          this.$store.commit("users/SET_LOGIN_ERROR", "Benutzer nicht gefunden")
        } else {
          if (err.response?.status === 401) {
            this.onLogout()
          }
        }
        return
      }

      this.$store.commit("SET_BUSY", false)

      if (!response) {
        this.$store.commit("customers/SET_LOGIN_ERROR", "Could not authenticate against the server")
        this.status = "qr_code_login_error"
        return
      }

      this.status = "logged_in"
      this.$emit("login")
    },

    async onQrCodeScannerReady(promise) {
      this.$store.commit("SET_BUSY", true)

      try {
        await promise
        // successfully initialized
      } catch (error) {
        this.status = "qr_code_scanner_error"

        if (error.name === "NotAllowedError") {
          // user denied camera access permisson
        } else if (error.name === "NotFoundError") {
          // no suitable camera device installed
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
        }
      } finally {
        this.$store.commit("SET_BUSY", false)
      }
    },

    openImprint() {
      this.$store.commit("SET_IMPRINT_VISIBLE", true)
    },

    openPrivacy() {
      this.$store.commit("SET_PRIVACY_VISIBLE", true)
    },

    openCustomerInfo() {
      this.$store.commit("SET_CUSTOMER_INFO_VISIBLE", true)
    },

    onLogout() {
      console.log("onLogout")
      this.$store.commit("customers/LOGOUT")
      this.$store.commit("requests/SET_ALL", [])
      this.$store.commit("cars/SET_CUSTOMER_CARS", [])
      window.clearInterval(this.updateInterval)
      this.$store.commit("SET_BUSY", false)
      this.$router.push({ name: "intro" })
    },

    async tryLogin() {
      this.status = "logging_in"

      this.$store.commit("customers/SET_LOGIN_ERROR", null)
      this.$store.commit("SET_BUSY", true)

      const token = this.token
      if (!token) {
        this.$store.commit("customers/SET_LOGIN_ERROR", "No magic link token provided")
        this.status = "login_error"
        this.$store.commit("SET_BUSY", false)
        return
      }

      let response
      try {
        response = await this.$store.dispatch("customers/authWithMagicToken", token)

        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        // this.status = "login_error";

        if (err?.response?.status === 401) {
          this.$store.commit("users/SET_LOGIN_ERROR", "Passwort nicht korrekt")
        } else if (err?.response?.status === 404) {
          this.$store.commit("users/SET_LOGIN_ERROR", "Benutzer nicht gefunden")
        } else {
          if (err.response?.status === 401) {
            this.onLogout()
          }
        }

        this.$store.commit("SET_BUSY", false)
        return
      }

      this.$store.commit("SET_BUSY", false)

      if (!response) {
        this.$store.commit("customers/SET_LOGIN_ERROR", "Could not authenticate against the server")
        this.status = "login_error"
        return
      }

      this.status = "logged_in"
      this.$emit("login")
    },
  },

  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      loginError: "customers/loginError",
    }),
  },

  mounted() {
    console.log("MAGICLINKLOGIN MOUNTED")

    if (this.token) {
      this.tryLogin()
    } else if (this.email) {
      this.emailAddress = this.email
      this.requestMagicLink()
    } else {
      this.$router.push({ name: "intro" })
    }
  },
}
</script>
