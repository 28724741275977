<template>
  <div class="h-full text-white flex flex-col justify-center">
    <div
      class="m-4"
      v-if="!qrScanActive && !vinScanActive && !enterCarActive && !registrationScanActive"
    >
      <div
        class="border-2 bg-fiasco-blue-dark flex items-center p-3 border-white rounded-lg mb-4"
        @click="goRegistrationScan()"
      >
        <img
          class="w-24 h-24 mr-4 rounded-full object-cover"
          src="@/assets/gfx/placeholder_kfz_schein.jpg"
        />
        <div class="create-request-car-main-item-caption">
          <h2 class="text-xl">Fahrzeugschein scannen</h2>
          <p class="opacity-75 text-sm">Die Fahrzeug-Identifikationsnummer muss gut lesbar sein.</p>
        </div>
      </div>

      <div
        class="border-2 bg-fiasco-blue-dark flex items-center p-3 border-white rounded-lg"
        @click="goEnterCar()"
      >
        <img
          class="w-14 h-14 my-4 mx-7 rounded-full object-cover"
          src="@/assets/gfx/icon_edit.png"
        />
        <div class="create-request-car-main-item-caption">
          <h2 class="text-xl">Manuell eingeben</h2>
          <p class="opacity-75 text-sm">Die 17-stellige VIN einfach eintippen.</p>
        </div>
      </div>
    </div>

    <div v-if="customer">
      <template
        v-if="
          customer._id &&
          customerCars &&
          customerCars.length > 0 &&
          !qrScanActive &&
          !vinScanActive &&
          !enterCarActive &&
          !registrationScanActive
        "
      >
        <div class="m-4 text-center">
          <p>oder</p>
        </div>

        <div class="m-4">
          <h3 class="text-center text-lg mb-2" v-if="!qrScanActive && !vinScanActive">
            Aus meinem Fahrzeugpool wählen:
          </h3>
          <div
            class="mb-2 bg-fiasco-blue p-2 rounded-full px-4"
            v-for="item in customerCars"
            :key="item._id"
            @click="onCarSelect(item)"
          >
            <div v-if="item.manufacturer || item.model">
              {{ item.manufacturer }} &nbsp; {{ item.model }}&nbsp;
            </div>
            <div v-if="item.firstRegistration">Erstzulassung: {{ item.firstRegistration }}</div>
            <div v-if="item.numberPlate">Kennzeichen: {{ item.numberPlate }}</div>
            <div>FIN: {{ item.vin }}</div>
          </div>
        </div>
      </template>
    </div>

    <div
      class="absolute bottom-4 left-4 w-14 h-14 p-1 rounded-full bg-transparent border-2 border-white"
      @click="goBack()"
    >
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateRequestCar",
  data() {
    return {
      photoIndex: 0,
      editRequest: null,
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestPhotos" })
    },
    goRegistrationScan() {
      this.$router.push({ name: "CreateRequestRegistrationScan" })
      // this.$store.commit("SET_REGISTRATION_SCAN_ACTIVE", true);
    },
    goVinScan() {
      console.log("goVinScan")
      this.$router.push({ name: "CreateRequestVinScan" })
      // this.$store.commit("SET_VIN_SCAN_ACTIVE", true);
    },
    goQrScan() {
      // this.$store.commit("SET_QR_SCAN_ACTIVE", true);
    },
    goEnterCar() {
      this.$router.push({ name: "CreateRequestEnterCar" })
      // this.$store.commit("SET_ENTER_CAR_ACTIVE", true);
    },
    onCarSelect(item) {
      this.editRequest.car = item._id
      this.$store.commit("requests/SET_SELECTED_REQUEST", this.editRequest)
      this.$store.commit("cars/SET_CREATE_REQUEST_CAR", null)
      this.$emit("carSelected")
      this.$router.push({ name: "CreateRequestDamageIntro" })
    },
    onCarEditCancel() {
      this.$store.commit("SET_REGISTRATION_SCAN_ACTIVE", false)
      this.$store.commit("SET_VIN_SCAN_ACTIVE", false)
      this.$store.commit("SET_QR_SCAN_ACTIVE", false)
      this.$store.commit("SET_ENTER_CAR_ACTIVE", false)
    },
    onCarEditCommit() {
      console.log("onCarEditClose")
      console.log(this.createRequestCar)
      this.$store.commit("requests/SET_SELECTED_REQUEST", this.editRequest)
      this.$store.commit("SET_REGISTRATION_SCAN_ACTIVE", false)
      this.$store.commit("SET_VIN_SCAN_ACTIVE", false)
      this.$store.commit("SET_QR_SCAN_ACTIVE", false)
      this.$store.commit("SET_ENTER_CAR_ACTIVE", false)
      // this.$emit("carSelected");
      this.$router.push({ name: "CreateRequestDamageIntro" })
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      selectedRequest: "requests/selectedRequest",
      createRequestCar: "cars/createRequestCar",
      vinScanActive: "vinScanActive",
      registrationScanActive: "registrationScanActive",
      qrScanActive: "qrScanActive",
      enterCarActive: "enterCarActive",
    }),
  },
  mounted() {
    console.log("CREATE REQUEST CAR MOUNTED")

    // The value in this variable is read from local state, which can be unprepared if the browser was refreshed.
    if (!this.selectedRequest) {
      this.$router.push({ name: "intro" })
    }

    this.getEditData()
    this.editRequest.car = null
    this.$store.commit("requests/SET_SELECTED_REQUEST", this.editRequest)
    this.getEditData()
  },
}
</script>
