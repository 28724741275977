<template>
  <div class="absolute top-0 left-0 w-full h-16 bg-fiasco-blue-dark text-white z-40 p-2">
    <div class="text-center text-lg">
      <span class="opacity-75">Neue Anfrage: </span>
      <span class="create-request-header-caption-step">{{ stepName }}</span>
    </div>
    <div class="w-full h-1.5 relative rounded-full bg-transparent-black mt-2 overflow-hidden">
      <div class="absolute t-0 l-0 h-full bg-white" :style="{ width: stepPercent + '%' }" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateRequestHeader",
  data() {
    return {
      step: 1,
    }
  },
  computed: {
    stepName() {
      let name = "Start"
      switch (this.$route.name) {
        case "CreateRequestPhotosExample":
          name = "Fotos"
          break
        case "CreateRequestPhotos":
          name = "Fotos"
          break
        case "CreateRequestCar":
          name = "Fahrzeug"
          break
        case "CreateRequestRegistrationScan":
          name = "Fahrzeugschein"
          break
        case "CreateRequestVinScan":
          name = "FIN scannen"
          break
        case "CreateRequestEnterCar":
          name = "FIN eingeben"
          break
        case "CreateRequestDamageIntro":
          name = "Schaden - Modell"
          break
        case "CreateRequestDamage":
          name = "Schaden markieren"
          break
        case "CreateRequestInterview":
          name = "Beschreibung"
          break
        case "CreateRequestReady":
          name = "Senden"
          break
        case "CreateStationRequestType":
          name = "Anfragetyp"
          break
        case "CreateStationRequestReady":
          name = "Senden und Kalkulieren"
          break
      }
      return name
    },
    stepPercent() {
      let percent = 0
      switch (this.$route.name) {
        case "CreateRequestPhotos":
          percent = 15
          break
        case "CreateRequestCar":
          percent = 25
          break
        case "CreateRequestRegistrationScan":
          percent = 30
          break
        case "CreateRequestVinScan":
          percent = 30
          break
        case "CreateRequestEnterCar":
          percent = 30
          break
        case "CreateRequestDamageIntro":
          percent = 40
          break
        case "CreateRequestDamage":
          percent = 50
          break
        case "CreateRequestInterview":
          percent = 75
          break
        case "CreateRequestReady":
          percent = 100
          break
        case "CreateStationRequestType":
          percent = 85
          break
        case "CreateStationRequestReady":
          percent = 100
          break
      }
      return percent
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      createRequestCar: "cars/createRequestCar",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      cameraPhotos: "requests/cameraPhotos",
      vinScanActive: "vinScanActive",
      registrationScanActive: "registrationScanActive",
      qrScanActive: "qrScanActive",
      enterCarActive: "enterCarActive",
      editRequest: "editRequest",
      damageDummyCar: "damageDummyCar",
    }),
  },
}
</script>
