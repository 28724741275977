/* eslint-disable vue/multi-word-component-names */
<template>
  <div
    class="screen h-full text-white overflow-y-auto"
    v-if="selectedOffer"
    :class="{ modalopen: contactVisible }"
  >
    <div class="w-full px-4 pt-2 flex flex-col justify-between items-center">
      <s3-image
        class="w-32 h-32 m-2 rounded-full object-cover"
        :image="selectedRequest.s3Images[selectedRequest.s3Images.length - 1]"
        v-if="selectedRequest.s3Images.length > 0"
      />
      <div class="text-center">
        <div>
          {{ selectedRequest.fiascoId }}
        </div>
        <div class="opacity-50">vom {{ formatDate(selectedRequest.createdAt) }}</div>
        <div
          class="text-2xl my-2"
          v-if="!selectedOffer.calculation.useLumpSumPrice && !selectedOffer.hidePrice"
        >
          Angebot:
          {{ (selectedOffer.calculation.totalPrice * 1.19).toFixed(0) }},-€
        </div>
        <div
          class="text-2xl my-2"
          v-if="selectedOffer.calculation.useLumpSumPrice && !selectedOffer.hidePrice"
        >
          Angebot:
          {{ (selectedOffer.calculation.lumpSumPrice * 1.19).toFixed(0) }},-€
        </div>
      </div>
      <!--img
        class="header-edit"
        src="@/assets/gfx/icon_edit.png"
        @click="goEdit()"
      /-->
    </div>
    <div class="p-2">
      <div v-if="selectedRequest.status !== 'OFFERED'">
        <div class="text-white text-center">
          <div class="text-sm">Status:</div>
          <div class="mb-2">
            {{ requestStatusName(selectedRequest.status) }}
          </div>
          <img
            class="w-8 h-8 block mx-auto p-1 object-contain border-2 border-black rounded-full invert"
            src="@/assets/gfx/icon_check.png"
          />
        </div>
      </div>
      <div class="m-4" @click="chatVisible = true" v-if="recentMessage">
        <div class="flex">
          <!--div class="chat-line-name" v-if="recentMessage.customer">
            {{recentMessage.customer.firstName}}  {{recentMessage.customer.lastName}}
          </div-->
          <div class="chat-line-name" v-if="recentMessage.user">
            {{ recentMessage.user.firstName }} {{ recentMessage.user.lastName }}
          </div>
          <span v-if="recentMessage.user">
            <s3-image
              class="w-12 h-12 rounded-full object-cover"
              :image="recentMessage.user.s3Image"
              v-if="recentMessage.user.s3Image"
            />
          </span>
          <span v-if="recentMessage.customer">
            <button class="w-14 h-14 p-2 bg-fiasco-blue rounded-full">
              <img class="invert" src="@/assets/gfx/icon_chat.png" />
            </button>
          </span>
          <div class="mx-2 bg-white text-gray-dark p-2 rounded-lg">
            <div class="text-xs opacity-50">
              {{ formatDate(recentMessage.deliveredAt) }}
            </div>
            {{ recentMessage.content }}
          </div>
        </div>
      </div>

      <div class="bg-slate-200 rounded-lg my-4 text-gray-dark p-2">
        <div class="content-floating-inner">
          <br v-if="selectedOffer.hidePrice" />
          <div v-if="!selectedOffer.hidePrice" class="mb-4">
            <div class="my-2 text-center text-lg">Kostenvoranschlag</div>
            <div class="flex items-center">
              <div>
                <div
                  class="text-xl font-bold px-2 py-1 rounded"
                  :class="'reliability-level-' + selectedOffer.calculationLevel.toLowerCase()"
                  v-if="!selectedOffer.calculation.useLumpSumPrice"
                >
                  {{ (selectedOffer.calculation.totalPrice * 1.19).toFixed(0) }},- bis
                  {{ (offerMaxPrice(selectedOffer) * 1.19).toFixed(0) }},- €
                </div>
                <div
                  class="text-xl font-bold px-2 py-1 rounded text-white bg-green"
                  v-if="selectedOffer.calculation.useLumpSumPrice"
                >
                  {{ (selectedOffer.calculation.lumpSumPrice * 1.19).toFixed(0) }},- €
                </div>
              </div>
              <div
                class="offer-price-level"
                v-if="!selectedOffer.calculation.useLumpSumPrice"
                :class="'reliability-level-' + selectedOffer.calculationLevel.toLowerCase()"
              >
                {{ selectedOffer.calculationLevel }}
              </div>
              <div
                class="w-6 h-6 rounded-full text-center bg-green font-bold text-white mx-2"
                v-if="selectedOffer.calculation.useLumpSumPrice"
              >
                F
              </div>
              <div
                class="offer-price-explanation"
                v-if="
                  selectedOffer.calculationLevel == 'A' &&
                  !selectedOffer.calculation.useLumpSumPrice
                "
              >
                Zuverlässige Kalkulation: Maximal 10% Abweichung
              </div>
              <div
                class="offer-price-explanation"
                v-if="
                  selectedOffer.calculationLevel == 'B' &&
                  !selectedOffer.calculation.useLumpSumPrice
                "
              >
                Grobe Kalkulation: Maximal 30% Abweichung
              </div>
              <div
                class="offer-price-explanation"
                v-if="
                  selectedOffer.calculationLevel == 'C' &&
                  !selectedOffer.calculation.useLumpSumPrice
                "
              >
                Schätzung: Mehr als 30% Abweichung
              </div>
              <div class="offer-price-explanation" v-if="selectedOffer.calculation.useLumpSumPrice">
                Fixpreis!
              </div>
            </div>
          </div>

          <div class="flex items-center mb-2" v-if="selectedRequest.status === 'OFFERED'">
            <img
              class="w-8 h-8 block mr-2 p-1 object-contain border-2 rounded-full"
              src="@/assets/gfx/icon_check.png"
            />
            {{ requestStatusName(selectedRequest.status) }}
          </div>

          <!--div class="offer-certificates">
          <div class="offer-certificates-label">Zertifizierung:</div>
          <div class="offer-certificates-content">
            <img class="offer-certificate" src="@/assets/gfx/certificate-fiasco.png">
            <img class="offer-certificate" src="@/assets/gfx/certificate-dekra.png">
            <img class="offer-certificate" src="@/assets/gfx/certificate-iso.png">
            <img class="offer-certificate" src="@/assets/gfx/certificate-eurogarant.png">
            <img class="offer-certificate" src="@/assets/gfx/certificate-zkf.png">
          </div>
        </div-->

          <div class="offer-services">
            <div v-if="selectedOffer.services.length > 0" class="text-center my-2 text-lg">
              Service:
            </div>

            <div v-for="(service, index) in selectedOffer.services" :key="index">
              <div class="offer-services-content">
                <div class="offer-service" v-if="service.isSelected">
                  <div class="offer-service-icon">
                    <img :src="'@/assets/gfx/' + service.displayIcon + '.svg'" />
                  </div>
                  {{ service.displayName }}: {{ service.price }} €
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedOffer.contactPerson">
            <div class="my-2 text-center text-lg">Ansprechpartner:</div>
            <div class="flex items-center">
              <s3-image
                class="w-12 h-12 rounded-full object-cover mr-4"
                :image="selectedOffer.contactPerson.s3Image"
                v-if="selectedOffer.contactPerson.s3Image"
              />
              <div class="details-item-caption">
                <div class="details-item-caption-value">
                  {{ selectedOffer.contactPerson.firstName }}
                  {{ selectedOffer.contactPerson.lastName }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex items-center my-2"
            @click="chatVisible = true"
            v-if="selectedRequest.messages.length === 0"
          >
            <button class="w-12 h-12 mr-4 p-2 bg-fiasco-blue rounded-full">
              <img class="invert" src="@/assets/gfx/icon_chat.png" />
            </button>
            <div class="details-item-caption">
              <div class="details-item-caption-quote">FIASCO Chat starten</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-floating">
        <div class="content-floating-inner grid justify-items-stretch">
          <div class="offer-contact-form-openers"><DocumentsList /></div>
        </div>
      </div>

      <div
        v-if="acceptedSuggestion"
        class="p-2 bg-slate-200 text-gray-dark m-2 rounded text-center"
      >
        <div class="text-lg">Termin bestätigt!</div>
        <div class="font-bold">
          {{ formatDateTime(acceptedSuggestion.date) }}
        </div>
        <div>
          <span v-if="acceptedSuggestion.type == 'REPAIR'">Reparatur</span>
          <span v-if="acceptedSuggestion.type == 'INSPECTION'">Besichtigung </span>
          <span>&nbsp;</span>
          <span v-if="acceptedSuggestion.delivery == 'CUSTOMER'"> Ich bringe das Fahrzeug </span>
          <span v-if="acceptedSuggestion.delivery == 'STATION'">Hol- und Bringservice</span>
        </div>
      </div>

      <div
        v-if="!createDateSuggestion && !acceptedSuggestion"
        class="p-2 bg-slate-200 m-2 rounded-lg text-gray-dark"
      >
        <div class="text-lg text-center" v-if="suggestionsByStation.length > 0">
          Terminvorschläge der Werkstatt
        </div>
        <div class="text-lg text-center" v-if="selectedOffer.dateSuggestions.length == 0">
          Noch keine Terminvorschläge
        </div>
        <div
          class="rounded p-2 my-2 flex items-center border"
          :class="[
            { 'border-transparent': selectedDate != item },
            { 'border-gray': selectedDate == item },
          ]"
          v-for="item in suggestionsByStation"
          :key="item._id"
          @click="selectedDate = item"
        >
          <!-- <div
            class="w-4 h-4 rounded-full mr-2"
            :class="[
              { 'bg-fiasco-blue': selectedDate == item },
              { 'bg-white': selectedDate != item },
            ]"
          /> -->
          <input
            class="form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-fiasco-blue checked:border-fiasco-blue-dark focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer flex-shrink-0"
            type="radio"
            name="selectedDate"
            id="selectedDate"
            v-model="selectedDate"
            :value="item"
          />
          <div class="text-left">
            <div class="font-bold">
              {{ formatDateTime(item.date) }}
            </div>
            <div class="text-sm">
              <span v-if="item.type == 'REPAIR'">Reparatur</span>
              <span v-if="item.type == 'INSPECTION'">Besichtigung</span>
            </div>
          </div>
        </div>
        <button
          v-if="suggestionsByStation.length > 0"
          class="rounded-full h-14 text-white bg-fiasco-purple w-full disabled:opacity-50"
          @click="showContact('ACCEPT')"
          :disabled="!selectedDate"
        >
          Termin bestätigen
        </button>
        <div v-if="suggestionsByCustomer.length == 0">
          <div class="text-center my-4 text-lg">Kein passender Termin dabei?</div>
          <button
            class="rounded-full w-full border-2 border-gray h-12"
            @click="createDateSuggestion = true"
          >
            Eigenen Termin vorschlagen
          </button>
        </div>
        <div class="text-lg mt-2" v-if="suggestionsByCustomer.length > 0">
          Warte auf Bestätigung
        </div>
        <div
          class="rounded my-2 flex items-center justify-between text-sm"
          v-for="item in suggestionsByCustomer"
          :key="item._id"
        >
          <div>
            <div class="font-bold">
              {{ formatDateTime(item.date) }}
            </div>
            <div v-if="item.type == 'REPAIR'">Reparatur</div>
            <div v-if="item.type == 'INSPECTION'">Besichtigung</div>
          </div>
          <img
            v-if="item != suggestionToBeDeleted"
            class="w-8 opacity-25"
            src="@/assets/gfx/icon_trash.png"
            @click="requestDeleteSuggestion(item)"
          />
          <div v-if="item == suggestionToBeDeleted" class="flex">
            <div
              class="bg-reliability-c w-10 h-10 mr-2 rounded-full flex items-center justify-center"
            >
              <img
                class="w-8"
                src="@/assets/gfx/icon_trash_white.png"
                @click="deleteSuggestion(item)"
              />
            </div>
            <div class="bg-fiasco-blue w-10 h-10 rounded-full flex items-center justify-center">
              <img
                class="w-8"
                src="@/assets/gfx/icon_close.png"
                @click="suggestionToBeDeleted = null"
              />
            </div>
          </div>
        </div>
        <div class="mt-2" v-if="declinedSuggestions.length > 0">Abgelehnte Termine</div>
        <div
          class="rounded my-2 flex items-center justify-between text-xs"
          v-for="item in declinedSuggestions"
          :key="item._id"
        >
          <div>
            {{ formatDateTime(item.date) }}
          </div>
          <div>
            <span v-if="item.customerApproval == 'DECLINED'"> von mir abgelehnt</span>
            <span v-if="item.stationApproval == 'DECLINED'"> von der Werkstatt abgelehnt</span>
          </div>
        </div>
      </div>

      <div v-if="createDateSuggestion" class="p-2 bg-slate-200 m-2 rounded-lg text-gray-dark">
        <div class="text-lg mb-2">Termin vorschlagen:</div>
        <div class="mb-4">
          <input type="date" class="p-2 mr-2 rounded-full" v-model="editSuggestionDate" />
          <select class="p-2 rounded-full" v-model="editHours">
            <option v-for="n in hours" :key="n" :value="n">{{ n }}</option>
          </select>
          :
          <select class="p-2 rounded-full" v-model="editMinutes">
            <option v-for="n in minutes" :key="n" :value="n">{{ n }}</option>
          </select>
        </div>

        <div class="text-sm opacity-75">Was soll gemacht werden?</div>
        <div class="flex items-center my-1" @click="editSuggestionType = 'REPAIR'">
          <div
            class="w-4 h-4 rounded-full mr-2"
            :class="[
              { 'bg-fiasco-blue': editSuggestionType == 'REPAIR' },
              { 'bg-white': editSuggestionType != 'REPAIR' },
            ]"
          />
          <span> gleich zur Reparatur </span>
        </div>
        <div class="flex items-center my-1 mb-4" @click="editSuggestionType = 'INSPECTION'">
          <div
            class="w-4 h-4 rounded-full mr-2"
            :class="[
              { 'bg-fiasco-blue': editSuggestionType == 'INSPECTION' },
              { 'bg-white': editSuggestionType != 'INSPECTION' },
            ]"
          />
          <span> erstmal für eine Besichtigung </span>
        </div>

        <div class="text-sm opacity-75">Wie kommt das Fahrzeug zur Werkstatt?</div>
        <div class="flex items-center my-1" @click="editSuggestionDelivery = 'CUSTOMER'">
          <div
            class="w-4 h-4 rounded-full mr-2"
            :class="[
              { 'bg-fiasco-blue': editSuggestionDelivery == 'CUSTOMER' },
              { 'bg-white': editSuggestionDelivery != 'CUSTOMER' },
            ]"
          />
          <span> Ich bringe das Fahrzeug </span>
        </div>
        <div class="flex items-center my-1" @click="editSuggestionDelivery = 'STATION'">
          <div
            class="w-4 h-4 rounded-full mr-2"
            :class="[
              { 'bg-fiasco-blue': editSuggestionDelivery == 'STATION' },
              { 'bg-white': editSuggestionDelivery != 'STATION' },
            ]"
          />
          <span>Ich benötige Hol- und Bringservice </span>
        </div>

        <button
          class="rounded-full text-white bg-fiasco-purple w-full h-14 my-2 disabled:opacity-50"
          @click="showContact('SUGGEST')"
          :disabled="
            !editSuggestionDate ||
            !editSuggestionDelivery ||
            !editSuggestionType ||
            !editHours ||
            !editMinutes
          "
        >
          Termin senden
        </button>
        <button
          class="rounded-full w-full h-14 border-gray border-2"
          @click="createDateSuggestion = false"
        >
          Abbrechen
        </button>
      </div>
    </div>
    <div class="h-24"></div>

    <OfferContact
      v-if="contactVisible"
      @close="onContactClose()"
      :mode="contactMode"
      :dateSuggestion="editSuggestion"
    />
    <Chat v-if="chatVisible" @close="chatVisible = false" />
    <div
      class="absolute bottom-4 left-4 w-14 h-14 p-1 rounded-full bg-fiasco-blue-dark border-2 border-white"
      @click="goBack()"
    >
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"
import DocumentsList from "./DocumentsList.vue"
import OfferContact from "./OfferContact.vue"
import Chat from "./Chat.vue"

import dateDisplayMixin from "../mixins/dateDisplay"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Offer",
  components: {
    OfferContact,
    Chat,
    DocumentsList,
  },
  data() {
    return {
      contactVisible: false,
      documents: [],
      contactMode: null,
      chatVisible: false,
      selectedDate: null,
      suggestionToBeDeleted: null,
      createDateSuggestion: false,
      editSuggestionDate: null,
      editSuggestionTime: null,
      editSuggestionType: null,
      editSuggestionDelivery: null,
      editHours: null,
      editMinutes: null,
      hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
      minutes: ["00", "15", "30", "45"],
    }
  },
  mixins: [dateDisplayMixin],
  methods: {
    goBack() {
      // this.$router.go(-1)
      this.$router.push({ name: "main" })
      // this.$router.push({name: 'request-offers', params: {id: this.selectedRequest._id}})
    },
    onContactClose() {
      this.contactVisible = false
      this.contactMode = null
      this.createDateSuggestion = false
    },
    showContact(mode) {
      this.contactMode = mode
      document.querySelector(".screen").scrollTo(0, 0)
      this.contactVisible = true
    },
    goEdit() {
      this.$router.push({
        name: "request-edit",
        params: { id: this.selectedRequest._id },
      })
    },
    requestDeleteSuggestion(item) {
      this.suggestionToBeDeleted = item
    },
    async deleteSuggestion(item) {
      const editOffer = JSON.parse(JSON.stringify(this.selectedOffer))
      editOffer.dateSuggestions = editOffer.dateSuggestions.filter((o) => o._id !== item._id)

      this.$store.commit("SET_BUSY", true)

      try {
        await this.$store.dispatch("offers/update", editOffer)
        await this.$store.dispatch("offers/getById", this.selectedRequest.offer)

        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }

      this.$store.commit("SET_BUSY", false)
    },
  },

  computed: {
    editSuggestion() {
      let suggestion = null
      if (this.contactMode === "ACCEPT") {
        suggestion = this.selectedDate
      }
      if (this.contactMode === "SUGGEST") {
        const dateTime = moment(this.editSuggestionDate)
        dateTime.set("hour", this.editHours).set("minute", this.editMinutes)
        suggestion = {
          date: dateTime,
          type: this.editSuggestionType,
          delivery: this.editSuggestionDelivery,
        }
      }
      return suggestion
    },
    acceptedSuggestion() {
      return this.selectedOffer.dateSuggestions.find(
        (o) =>
          (o.createdBy === "CUSTOMER" && o.stationApproval === "ACCEPTED") ||
          (o.createdBy === "STATION" && o.customerApproval === "ACCEPTED")
      )
    },
    declinedSuggestions() {
      return this.selectedOffer.dateSuggestions.filter(
        (o) => o.stationApproval === "DECLINED" || o.customerApproval === "DECLINED"
      )
    },
    suggestionsByStation() {
      return this.selectedOffer.dateSuggestions.filter(
        (o) => o.createdBy === "STATION" && o.customerApproval !== "DECLINED"
      )
    },
    suggestionsByCustomer() {
      return this.selectedOffer.dateSuggestions.filter(
        (o) => o.createdBy === "CUSTOMER" && o.stationApproval !== "DECLINED"
      )
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      recentMessage: "requests/recentMessage",
      requestStatusName: "requests/requestStatusName",
      selectedOffer: "offers/selectedOffer",
      offerMaxPrice: "offers/maxPrice",
      customerStation: "stations/customerStation",
      stations: "stations/stations",
      selectedStation: "stations/selectedStation",
    }),
  },
  watch: {
    requests: {
      deep: true,
      handler: function () {
        console.log("WATCH requests")
      },
    },
  },
  async mounted() {
    if (!this.selectedRequest) {
      this.$store.commit("SET_BUSY", true)

      this.$store.commit(
        "requests/SET_SELECTED_REQUEST",
        this.requests.find((o) => o._id === this.$route.params.requestId)
      )
    }
    // this.offer = this.activeOffers.find(o => o._id === this.$route.params.id)

    if (this.selectedRequest.statusHistory.some((o) => o.status === "OFFERED")) {
      try {
        await this.$store.dispatch("offers/getById", this.selectedRequest.offer)
        await this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }
    }
    this.$store.commit("SET_BUSY", false)
  },
}
</script>
