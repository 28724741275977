<template>
  <div class="absolute top-0 left-0 z-30 w-full h-full bg-transparent-black text-white">
    <div class="absolute z-0 top-0 left-0 w-full h-full" @click="goBack()"></div>
    <div class="bg-slate-200 p-2 rounded-lg absolute top-4 bottom-4 left-4 right-4">
      <img
        class="absolute top-0 left-0 z-0 w-full h-full object-cover"
        src="@/assets/gfx/cloud-werkstatt-wallpaper_05.png"
      />
      <div class="absolute z-1 top-2 w-full">
        <img
          v-if="!whitelabel"
          class="w-48 mx-auto block"
          src="@/assets/gfx/fiasco-logo.png"
          @click="goBack()"
        />
        <div class="text-center">Chat mit: {{ customerStation.name }}</div>
      </div>

      <div class="absolute z-1 top-24 bottom-16 right-2 left-2 overflow-y-auto">
        <div
          class="flex items-center mb-2"
          v-for="item in filteredMessages"
          :key="item._id"
          :class="{ 'justify-end': item.customer }"
        >
          <s3-image
            class="w-14 h-14 rounded-full object-cover"
            :image="item.user.s3Image"
            v-if="item.user && item.user.s3Image"
          />

          <s3-image
            class="w-14 h-14 rounded-full object-cover"
            :image="item.customer.s3Image"
            v-if="item.customer && item.customer.s3Image"
          />
          <div
            class="rounded-lg p-2 mx-2 text-gray-dark"
            :class="[{ 'bg-white': !item.customer }, { 'bg-amber-100': item.customer }]"
          >
            <div class="text-xs opacity-50" v-if="item.customer">
              {{ item.customer.firstName }} {{ item.customer.lastName }}
            </div>
            <div class="text-xs opacity-50" v-if="item.user">
              {{ item.user.firstName }} {{ item.user.lastName }}
            </div>
            <div class="text-xs opacity-50">
              {{ formatDate(item.deliveredAt) }}
            </div>

            <div class="chat-line-message">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full absolute z-1 bottom-2 left-0">
        <input
          type="text"
          class="grow h-14 bg-white text-gray-dark px-4 rounded-full"
          v-model="messageInput"
        />
        <button class="w-14 h-14 ml-2 p-3 bg-fiasco-purple rounded-full" @click="sendMessage()">
          <img src="@/assets/gfx/icon-send.png" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import dateDisplayMixin from "../mixins/dateDisplay"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Chat",
  data() {
    return {
      hits: 0,
      messageInput: "",
    }
  },
  mixins: [dateDisplayMixin],
  methods: {
    goBack() {
      this.$emit("close")
    },
    async sendMessage() {
      const id = this.selectedRequest._id
      const payload = {
        message: this.messageInput,
        requestId: id,
        fiascoId: this.selectedRequest.fiascoId,
      }
      this.$store.commit("SET_BUSY", true)

      try {
        await this.$store.dispatch("requests/sendMessage", payload)
        await this.$store.dispatch("requests/getByCustomer", this.customer._id)

        this.$store.commit(
          "requests/SET_SELECTED_REQUEST",
          this.requests.find((o) => o._id === id)
        )
        this.messageInput = ""
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }
      this.$store.commit("SET_BUSY", false)
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      whitelabel: "whitelabel",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestStatusName: "requests/requestStatusName",
      activeOffers: "offers/activeOffers",
      offerMaxPrice: "offers/maxPrice",
      customerStation: "stations/customerStation",
    }),
    filteredMessages() {
      return this.selectedRequest.messages.filter((o) => o.type === "PUBLIC")
    },
  },
}
</script>
