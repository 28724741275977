import axios from "axios"

const state = {
  activeOffers: [],
  selectedOffer: null,
}

// getters
const getters = {
  activeOffers: (state) => state.activeOffers,
  selectedOffer: (state) => state.selectedOffer,
  // eslint-disable-next-line no-unused-vars
  maxPrice: (state) => (offer) => {
    let max = "?"
    if (offer.calculation.useLumpSumPrice) {
      max = offer.calculation.lumpSumPrice
    } else {
      if (offer.calculationLevel === "A") max = offer.calculation.totalPrice * 1.1
      if (offer.calculationLevel === "B") max = offer.calculation.totalPrice * 1.3
      if (offer.calculationLevel === "C") max = "?"
    }
    return Math.floor(max)
  },
}

// actions
const actions = {
  async getAll(context) {
    let response
    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "offers/",
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.log("offers/getAll network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_ACTIVE", response.data)
    } else {
      console.error("offers/getAll error", response)
      throw new Error("Angebote konnten nicht geladen werden")
    }
  },
  async getById(context, payload) {
    let response
    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "offers/" + payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.log("offers/getById network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      console.log("offers module: ", response)
      context.commit("SET_SELECTED_OFFER", response.data.offer)
    } else {
      console.error("offers/getById error", response)
      if (response.status == 404) {
        throw new Error("Das Angebot wurde nicht gefunden")
      } else {
        throw new Error("Die Daten zu dem Angebot konnten nicht geladen werden")
      }
    }
  },
  async create(context, payload) {
    console.log("create offer")

    let response
    try {
      response = await axios.put(
        context.rootGetters.API.baseUrl + "offers/",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.log("offers/create network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("offers/create error", response)
      throw new Error("Ein Angebotsdatensatz konnte nicht erstellt werden")
    }
  },
  async update(context, payload) {
    console.log("update offer")

    let response
    try {
      response = await axios.patch(
        context.rootGetters.API.baseUrl + "offers/" + payload._id,
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.log("offers/update error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }
    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("offers/update error", response)
      throw new Error("Das Angebot konnte nicht aktualisiert werden")
    }
  },

  async uploadSignedDocument(context, payload) {
    let response
    try {
      response = await axios.put(
        context.rootGetters.API.baseUrl + "offers/uploadSignedDocument/" + payload.offerId,
        payload.data,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.log("offers/uploadSignedDocument network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_SELECTED_OFFER", response.data.offer)
      return response
    } else {
      console.error("offers/uploadSignedDocument error", response)
      throw new Error("Das Dokument konnte nicht auf den Server übertragen werden")
    }
  },
}

// mutations
const mutations = {
  SET_ACTIVE(state, data) {
    state.activeOffers = data
  },
  SET_SELECTED_OFFER(state, data) {
    state.selectedOffer = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
