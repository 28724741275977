import { createStore } from "vuex"

import users from "./modules/users"
import customers from "./modules/customers"
import requests from "./modules/requests"
import stations from "./modules/stations"
import cars from "./modules/cars"
import offers from "./modules/offers"
import error from "./modules/error"

import axios from "axios"
import ImageBlobReduce from "image-blob-reduce"

export default createStore({
  state: {
    API: {
      baseUrl: `${process.env.VUE_APP_BASE_URL_API}/`,
      baseURL: `${process.env.VUE_APP_BASE_URL_API}/`,
      imageUrl: `${process.env.VUE_APP_BASE_URL_IMAGES}/`,
    },
    CHANGE_TIMEOUT_MS: 500,
    MAX_UPLOAD_IMAGE_SIZE_PX: 1920,
    MAX_UPLOAD_MINI_SIZE_PX: 180,
    axiosConfig: {},
    busy: false,
    whitelabel: false,
    vinScanActive: false,
    registrationScanActive: false,
    qrScanActive: false,
    enterCarActive: false,
    lastUpdateCall: null,
    newDataAvailable: false,
    imprintVisible: false,
    privacyVisible: false,
    customerInfoVisible: false,
    onboardingVisible: false,
    profileVisible: false,
    damageDummyCar: false,
  },
  getters: {
    API: (state) => state.API,
    axiosConfig: (state) => state.axiosConfig,
    busy: (state) => state.busy,
    whitelabel: (state) => state.whitelabel,
    CHANGE_TIMEOUT_MS: (state) => state.CHANGE_TIMEOUT_MS,
    MAX_UPLOAD_IMAGE_SIZE_PX: (state) => state.MAX_UPLOAD_IMAGE_SIZE_PX,
    MAX_UPLOAD_MINI_SIZE_PX: (state) => state.MAX_UPLOAD_MINI_SIZE_PX,
    vinScanActive: (state) => state.vinScanActive,
    registrationScanActive: (state) => state.registrationScanActive,
    qrScanActive: (state) => state.qrScanActive,
    enterCarActive: (state) => state.enterCarActive,
    lastUpdateCall: (state) => state.lastUpdateCall,
    newDataAvailable: (state) => state.newDataAvailable,
    imprintVisible: (state) => state.imprintVisible,
    privacyVisible: (state) => state.privacyVisible,
    customerInfoVisible: (state) => state.customerInfoVisible,
    onboardingVisible: (state) => state.onboardingVisible,
    profileVisible: (state) => state.profileVisible,
    damageDummyCar: (state) => state.damageDummyCar,
  },
  mutations: {
    SET_BUSY(state, data) {
      state.busy = data
    },
    SET_TOKEN(state, data) {
      state.axiosConfig = {
        headers: { Authorization: "Bearer " + data },
      }
    },
    SET_WHITELABEL(state, data) {
      state.whitelabel = data
    },
    SET_VIN_SCAN_ACTIVE(state, data) {
      console.log("SET_VIN_SCAN_ACTIVE")
      state.vinScanActive = data
    },
    SET_REGISTRATION_SCAN_ACTIVE(state, data) {
      state.registrationScanActive = data
    },
    SET_QR_SCAN_ACTIVE(state, data) {
      state.qrScanActive = data
    },
    SET_ENTER_CAR_ACTIVE(state, data) {
      state.enterCarActive = data
    },
    RESET_LAST_UPDATE_CALL(state) {
      state.lastUpdateCall = new Date()
    },
    SET_NEW_DATA_AVAILABLE(state, data) {
      state.newDataAvailable = data
    },
    SET_IMPRINT_VISIBLE(state, data) {
      state.imprintVisible = data
    },
    SET_PRIVACY_VISIBLE(state, data) {
      state.privacyVisible = data
    },
    SET_PROFILE_VISIBLE(state, data) {
      state.profileVisible = data
    },
    SET_CUSTOMER_INFO_VISIBLE(state, data) {
      state.customerInfoVisible = data
    },
    SET_ONBOARDING_VISIBLE(state, data) {
      state.onboardingVisible = data
    },
    SET_DAMAGE_DUMMY_CAR(state, data) {
      state.damageDummyCar = data
    },
  },
  actions: {
    uploadS3CameraPhoto(context, payload) {
      return axios
        .post(
          context.rootGetters.API.baseUrl + "uploads/camera-image-s3/",
          payload,
          context.rootGetters.axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            console.error(res)

            throw new Error("error uploadS3CameraPhoto")
          }
        })
    },
    getS3Image(context, payload) {
      return axios
        .get(
          context.rootGetters.API.baseUrl + "uploads/image-s3/" + encodeURIComponent(payload),
          context.rootGetters.axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            console.error(res)

            throw new Error("error getS3Image")
          }
        })
    },
    fileToSizedBlob(context, payload) {
      return ImageBlobReduce()
        .toBlob(payload.file, { max: context.getters.MAX_UPLOAD_IMAGE_SIZE_PX })
        .then((blob) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (e) => {
              resolve(e.target.result)
            }

            reader.onerror = reject
            reader.readAsDataURL(blob)
          })
        })
    },
    fileToMiniBlob(context, payload) {
      return ImageBlobReduce()
        .toBlob(payload.file, { max: context.getters.MAX_UPLOAD_MINI_SIZE_PX })
        .then((blob) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (e) => {
              resolve(e.target.result)
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
          })
        })
    },
    fileToBlob(context, payload) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          resolve(e.target.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(payload.file)
      })
    },

    blobToBase64(context, blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    },

    scanK(context, payload) {
      return axios
        .post(
          context.rootGetters.API.baseUrl + "ocr/vehicle_papers",
          payload,
          context.rootGetters.axiosConfig
        )
        .then((response) => {
          return response
        })
        .catch((error) => console.log(error))
    },

    getS3ImageData(context, payload) {
      return axios
        .get(
          context.rootGetters.API.baseUrl + "uploads/image-data-s3/" + encodeURIComponent(payload),
          context.rootGetters.axiosConfig
        )
        .then((res) => {
          return res.data
        })
        .catch((err) => console.error(err))
    },
    uploadS3Image(context, body) {
      if (!body.id) return console.error(`Missing parameter: id`)
      if (!body.component) return console.error(`Missing parameter: component`)

      return axios
        .post(
          context.rootGetters.API.baseUrl + `uploads/image-s3/${body.component}/${body.id}`,
          body.payload,
          context.rootGetters.axiosConfig
        )
        .then((res) => {
          return res.data
        })

        .catch((err) => console.error(err))
    },
  },
  modules: {
    users,
    customers,
    cars,
    offers,
    requests,
    stations,
    error,
  },
})
