import { createRouter, createWebHistory } from "vue-router"

import MagicLinkLogin from "@/components/MagicLinkLogin.vue"
import MagicLinkRequest from "@/components/MagicLinkRequest.vue"
import Intro from "@/components/Intro.vue"
import StationIntro from "@/components/StationIntro.vue"
import RegisterMenu from "@/components/RegisterMenu.vue"
import RegisterByCode from "@/components/RegisterByCode.vue"
import RegisterByQr from "@/components/RegisterByQr.vue"
import RegisterByExpressLink from "@/components/RegisterByExpressLink.vue"
import Main from "@/components/Main.vue"
import StationMain from "@/components/StationMain.vue"
import RequestOffers from "@/components/RequestOffers.vue"
import CreateRequestHeader from "@/components/CreateRequestHeader.vue"
import CreateRequestIntro from "@/components/CreateRequestIntro.vue"
import CreateRequestPhotosExample from "@/components/CreateRequestPhotosExample.vue"
import CreateRequestPhotos from "@/components/CreateRequestPhotos.vue"
import CreateRequestCar from "@/components/CreateRequestCar.vue"
import CreateRequestVinScan from "@/components/CreateRequestVinScan.vue"
import CreateRequestRegistrationScan from "@/components/CreateRequestRegistrationScan.vue"
import CreateRequestEnterCar from "@/components/CreateRequestEnterCar.vue"
import CreateRequestDamageIntro from "@/components/CreateRequestDamageIntro.vue"
import CreateRequestDamage from "@/components/CreateRequestDamage.vue"
import CreateRequestInterview from "@/components/CreateRequestInterview.vue"
import CreateRequestOwner from "@/components/CreateRequestOwner.vue"
import CreateRequestReady from "@/components/CreateRequestReady.vue"
import CreateStationRequestType from "@/components/CreateStationRequestType.vue"
import CreateStationRequestReady from "@/components/CreateStationRequestReady.vue"
import Offer from "@/components/Offer.vue"
import RequestEdit from "@/components/RequestEdit.vue"
import QrDecoder from "@/components/QrDecoder.vue"
import CustomerActionVerify from "@/components/CustomerActionVerify"
import CustomerActionIntro from "@/components/CustomerActionIntro"

const routes = [
  {
    path: "/mlog",
    name: "magicLinkRequest",
    component: MagicLinkRequest,
  },
  {
    path: "/mlog/:token?",
    name: "magicLinkLogin",
    component: MagicLinkLogin,
    props: true,
  },
  {
    path: "/mlog/action/:token",
    name: "customerActionVerify",
    component: CustomerActionVerify,
  },
  {
    path: "/welcome",
    name: "customerActionIntro",
    component: CustomerActionIntro,
  },
  {
    path: "/impressum",
    name: "Imprint",
    component: Intro,
  },
  {
    path: "/datenschutzerklaerung",
    name: "Privacy",
    component: Intro,
  },
  {
    path: "/neue-anfrage",
    name: "CreateRequestIntro",
    components: {
      default: CreateRequestIntro,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/photos-example",
    name: "CreateRequestPhotosExample",
    components: {
      default: CreateRequestPhotosExample,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/photos",
    name: "CreateRequestPhotos",
    components: {
      default: CreateRequestPhotos,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/fahrzeug",
    name: "CreateRequestCar",
    components: {
      default: CreateRequestCar,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/fahrzeug/vin-scan",
    name: "CreateRequestVinScan",
    components: {
      default: CreateRequestVinScan,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/fahrzeug/vin-eingabe",
    name: "CreateRequestEnterCar",
    components: {
      default: CreateRequestEnterCar,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/fahrzeug/schein-scan",
    name: "CreateRequestRegistrationScan",
    components: {
      default: CreateRequestRegistrationScan,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/schaden-modell",
    name: "CreateRequestDamageIntro",
    components: {
      default: CreateRequestDamageIntro,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/schaden",
    name: "CreateRequestDamage",
    component: CreateRequestDamage,
  },
  {
    path: "/neue-anfrage/beschreibung",
    name: "CreateRequestInterview",
    components: {
      default: CreateRequestInterview,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/halter",
    name: "CreateRequestOwner",
    components: {
      default: CreateRequestOwner,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/senden",
    name: "CreateRequestReady",
    components: {
      default: CreateRequestReady,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/werkstatt-typ",
    name: "CreateStationRequestType",
    components: {
      default: CreateStationRequestType,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/neue-anfrage/werkstatt-senden",
    name: "CreateStationRequestReady",
    components: {
      default: CreateStationRequestReady,
      header: CreateRequestHeader,
    },
  },
  {
    path: "/qr-decode",
    name: "qr-decode",
    component: QrDecoder,
  },
  {
    path: "/registrieren",
    name: "registerMenu",
    component: RegisterMenu,
  },
  {
    path: "/registrieren/code",
    name: "registerCode",
    component: RegisterByCode,
  },
  {
    path: "/registrieren/qr-code/:code?",
    name: "registerQr",
    component: RegisterByQr,
  },
  {
    path: "/registrieren/expressLink/:token",
    name: "registerToken",
    component: RegisterByExpressLink,
  },
  {
    path: "/zentrale",
    name: "main",
    component: Main,
  },
  {
    path: "/werkstatt-zentrale",
    name: "station-main",
    component: StationMain,
  },
  {
    path: "/anfrage/:id/angebote",
    name: "request-offers",
    component: RequestOffers,
  },
  {
    path: "/anfrage/:id/bearbeiten",
    name: "request-edit",
    component: RequestEdit,
  },
  {
    path: "/anfrage/:requestId/angebot/:id",
    name: "offer",
    component: Offer,
  },
  {
    path: "/werkstatt/:partner?",
    name: "station-intro",
    component: StationIntro,
  },
  {
    path: "/:partner?",
    name: "intro",
    component: Intro,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {
  console.log('router.beforeEach')
  console.log('to: ', to)
  console.log('from: ', from)
  if (to.name == 'CreateStationRequestReady' && from.name != 'CreateStationRequestType') {
    return false
  }
  if (to.name == 'CreateRequestReady' && from.name != 'CreateRequestInterview') {
    return false
  }

})

export default router
