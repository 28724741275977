<template>
  <div class="w-full" :style="appStyle()">
    <ErrorHandler v-if="error || toastError" />
    <div
      class="busy absolute top-0 left-0 z-50 w-full h-screen flex items-center justify-center"
      v-if="busy && !error"
    >
      <div class="w-full h-full bg-fiasco-blue-dark opacity-70 absolute top-0 left-0 z-0"></div>
      <img class="w-[240px] relative z-10" src="@/assets/gfx/icon_waiting.gif" />
    </div>
    <img
      class="absolute top-0 left-0 w-full h-full object-cover z-0 opacity-50"
      src="@/assets/gfx/fiasco-hexa-bg.jpg"
    />
    <router-view name="header"></router-view>
    <div class="relative z-10 h-full w-full" v-if="!error">
      <router-view
        @login="onLogin()"
        @stationlogin="onStationLogin()"
        @logout="onLogout()"
        v-if="
          dataLoaded ||
          $route.name === 'intro' ||
          $route.name === 'station-intro' ||
          $route.name === 'magicLinkRequest' ||
          $route.name === 'magicLinkLogin' ||
          $route.name === 'customerActionVerify' ||
          $route.name === 'customerActionIntro' ||
          $route.name === 'Privacy' ||
          $route.name === 'Imprint' ||
          $route.name === 'registerMenu' ||
          $route.name === 'registerCode' ||
          $route.name === 'registerQr' ||
          $route.name === 'registerToken'
        "
      ></router-view>

      <PopupImprint v-if="imprintVisible" />
      <PopupPrivacy v-if="privacyVisible" />
      <PopupCustomerInfo v-if="customerInfoVisible" />
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="transform -translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-x-0"
        leave-to-class="transform -translate-x-full"
      >
        <Profile v-if="profileVisible" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"
import PopupImprint from "./components/PopupImprint.vue"
import PopupPrivacy from "./components/PopupPrivacy.vue"
import PopupCustomerInfo from "./components/PopupCustomerInfo.vue"
import Profile from "./components/Profile.vue"

import ErrorHandler from "./components/ErrorHandler.vue"
export default {
  name: "App",
  components: {
    PopupImprint,
    PopupPrivacy,
    PopupCustomerInfo,
    Profile,
    ErrorHandler,
  },
  data() {
    return {
      dataLoaded: false,
      UPDATE_INTERVAL_MILLIS: 12000,
      updateInterval: null,
      updateAge: 0,
    }
  },
  methods: {
    onLogin() {
      console.log("onLogin")
      // temp user?
      if (this.customer?._id) {
        this.loadData()
      } else {
        this.dataLoaded = true

        // // Prepare data for a new request.
        // // This is done in Main.vue's method `createRequest`, too.
        // let request = {
        //   customer: this.customer._id,
        //   carLocation: {
        //     zip: "",
        //   },
        // };
        // this.$store.commit("requests/SET_CAMERA_PHOTOS", []);
        // this.$store.commit("requests/SET_DOCUMENT_PHOTOS", []);
        // this.$store.commit("requests/SET_REQUEST_PENDING", true);
        // this.$store.commit("requests/SET_SELECTED_REQUEST", request);

        // this.$router.push({ name: "CreateRequestIntro" });
        this.$router.push({ name: "main" })
      }
    },

    async onStationLogin() {
      console.log("onStationLogin")
      console.log(this.user)

      const payload = {
        id: this.user._id,
        params: {
          folder: "INBOX",
        },
      }
      try {
        console.log("onStationLogin")
        await this.$store.dispatch("requests/getRequestStates")
        await this.$store.dispatch("requests/getByUser", payload)
        await this.$store.dispatch("stations/getById", this.user.station)
        this.dataLoaded = true
        if (this.$route.name !== "station-main") {
          this.$router.push({ name: "station-main" })
        }
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to login station", err)
      }
    },

    onLogout() {
      console.log("onLogout")
      this.$store.commit("customers/LOGOUT")
      this.$store.commit("requests/SET_ALL", [])
      this.$store.commit("cars/SET_CUSTOMER_CARS", [])
      window.clearInterval(this.updateInterval)
      this.$store.commit("SET_BUSY", false)
      this.$router.push({ name: "intro" })
    },

    appStyle() {
      // document.querySelector('body').style.height = window.innerHeight + 'px'
      const style = {}
      style.height = window.innerHeight + "px"
      return style
    },

    onOrientationChange() {
      setTimeout(() => {
        console.log(window.innerHeight)
        this.$forceUpdate()
      }, 100)
    },

    onResize() {
      this.$forceUpdate()
    },

    preventNav(event) {
      if (
        this.$route.name === "intro" ||
        this.$route.name === "magicLinkLogin" ||
        this.$route.name === "customerActionIntro" ||
        this.$route.name === "registerToken" ||
        this.$route.name === "main" ||
        this.$route.name === "station-main"
      ) {
        return
      }

      event.preventDefault()
      event.returnValue = ""
    },

    async getLastUpdate() {
      try {
        await this.$store.dispatch("requests/getLastUpdate", this.customer._id)
        this.updateAge = moment(this.lastUpdateCall).diff(moment(this.lastUpdate), "seconds")
        if (this.updateAge < 0) {
          this.$store.commit("SET_NEW_DATA_AVAILABLE", true)
          if (this.$route.name === "main" || this.$route.name === "offer") {
            console.log("getting new data")
            this.getNewData()
          }
        } else {
          this.$store.commit("RESET_LAST_UPDATE_CALL")
        }
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        console.log("error getting last update from here")
        console.log("error:", err)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
      }
    },

    async loadData() {
      console.log("loadData")
      if (!this.customer || !this.customer.selectedStation) {
        console.debug("loadData: no customer data set")
        return
      }

      this.$store.commit("SET_BUSY", true)

      try {
        await this.$store.dispatch("stations/getCustomerStation", this.customer.selectedStation._id)
        await this.$store.dispatch("cars/getByCustomer", this.customer._id)
        await this.$store.dispatch("requests/getRequestStates")
        await this.$store.dispatch("requests/getByCustomer", this.customer._id)

        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("App.vue: Could not load data", err)
      }
      this.dataLoaded = true
      if (
        this.$route.name === "intro" ||
        this.$route.name === "magicLinkLogin" ||
        this.$route.name === "customerActionVerify" ||
        this.$route.name === "customerActionIntro" ||
        this.$route.name === "registerCode" ||
        this.$route.name === "registerQr" ||
        this.$route.name === "registerToken" ||
        this.$route.name === "CreateRequestIntro"
      ) {
        this.$router.push({ name: "main" })
      }
      console.log("setting updateInterval")
      const that = this
      that.updateInterval = window.setInterval(() => {
        if (!this.error) that.getLastUpdate()
      }, that.UPDATE_INTERVAL_MILLIS)
    },

    async getNewData() {
      this.$store.commit("SET_BUSY", true)

      try {
        await this.$store.dispatch("requests/getByCustomer", this.customer._id)
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("App.vue: getNewData error:", err)
      }

      this.$store.commit("SET_BUSY", false)
      this.$store.commit("RESET_LAST_UPDATE_CALL")
      this.$store.commit("SET_NEW_DATA_AVAILABLE", false)

      if (this.selectedRequest) {
        console.log("setting selectedRequest")

        try {
          await this.$store.commit(
            "requests/SET_SELECTED_REQUEST",
            this.requests.find((o) => o._id === this.selectedRequest._id)
          )
        } catch (err) {
          console.error("getNewData error when setting selectedRequest:", err)
        }
      }
    },
  },

  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      whitelabel: "whitelabel",
      imprintVisible: "imprintVisible",
      privacyVisible: "privacyVisible",
      customerInfoVisible: "customerInfoVisible",
      profileVisible: "profileVisible",
      user: "users/user",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      newDataAvailable: "newDataAvailable",
      lastUpdateCall: "lastUpdateCall",
      lastUpdate: "requests/lastUpdate",
      error: "error/error",
      toastError: "error/toastError",
    }),
  },

  watch: {
    customer() {
      console.log("watch customer")
    },
  },

  async mounted() {
    console.log("APP MOUNTED", this.error)
    console.log(this.$route)
    window.addEventListener("orientationchange", this.onOrientationChange)
    window.addEventListener("resize", this.onResize)

    // catch # routes
    if (this.$route.fullPath.includes("#")) {
      this.$router.replace(this.$route.fullPath.replace("#/", ""))
    }

    const redirectToIntroOnLogout =
      this.$route.name !== "intro" &&
      this.$route.name !== "station-intro" &&
      this.$route.name !== "magicLinkLogin" &&
      this.$route.name !== "customerVerifyIntro" &&
      this.$route.name !== "registerQr" &&
      this.$route.name !== "registerCode" &&
      this.$route.name !== "registerToken"

    // Persistence
    // this.initAuthHandler();
    const token = localStorage.getItem("fiasco-token")
    const customer = JSON.parse(localStorage.getItem("fiasco-customer"))
    const user = JSON.parse(localStorage.getItem("fiasco-user"))
    if (
      token &&
      customer &&
      this.$route.name !== "registerCode" &&
      this.$route.name !== "registerQr" &&
      this.$route.name !== "registerToken"
    ) {
      let loggedIn
      try {
        loggedIn = await this.$store.dispatch("customers/login", token)
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
        if (loggedIn) {
          this.loadData()
        } else if (redirectToIntroOnLogout) {
          this.$router.push({ name: "intro" })
        }
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to login custumer", err)
      }
    } else if (redirectToIntroOnLogout) {
      this.$router.push({ name: "intro" })
      // } else if (this.$route.name != "customerActionVerify") {
      //   this.$router.push({ name: "CreateRequestPhotos" });
    }

    if (token && user) {
      this.$store.commit("SET_TOKEN", token)
      this.$store.commit("users/LOGIN", user)

      try {
        await this.onStationLogin()
      } catch (err) {
        console.error("Login failed (probably due to an expired token)", err)
        this.$store.commit("error/SET_ERROR", false)
      }
    }
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.preventNav)
  },
}
</script>
