<template>
  <div class="w-full h-full flex items-center flex-col justify-center" v-if="user">
    <div class="w-full h-16 absolute top-0 flex justify-center">
      <img
        class="w-16 p-4 absolute left-0 top-0"
        src="@/assets/gfx/icon-menu.png"
        @click="showMenu()"
      />
      <img
        class="h-full w-[calc(100%_-_12rem)] object-contain"
        v-if="!station.s3LogoImage"
        src="@/assets/gfx/fiasco-logo-white.png"
      />
      <s3-image
        class="h-full w-[calc(100%_-_8rem)] object-contain"
        :image="station.s3LogoImage"
        v-if="station.s3LogoImage"
      />
    </div>

    <div class="w-full pt-24 pb-24 h-full">
      <div class="w-full h-full overflow-y-auto">
        <div class="flex w-full">
          <div
            @click="folder = 'INBOX'"
            class="p-2 text-white w-1/2 border-b-2 text-center uppercase font-bold opacity-50 transition duration-300 relative"
            :class="{ 'opacity-100': folder == 'INBOX' }"
          >
            Eingang
            <div
              class="absolute w-5 h-5 border rounded-full top-0 right-8 bg-white text-fiasco-blue-dark flex justify-center items-center text-sm"
            >
              {{ inbox.length }}
            </div>
          </div>
          <div
            class="p-2 text-white w-1/2 border-b-2 text-center uppercase font-bold opacity-50 transition duration-300 relative"
            :class="{ 'opacity-100': folder == 'OFFERS' }"
            @click="folder = 'OFFERS'"
          >
            Angebote
            <div
              class="absolute w-5 h-5 border rounded-full top-0 right-8 bg-white text-fiasco-blue-dark flex justify-center items-center text-sm"
            >
              {{ offeredRequests.length }}
            </div>
          </div>
        </div>

        <div class="main-list m-4 pb-16" v-if="filteredRequests.length > 0">
          <div
            class="w-full bg-slate-200 rounded-xl overflow-hidden mb-4"
            v-for="item in filteredRequests"
            :key="item._id"
          >
            <div class="flex items-center justify-between p-2" @click="goRequest(item)">
              <s3-image
                class="w-14 h-14 rounded-full object-cover"
                v-if="item.s3Images.length > 0"
                :image="item.s3Images[item.s3Images.length - 1]"
              />
              <div class="text-right">
                <div>
                  <span class="text-sm">Auftrag-Nr.</span>
                  <span class="font-bold"> {{ item.fiascoId }}</span>
                </div>
                <div class="list-item-caption-main mb-1 text-sm">
                  {{ formatDate(item.createdAt) }}
                </div>
                <div class="text-sm">
                  {{ item.title }}
                </div>
              </div>
            </div>
            <div
              class="p-2 border-slate-400 border-t"
              :class="[
                {
                  'bg-fiasco-blue': item.offer && item.status === 'FIASCO_CALCULATION_SENT',
                },
                { 'text-white': item.offer && item.status === 'FIASCO_CALCULATION_SENT' },
              ]"
            >
              <div class="text-center">
                <img
                  v-if="item.offer && item.status === 'FIASCO_CALCULATION_SENT'"
                  class="w-8 block mx-auto"
                  src="@/assets/gfx/icon_mail_white.png"
                />
                <span v-if="item.offer && item.status !== 'OFFERED'">{{
                  requestStatusName(item.status)
                }}</span>
                <!--span v-if="!item.offer">Noch keine Angebote</span-->
                <button
                  v-if="!item.offer && item.status !== 'FIASCO_CALCULATION_REQUESTED'"
                  @click="startRequestFiasco(item)"
                  class="border-fiasco-purple border-2 rounded-full p-2 text-sm text-fiasco-purple"
                >
                  FIASCO Kalkulation anfordern
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="absolute bottom-0 left-0 z-20 px-4 w-full" v-if="user._id">
          <button
            class="rounded-full w-full bg-fiasco-purple text-white text-lg my-4 h-14 drop-shadow-lg"
            @click="createRequest()"
          >
            Fall melden
          </button>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="transform -translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="duration-200 ease-in"
      leave-from-class="translate-x-0"
      leave-to-class="transform -translate-x-full"
    >
      <Menu v-if="menuVisible" @close="menuVisible = false" @logout="onLogout" />
    </transition>

    <div
      class="absolute w-full h-full z-30 flex flex-col justify-center"
      v-if="requestFiascoPopupVisible"
    >
      <div
        class="w-full h-full absolute z-0 bg-slate-800 opacity-70"
        @click="closeFiascoRequestPopup()"
      />
      <div class="relative z-10 mx-2 mt-6 p-2 rounded-xl bg-slate-200 text-gray-dark mb-24">
        <h2 class="text-lg text-center my-2">FIASCO-Kalkulation anfordern</h2>
        <div class="mb-4" v-if="requestCar">
          <div v-if="requestCar.vin">VIN: {{ requestCar.vin }}</div>
          <div v-if="requestCar.numberPlate">Kennzeichen: {{ requestCar.numberPlate }}</div>
        </div>
        <div>
          <label class="pl-4 text-sm">Stundensätze</label>
          <select v-model="wageTemplate" class="w-full h-12 px-4 rounded-full block">
            <option v-for="item in station.wageTemplates" :value="item._id" :key="item._id">
              {{ item.displayName }}
            </option>
          </select>
        </div>
        <div class="text-center mt-6">
          <button
            class="bg-fiasco-blue rounded-full p-2 w-full text-white disabled:opacity-50"
            :disabled="!wageTemplate"
            @click="onRequestFiascoCalculationClicked()"
          >
            Senden
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import Menu from "./Menu.vue"

import dateDisplayMixin from "../mixins/dateDisplay"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  // eslint-disable-next-line vue/no-reserved-component-names
  name: "Main",
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Menu,
  },
  data() {
    return {
      menuVisible: false,
      profileVisible: false,
      showFixed: false,
      ratingRequest: null,
      emailSentAgain: false,
      folder: "INBOX",
      requestFiascoPopupVisible: false,
      fiascoRequest: null,
      requestCar: null,
      wageTemplate: null,
    }
  },
  mixins: [dateDisplayMixin],
  methods: {
    goRequest(item) {
      this.$store.commit("requests/SET_SELECTED_REQUEST", item)
      this.$store.commit("offers/SET_SELECTED_OFFER", null)
      this.$router.push({
        name: "request-edit",
        params: { id: item._id },
      })
    },
    async getRequests() {
      if (!this.user) return
      const payload = {
        id: this.user._id,
        params: {
          folder: "INBOX",
        },
      }
      try {
        await this.$store.dispatch("requests/getByUser", payload)
        console.log("GET REQUESTS INBOX")
        payload.params.folder = "OFFERS"
        await this.$store.dispatch("requests/getByUser", payload)
        console.log("GET REQUESTS OFFERS")
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to fetch request", err)
      }
    },
    createRequest() {
      const request = {
        user: this.user._id,
        carLocation: {
          zip: "",
        },
        colorMatchingAllowed: true, // Set the default value to "true"
      }
      this.$store.commit("requests/SET_CAMERA_PHOTOS", [])
      this.$store.commit("requests/SET_DOCUMENT_PHOTOS", [])
      this.$store.commit("requests/SET_REQUEST_PENDING", true)
      this.$store.commit("requests/SET_SELECTED_REQUEST", request)
      this.$router.push({ name: "CreateRequestPhotos" })
    },
    async startRequestFiasco(request) {
      this.fiascoRequest = request
      this.$store.commit("SET_BUSY", true)
      await this.$store.dispatch("cars/getByCustomer", this.fiascoRequest.customer)
      this.requestCar = this.customerCars.find((o) => o._id == this.fiascoRequest.car)
      this.$store.commit("SET_BUSY", false)
      this.requestFiascoPopupVisible = true
    },
    onRequestFiascoCalculationClicked() {
      // bypass old api
      // this.requestFiascoCalculation()
      // 2024 cloud api
      console.log("sending to 2024 cloud")
      this.sendToCloud()
    },
    async requestFiascoCalculation() {
      this.fiascoRequest.status = "FIASCO_CALCULATION_REQUESTED"
      let statusHistory = {
        status: this.fiascoRequest.status,
        user: this.userId,
        changedAt: new Date(),
      }
      this.fiascoRequest.statusHistory.unshift(statusHistory)
      this.$store.commit("SET_BUSY", true)
      await this.$store.dispatch("requests/update", this.fiascoRequest)

      const payload = {
        request: this.fiascoRequest._id,
        station: this.station._id,
        status: "QUALIFIED",
        calculation: this.station.wageTemplates.find((o) => o._id === this.wageTemplate)
          .calculationWages,
        contactPerson: this.user._id,
      }

      const res = await this.$store.dispatch("offers/create", payload)
      this.fiascoRequest.offer = res.data
      await this.$store.dispatch("requests/update", this.fiascoRequest)

      await this.$store.dispatch("requests/getActive")
      this.$store.commit("SET_BUSY", false)
      await this.getRequests()
      this.folder = "OFFERS"
      this.$store.commit("SET_BUSY", false)
      this.closeFiascoRequestPopup()
    },
    async sendToCloud() {
      await this.$store.dispatch("requests/getById", this.fiascoRequest._id)
      console.log("sendToCloud, request:", this.fiascoRequest)
      let editRequest = JSON.parse(JSON.stringify(this.fiascoRequest))

      editRequest.status = "FIASCO_CALCULATION_REQUESTED"
      const statusHistory = {
        status: editRequest.status,
        user: this.user._id,
        changedAt: new Date(),
      }
      editRequest.statusHistory.unshift(statusHistory)

      // create offer before sending to cloud
      const offerPayload = {
        request: this.fiascoRequest._id,
        station: this.station._id,
        status: "QUALIFIED",
        calculation: this.station.wageTemplates.find((o) => o._id === this.wageTemplate)
          .calculationWages,
        contactPerson: this.user._id,
      }

      const res = await this.$store.dispatch("offers/create", offerPayload)
      editRequest.offer = res.data

      await this.$store.dispatch("requests/update", editRequest)
      let baseCalculation = this.station.wageTemplates.find(
        (o) => o.displayName == "Standard-Werte"
      ).calculationWages

      let payload = {
        request: this.selectedRequest,
        baseCalculation: baseCalculation,
      }
      this.$store.commit("SET_BUSY", true)
      await this.$store.dispatch("requests/sendToCloud", payload)

      await this.$store.dispatch("requests/getActive")
      await this.getRequests()
      this.folder = "OFFERS"
      this.$store.commit("SET_BUSY", false)
      this.closeFiascoRequestPopup()
    },
    closeFiascoRequestPopup() {
      this.fiascoRequest = null
      this.requestFiascoPopupVisible = false
      this.wageTemplate = null
    },
    showMenu() {
      this.menuVisible = true
    },
    onLogout() {
      this.$emit("logout")
    },
  },
  computed: {
    filteredRequests() {
      if (this.folder === "INBOX") {
        return this.inbox
      } else {
        return this.offeredRequests
      }
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      user: "users/user",
      whitelabel: "whitelabel",
      customer: "customers/customer",
      openRequests: "requests/openRequests",
      requests: "requests/requests",
      inbox: "requests/inbox",
      offeredRequests: "requests/offered",
      requestStates: "requests/requestStates",
      fixedAndReadyRequests: "requests/fixedAndReadyRequests",
      fixedRequests: "requests/fixedRequests",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      offers: "offers/offers",
      requestStatusName: "requests/requestStatusName",
      customerStation: "stations/customerStation",
      customerCars: "cars/customerCars",
      station: "stations/selectedStation",
      newDataAvailable: "newDataAvailable",
      lastUpdateCall: "lastUpdateCall",
      lastUpdate: "requests/lastUpdate",
    }),
  },
  watch: {
    folder() {
      this.getRequests()
    },
    user() {
      this.getRequests()
    },
  },
  mounted() {
    this.getRequests()
  },
}
</script>
