<template>
  <div class="w-full h-full flex items-center flex-col justify-center">
    <div class="w-full h-16 absolute top-0 flex justify-center">
      <img
        class="w-16 p-4 absolute left-0 top-0"
        src="@/assets/gfx/icon-menu.png"
        @click="showMenu()"
      />
      <img
        class="h-full w-[calc(100%_-_12rem)] object-contain"
        v-if="!customerStation.s3LogoImage"
        src="@/assets/gfx/fiasco-logo-white.png"
      />
      <s3-image
        class="h-full w-[calc(100%_-_8rem)] object-contain"
        :image="customerStation.s3LogoImage"
        v-if="customerStation.s3LogoImage"
      />
    </div>

    <div
      v-if="openRequests.length == 0 && fixedAndReadyRequests.length == 0"
      class="text-white absolute z-10 left-4"
      :class="[
        {
          'bottom-[30vh]': openRequests.length == 0 && fixedAndReadyRequests.length == 0,
        },
        { 'bottom-20': openRequests.length > 0 || fixedAndReadyRequests.length > 0 },
      ]"
    >
      <div class="text-sm">Deine Werkstatt:</div>
      <div class="text-xl">
        {{ customerStation.name }}
      </div>
      <div class="text-sm">
        {{ customerStation.address }}
      </div>
      <div class="text-sm">{{ customerStation.zip }} {{ customerStation.city }}</div>
      <div class="flex mt-1">
        <a v-if="customerStation.phone" :href="'tel:' + customerStation.phone">
          <img
            class="w-8 h-8 p-1 mr-4 rounded-full border border-white object-contain"
            src="@/assets/gfx/icon_phone_white.png"
          />
        </a>
        <a v-if="customerStation.web" :href="customerStation.web" target="_blank">
          <img
            class="w-8 h-8 p-1 2 mr-4 rounded-full border border-white object-contain"
            src="@/assets/gfx/icon_web.png"
        /></a>
        <a v-if="customerStation.instagram" :href="customerStation.instagram" target="_blank">
          <img
            class="w-8 h-8 p-1 mr-4 rounded-full border border-white object-contain"
            src="@/assets/gfx/icon-insta.png"
          />
        </a>
        <a v-if="customerStation.facebook" :href="customerStation.facebook" target="_blank">
          <img
            class="w-8 h-8 p-1 mr-4 rounded-full border border-white object-contain"
            src="@/assets/gfx/icon-fb.png"
          />
        </a>
        <a v-if="customerStation.twitter" :href="customerStation.twitter" target="_blank">
          <img
            class="w-8 h-8 p-1 mr-4 rounded-full border border-white object-contain"
            src="@/assets/gfx/icon-twitter.png"
          />
        </a>
      </div>
    </div>

    <div
      v-if="customer && openRequests.length == 0 && fixedAndReadyRequests.length == 0"
      class="absolute bottom-0 left-0 w-full text-white"
    >
      <svg
        viewBox="0 0 100 15"
        class="fill-fiasco-purple w-full"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon points="0,14 100,0 100,15 0,15" />
      </svg>
      <div class="bg-fiasco-purple p-4 -mt-0.5">
        <h2 class="text-lg text-center mb-1">
          Willkommen bei deiner digitalen <span class="text-2xl">Schadensmeldung!</span>
        </h2>

        <button
          class="rounded-full w-full text-fiasco-purple bg-white text-lg my-4 h-12 drop-shadow-lg"
          @click="createRequest()"
        >
          Fall melden
        </button>
      </div>
    </div>
    <div class="w-full px-4 absolute top-20 bottom-20 overflow-y-auto" v-if="customer">
      <div class="text-center text-white" v-if="!customer.emailVerified">
        <p v-if="customer._id">Bitte bestätige deine E-Mail<br />innerhalb von 48 Stunden.</p>
        <button
          v-if="customer._id && !emailSentAgain"
          class="rounded-full text-white h-12 bg-fiasco-blue mt-2 mb-4 w-full"
          @click="resendRegister()"
        >
          E-Mail noch einmal senden
        </button>
        <p v-if="emailSentAgain">Bestätigungs-Email wurde versendet.</p>
      </div>

      <div v-if="!customer._id || (openRequests.length == 0 && fixedAndReadyRequests.length == 0)">
        <img
          class="max-h-[50vh] object-contain mx-auto block"
          src="@/assets/gfx/fox-victory-transparent.png"
        />
      </div>

      <!-- OPEN -->
      <div
        class="w-full bg-slate-200 rounded-xl overflow-hidden mb-4"
        v-for="item in openRequests"
        :key="item._id"
        @click="goRequest(item)"
      >
        <div class="flex items-center justify-between p-2">
          <s3-image
            class="w-14 h-14 rounded-full object-cover"
            v-if="item.s3Images.length > 0"
            :image="item.s3Images[item.s3Images.length - 1]"
          />
          <div class="text-right">
            <div>
              <span class="text-sm">Auftrag-Nr.</span>
              <span class="font-bold"> {{ item.fiascoId }}</span>
            </div>
            <div class="list-item-caption-main mb-1 text-sm">
              {{ formatDate(item.createdAt) }}
            </div>
            <div class="text-sm">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div
          class="p-2 border-slate-400 border-t"
          :class="[
            {
              'bg-fiasco-blue':
                (item.offer && item.status === 'OFFERED') ||
                item.status == 'INSPECTION_AGREED' ||
                item.status == 'REPAIR_AGREED',
            },
            { 'text-white': item.offer && item.status === 'OFFERED' },
          ]"
        >
          <div class="text-center">
            <img
              v-if="item.offer"
              class="w-8 block mx-auto"
              src="@/assets/gfx/icon_mail_white.png"
            />
            <span v-if="item.offer && item.status === 'OFFERED'">Dein Angebot ist da</span>
            <span
              v-if="item.offer && item.status !== 'OFFERED'"
              :class="{
                'text-white': item.status == 'INSPECTION_AGREED' || item.status == 'REPAIR_AGREED',
              }"
            >
              {{ requestStatusName(item.status) }}
            </span>
            <span v-if="!item.offer">Noch keine Angebote</span>
          </div>
        </div>
      </div>

      <!-- FIXED AND READY -->
      <div
        class="ready w-full bg-slate-200 rounded-xl overflow-hidden mb-4"
        v-for="item in fixedAndReadyRequests"
        :key="item._id"
        @click="goRequest(item)"
      >
        <div class="flex items-center justify-between p-2">
          <s3-image
            class="w-14 h-14 rounded-full object-cover"
            v-if="item.s3Images.length > 0"
            :image="item.s3Images[item.s3Images.length - 1]"
          />
          <div class="text-right">
            <div>
              <span class="text-sm">Auftrag-Nr.</span>
              <span class="font-bold"> {{ item.fiascoId }}</span>
            </div>
            <div class="list-item-caption-main mb-1 text-sm">
              {{ formatDate(item.createdAt) }}
            </div>
            <div class="text-sm">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="p-2 border-slate-400 border-t bg-fiasco-blue text-white">
          <div class="text-center">
            <img
              v-if="item.offer"
              class="w-8 h-8 object-contain block mx-auto invert"
              src="@/assets/gfx/icon_location.png"
            />
            <span>
              {{ requestStatusName(item.status) }}
            </span>
            <span v-if="!item.offer">Noch keine Angebote</span>
          </div>
        </div>
      </div>

      <!--button
        class="main-list-btn-closed-cases transparent"
        v-if="fixedRequests.length > 0"
        @click="showFixed = !showFixed"
      >
        <span v-if="!showFixed"
          >Abgeschlossene Fälle anzeigen ({{ fixedRequests.length }})</span
        >
        <span v-if="showFixed">Abgeschlossene Fälle verbergen</span>
      </button-->

      <!-- FIXED -->
      <div v-if="showFixed">
        <div class="list-item" v-for="item in fixedRequests" :key="item._id">
          <div class="list-item-top">
            <img
              class="list-item-image"
              v-if="item.s3Images.length === 0"
              :src="API.imageUrl + 'requests/' + item.images[item.images.length - 1]"
            />
            <s3-image
              class="profile-avatar"
              v-if="item.s3Images.length > 0"
              :image="item.s3Images[item.s3Images.length - 1]"
            />
            <div class="list-item-id">
              Auftrag-Nr. {{ item.fiascoId }} IMG
              {{ item.s3Images[item.s3Images.length - 1] }}
            </div>
            <div class="list-item-caption">
              <div class="list-item-caption-main">
                {{ item.title }}
              </div>
              <div class="list-item-caption-sub">
                {{ formatDate(item.createdAt) }}
              </div>
            </div>
          </div>
          <div class="list-item-state">
            <div class="list-item-state-caption">
              <span v-if="item.offer">{{ requestStatusName(item.status) }}</span>
            </div>
            <img v-if="item.offer" class="list-item-state-icon" src="@/assets/gfx/icon_mail.png" />
          </div>
          <div class="list-item-rating">
            <div class="list-item-rating-hint" v-if="!item.rating" @click="startRating(item)">
              Bist du zufrieden?
              <div class="list-item-rating-hint-cta">Jetzt bewerten</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="openRequests.length > 0" class="absolute bottom-0 left-0 z-20 px-4 w-full">
      <button
        class="rounded-full w-full bg-fiasco-purple text-white text-lg my-4 h-14 drop-shadow-lg"
        @click="createRequest()"
      >
        Fall melden
      </button>
    </div>

    <transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="transform -translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="duration-200 ease-in"
      leave-from-class="translate-x-0"
      leave-to-class="transform -translate-x-full"
    >
      <Menu v-if="menuVisible" @close="menuVisible = false" @logout="onLogout" />
    </transition>

    <div class="modal" v-if="ratingRequest">
      <div class="modal-clicker" @click="ratingRequest = null"></div>
      <div class="rating popup">
        <img
          class="popup-close"
          src="@/assets/gfx/icon_close_black.png"
          @click="ratingRequest = null"
        />
        <h3>Reparatur bewerten:<br />{{ ratingRequest.title }}</h3>
        <p>
          vom
          {{ formatDate(ratingRequest.statusHistory.find((o) => o.status === "FIXED").changedAt) }}
        </p>

        <div class="rating-line">
          <div class="rating-line-label">Preis/Leistung</div>
          <div class="rating-line-stars">
            <span
              v-for="n in 5"
              :key="n + 'star'"
              :class="{ active: ratingRequest.rating.performance >= n }"
              @click="setRating('performance', n)"
            >
              &starf;
            </span>
          </div>
        </div>
        <div class="rating-line">
          <div class="rating-line-label">Kommunikation</div>
          <div class="rating-line-stars">
            <span
              v-for="n in 5"
              :key="n + 'star'"
              :class="{ active: ratingRequest.rating.communication >= n }"
              @click="setRating('communication', n)"
            >
              &starf;
            </span>
          </div>
        </div>
        <div class="rating-line">
          <div class="rating-line-label">Zufriedenheit</div>
          <div class="rating-line-stars">
            <span
              v-for="n in 5"
              :key="n + 'star'"
              :class="{ active: ratingRequest.rating.satisfaction >= n }"
              @click="setRating('satisfaction', n)"
            >
              &starf;
            </span>
          </div>
        </div>

        <textarea v-model="ratingRequest.rating.comment" placeholder="Kommentar" />
        <button @click="sendRating()" :disabled="!ratingReady()">Senden</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import dateDisplayMixin from "../mixins/dateDisplay"

import Menu from "./Menu.vue"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  // eslint-disable-next-line vue/no-reserved-component-names
  name: "Main",
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Menu,
  },
  data() {
    return {
      menuVisible: false,
      profileVisible: false,
      showFixed: false,
      ratingRequest: null,
      emailSentAgain: false,
    }
  },
  mixins: [dateDisplayMixin],
  methods: {
    async goRequest(item) {
      this.$store.commit("requests/SET_SELECTED_REQUEST", item)
      this.$store.commit("offers/SET_SELECTED_OFFER", null)
      // this.$router.push({ name: "request-offers", params: { id: item._id } });
      /*
      if (item.status === "REQUESTED") {
        this.$router.push({ name: "request-edit", params: { id: item._id } });
      }
      */
      if (item.status === "OFFERED" && item.offer) {
        this.$router.push({
          name: "offer",
          params: {
            requestId: this.selectedRequest._id,
            id: item.offer,
          },
        })
      }

      if (
        item.status !== "OFFERED" &&
        item.offer &&
        item.statusHistory.some((o) => o.status === "OFFERED")
      ) {
        this.$router.push({
          name: "offer",
          params: {
            requestId: this.selectedRequest._id,
            id: item.offer,
          },
        })
      } else {
        if (item.status !== "OFFERED") {
          this.$router.push({
            name: "request-offers",
            params: { id: item._id },
          })
        }
      }
    },
    createRequest() {
      // Prepare data for a new request.
      // This is done in App.vue's method `onLogin`, too.
      const request = {
        customer: this.customer._id,
        carLocation: {
          zip: "",
        },
      }
      this.$store.commit("requests/SET_CAMERA_PHOTOS", [])
      this.$store.commit("requests/SET_DOCUMENT_PHOTOS", [])
      this.$store.commit("requests/SET_REQUEST_PENDING", true)
      this.$store.commit("requests/SET_SELECTED_REQUEST", request)

      this.$router.push({ name: "CreateRequestIntro" })
    },
    showMenu() {
      this.menuVisible = true
    },
    onLogout() {
      this.$emit("logout")
    },
    async resendRegister() {
      this.$store.commit("SET_BUSY", true)

      this.$store.commit("SET_BUSY", true)

      try {
        await this.$store.dispatch("customers/resendRegister", this.customer)
        this.emailSentAgain = true
        this.$store.commit("error/SET_TOAST_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_TOAST_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to get data from api", err)
      }
    },
    showProfile() {
      this.profileVisible = true
    },
    startRating(item) {
      this.ratingRequest = JSON.parse(JSON.stringify(item))
      this.ratingRequest.rating = {
        performance: 0,
        communication: 0,
        satisfaction: 0,
        comment: "",
      }
    },
    setRating(category, level) {
      this.ratingRequest.rating[category] = level
      this.$forceUpdate()
    },
    async sendRating() {
      this.ratingRequest.rating.ratedAt = new Date()
      this.$store.commit("SET_BUSY", true)

      try {
        await this.$store.dispatch("requests/update", this.ratingRequest)
        await this.$store.dispatch("requests/getByCustomer", this.customer._id)

        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }

      this.$store.commit("SET_BUSY", false)
      this.ratingRequest = null
    },
    ratingReady() {
      if (!this.ratingRequest) {
        return false
      } else {
        return (
          this.ratingRequest.rating.performance > 0 &&
          this.ratingRequest.rating.communication > 0 &&
          this.ratingRequest.rating.satisfaction > 0
        )
      }
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      whitelabel: "whitelabel",
      customer: "customers/customer",
      requests: "requests/requests",
      openRequests: "requests/openRequests",
      fixedAndReadyRequests: "requests/fixedAndReadyRequests",
      fixedRequests: "requests/fixedRequests",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      offers: "offers/offers",
      requestStatusName: "requests/requestStatusName",
      customerStation: "stations/customerStation",
      newDataAvailable: "newDataAvailable",
      lastUpdateCall: "lastUpdateCall",
      lastUpdate: "requests/lastUpdate",
    }),
  },
}
</script>
