<template>
  <div class="w-full h-full overflow-y-auto text-white" v-if="editItem && !busy">
    <div class="w-full px-4 pt-12 flex flex-col justify-between items-center">
      <s3-image
        class="w-32 h-32 m-2 rounded-full object-cover"
        :image="editItem.s3Images[editItem.s3Images.length - 1]"
        v-if="editItem.s3Images.length > 0"
      />
      <div class="text-center mb-2">
        <div class="text-xl">
          {{ this.editItem.fiascoId }}
        </div>
        <div class="header-caption-sub">
          {{ formatDate(this.editItem.createdAt) }}
        </div>
      </div>
    </div>

    <div class="px-4">
      <div class="text-center mb-6">
        <div class="request-edit-label">Status</div>
        {{ requestStatusName(editItem.status) }}
      </div>

      <div class="mb-6">
        <label class="text-center text-lg block">Beschreibung</label>
        <textarea
          class="p-2 rounded-xl text-gray-dark w-full block bg-white h-24 text-lg"
          v-model="editItem.description"
          @input="onChange()"
        />
      </div>
      <div class="mb-6">
        <label class="text-center text-lg block">Schaden</label>
        <div
          class="flex w-full justify-between my-1 border-b border-slate-500"
          v-for="item in editItem.damages"
          :key="item._id"
        >
          <span>{{ damageLocationName(item.location) }}</span>
          <span>{{ damageTypeName(item.type) }}</span>
        </div>
        <p class="text-sm opacity-50 my-2">
          Schäden und Fahrzeug können nicht geändert werden. Erzeuge eine neue Anfrage für
          grundsätzliche Änderungen.
        </p>
      </div>
      <div class="mb-6">
        <label class="text-center text-lg block">Fahrzeug</label>
        <div class="request-edit-car">
          <!--img class="request-edit-car-avatar" :src="API.imageUrl + 'cars/' + requestCar.image"-->
          <div v-if="editItem.car.manufacturer || editItem.car.model">
            {{ editItem.car.manufacturer }}&nbsp;{{ editItem.car.model }}
          </div>
          <div class="small">{{ editItem.car.numberPlate }}</div>
          <div class="small">VIN {{ editItem.car.vin }}</div>
        </div>
      </div>
      <div class="mb-8">
        <label class="text-lg text-center block">Fotos</label>

        <div class="">
          <div class="flex flex-wrap my-6">
            <div
              class="relative w-16 h-16 m-3"
              v-for="(item, index) in editItem.s3Images"
              :key="index + 'img'"
            >
              <s3-image class="w-16 h-16 rounded object-cover" :image="item" />
              <img
                class="w-7 h-7 absolute -top-3 -right-3 bg-fiasco-blue-dark p-1 rounded-full"
                src="@/assets/gfx/icon_trash_white.png"
                @click="deleteRequestImage = item"
              />
            </div>
          </div>

          <div class="flex mb-24 mt-4 justify-center">
            <div
              class="w-14 h-14 rounded-full bg-fiasco-blue mr-4 p-3"
              v-if="editItem.images.length < 9"
            >
              <label for="camera-select" class="input-camera">
                <img class="input-camera-icon" src="@/assets/gfx/icon_camera.png" />
              </label>
              <input
                id="camera-select"
                class="w-0 h-0"
                type="file"
                accept="image/*"
                multiple
                @change="onFileSelect"
              />
            </div>

            <div
              class="w-14 h-14 rounded-full bg-fiasco-blue p-3"
              v-if="editItem.images.length < 9"
            >
              <label for="file-select" class="input-camera">
                <img class="input-camera-icon" src="@/assets/gfx/icon_gallery.png" />
              </label>
              <input
                id="file-select"
                class="w-0 h-0"
                type="file"
                accept="image/*"
                multiple
                @change="onFileSelect"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="absolute top-0 left-0 z-30 w-full h-full bg-transparent-black p-4 flex items-center justify-center"
      v-if="deleteRequestImage"
    >
      <div class="absolute z-0 top-0 left-0 w-full h-full" @click="deleteRequestImage = null"></div>
      <div class="p-2 rounded-xl bg-slate-200 text-gray-dark w-full max-w-xl relative z-10">
        <h2 class="text-xl text-center">Bild löschen?</h2>
        <div class="flex">
          <button
            class="bg-fiasco-blue p-2 h-12 rounded-full text-white w-full m-2"
            @click="deleteRequestImage = null"
          >
            Abbrechen
          </button>
          <button
            class="bg-fiasco-purple p-2 h-12 rounded-full text-white w-full m-2"
            @click="deletePhoto()"
          >
            Bestätigen
          </button>
        </div>
      </div>
    </div>

    <div
      class="absolute bottom-4 left-4 w-14 h-14 p-1 rounded-full bg-fiasco-blue-dark border-2 border-white"
      @click="goBack()"
    >
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
// import Camera from './Camera.vue'

import dateDisplayMixin from "../mixins/dateDisplay"

export default {
  name: "RequestEdit",
  components: {
    // Camera
  },
  data() {
    return {
      editItem: null,
      changeTimeout: null,
      requestCar: null,
      photos: [],
      cameraActive: false,
      deleteRequestImage: null,
    }
  },
  mixins: [dateDisplayMixin],
  methods: {
    goBack() {
      // this.$router.push({name: 'main'})
      this.$router.go(-1)
    },
    async getEditData() {
      console.log("request edit getEditData", this.$route.params.id)
      this.$store.commit("SET_BUSY", true)
      try {
        await this.$store.dispatch("requests/getById", this.$route.params.id)
        console.log(this.selectedRequest)
        this.editItem = JSON.parse(JSON.stringify(this.selectedRequest))
        this.requestCar = this.customerCars.find((o) => o._id === this.editItem.car)
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to get request data", err)
      }
    },
    onChange() {
      console.log("onChange")
      if (this.changeTimeout) {
        window.clearTimeout(this.changeTimeout)
      }
      this.changeTimeout = window.setTimeout(() => {
        console.log("now we change")
        this.updateRequest()
        window.clearTimeout(this.changeTimeout)
      }, this.CHANGE_TIMEOUT_MS)
    },
    async updateRequest() {
      this.$store.commit("SET_BUSY", true)
      try {
        await this.$store.dispatch("requests/update", this.editItem)
        this.getEditData()
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to update request", err)
      }
    },
    onGrab(photo) {
      this.photos.push(photo)
      this.$store.commit("requests/SET_CAMERA_PHOTOS", this.photos)
    },
    resetCameraPhotos() {
      this.photos = []
      this.$store.commit("requests/SET_CAMERA_PHOTOS", this.photos)
    },
    async uploadPhotos() {
      console.log("uploading pic")
      if (this.cameraPhotos.length > 0) {
        this.$store.commit("SET_BUSY", true)
        console.log("uploading photos: ")
        console.log(this.cameraPhotos)
        const that = this
        const images = that.editItem.s3Images
        let uploadIndex = that.editItem.s3Images.length

        // Upload each photo to S3 and store its key in the images array.
        try {
          for await (const photo of this.cameraPhotos) {
            const photoUploadPayload = {
              image: photo,
              key: "images/requests/" + this.editItem._id + "-" + uploadIndex,
            }
            const uploadRes = await this.$store.dispatch("uploadS3CameraPhoto", photoUploadPayload)
            console.debug("photo uploaded, key =", uploadRes)
            images.push(uploadRes)
            console.debug("added key to images", images)
            uploadIndex++
          }
        } catch (err) {
          this.$store.commit("error/SET_ERROR", true)
          this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
          this.$store.commit("error/SET_ERROR_CODE", err.status)
          this.$store.commit("SET_BUSY", false)
          throw new Error("Failed to upload pictures to s3", err)
        }

        console.debug("image keys", images)

        // Update the request with the storage keys.
        try {
          const updateRequestWithPhotosPayload = {
            _id: this.editItem._id,
            s3Images: images,
          }

          await this.$store.dispatch("requests/update", updateRequestWithPhotosPayload)
        } catch (err) {
          this.$store.commit("error/SET_ERROR", true)
          this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
          this.$store.commit("error/SET_ERROR_CODE", err.status)
          this.$store.commit("SET_BUSY", false)
          throw new Error("Failed to upload pictures to s3", err)
        }

        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)

        console.log("All photos have been processed successfully")
        this.$store.commit("requests/SET_REQUEST_PENDING", false)
        try {
          await this.$store.dispatch("requests/getByCustomer", this.customer._id)
          this.getEditData()
          this.resetCameraPhotos()
          this.cameraActive = false
          this.$store.commit("SET_BUSY", false)
          this.$store.commit("error/SET_ERROR", false)
        } catch (err) {
          this.$store.commit("SET_BUSY", false)
          this.$store.commit("error/SET_ERROR", true)
          this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
          this.$store.commit("error/SET_ERROR_CODE", err.status)
          throw new Error("Failed to load picutes", err)
        }
      }
    },
    deletePhoto() {
      const item = this.deleteRequestImage
      console.log(item)
      console.log(this.editItem.s3Images)
      this.editItem.s3Images = this.editItem.s3Images.filter((o) => o !== item)
      this.deleteRequestImage = null
      this.updateRequest()

      /*
      let payload = {
        file: item
      }
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('requests/deletePhoto', payload).then((res) => {
        console.log(res)
        this.editItem.images = this.editItem.images.filter(o => o !== item)
        this.deleteRequestImage = null
        this.updateRequest()
        this.resetCameraPhotos()
      })
      */
    },
    async onFileSelect(e) {
      console.log("selecting file 111111")
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      this.$store.commit("SET_BUSY", true)
      try {
        const blob = await this.$store.dispatch("fileToSizedBlob", { file: files[0] })
        this.photos.push(blob)
        this.$store.commit("requests/SET_CAMERA_PHOTOS", this.photos)
        await this.uploadPhotos()
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed load file", err)
      }
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      CHANGE_TIMEOUT_MS: "CHANGE_TIMEOUT_MS",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestStatusName: "requests/requestStatusName",
      activeOffers: "offers/activeOffers",
      damageLocationName: "requests/damageLocationName",
      damageTypeName: "requests/damageTypeName",
      cameraPhotos: "requests/cameraPhotos",
      customerCars: "cars/customerCars",
      customerStation: "stations/customerStation",
    }),
  },
  mounted() {
    this.getEditData()
  },
}
</script>
