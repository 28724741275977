<template>
  <div class="h-full text-white flex flex-col justify-center relative">
    <img
      class="absolute top-2 w-full h-16 object-contain z-10"
      v-if="!whitelabel"
      src="@/assets/gfx/fiasco-logo-white.png"
    />
    <div class="max-w-sm w-full flex flex-col p-4 mx-auto">
      <h2 class="text-3xl uppercase text-center my-2">Profi-Login</h2>
      <img v-if="whitelabel === 'rep2go'" src="@/assets/gfx/Rep2Go_logo_white.png" />
      <img v-if="whitelabel === 'mobilityconcept'" src="@/assets/gfx/mobility-concept-logo.png" />
      <div class="intro-nav">
        <form
          @submit.prevent="onLogin()"
          class="login-form"
          :class="{ error: loginError }"
          v-if="!onboardingVisible"
        >
          <input
            type="text"
            class="text-gray px-4 text-lg w-full rounded-full mb-4 h-12"
            v-model="loginForm.email"
            placeholder="E-Mail"
          />
          <input
            type="password"
            class="text-gray px-4 text-lg w-full rounded-full mb-4 h-12"
            v-model="loginForm.password"
            placeholder="Passwort"
          />
          <input
            class="rounded-full w-full bg-fiasco-purple text-white text-lg mb-4 h-12 disabled:opacity-50"
            type="submit"
            value="Login"
          />
        </form>
        <p class="intro-error" v-if="loginError">
          Das hat nicht funktioniert. Überprüfe E-Mail und Passwort.
        </p>
      </div>
    </div>
    <div class="flex absolute bottom-2 w-full justify-center">
      <div class="mx-2 text-white text-xs opacity-50" @click="openImprint()">Impressum</div>
      <div class="mx-2 text-white text-xs opacity-50" @click="openPrivacy()">Datenschutz</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "StationIntro",
  data() {
    return {
      hits: 0,
      password: "123",
      loginForm: {
        email: "",
        password: "",
      },
    }
  },
  methods: {
    goMain() {
      this.$router.push({ name: "main" })
    },
    goRegisterCode() {
      this.$router.push({ name: "registerCode" })
    },
    goRegisterQr() {
      this.$router.push({ name: "registerQr" })
    },

    onLogout() {
      console.log("onLogout")
      this.$store.commit("customers/LOGOUT")
      this.$store.commit("requests/SET_ALL", [])
      this.$store.commit("cars/SET_CUSTOMER_CARS", [])
      window.clearInterval(this.updateInterval)
      this.$store.commit("SET_BUSY", false)
      this.$router.push({ name: "intro" })
    },

    async onLogin() {
      const payload = {
        email: this.loginForm.email,
        password: this.loginForm.password,
      }
      console.log("on user login")
      this.$store.commit("users/SET_LOGIN_ERROR", null)
      this.$store.commit("SET_BUSY", true)
      try {
        await this.$store.dispatch("users/login", payload)
        if (!this.loginError) {
          this.$emit("stationlogin")
        } else {
          console.log(this.loginError)
        }
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        if (err?.response?.status === 401) {
          this.$store.commit("users/SET_LOGIN_ERROR", "Passwort nicht korrekt")
        } else if (err?.response?.status === 404) {
          this.$store.commit("users/SET_LOGIN_ERROR", "Benutzer nicht gefunden")
        } else {
          if (err.response?.status === 401) {
            this.onLogout()
          }
        }
        this.$store.commit("SET_BUSY", false)
      }
    },
    openImprint() {
      this.$store.commit("SET_IMPRINT_VISIBLE", true)
    },
    openPrivacy() {
      this.$store.commit("SET_PRIVACY_VISIBLE", true)
    },
    openCustomerInfo() {
      this.$store.commit("SET_CUSTOMER_INFO_VISIBLE", true)
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      user: "users/user",
      customer: "customers/customer",
      loginError: "users/loginError",
      whitelabel: "whitelabel",
      onboardingVisible: "onboardingVisible",
    }),
  },
  mounted() {
    console.log("STATION NTRO MOUNTED")

    if (this.$route.params.partner === "rep2go") {
      // todo: check exists
      this.$store.commit("SET_WHITELABEL", this.$route.params.partner)
    }
    if (this.$route.params.partner === "mobilityconcept") {
      this.$store.commit("SET_WHITELABEL", this.$route.params.partner)
    }
    if (this.$route.name === "Imprint") {
      this.$store.commit("SET_IMPRINT_VISIBLE", true)
    }
    if (this.$route.name === "Privacy") {
      this.$store.commit("SET_PRIVACY_VISIBLE", true)
    }
    /*
    if (window.location.hostname === 'localhost') {
      this.loginForm.email = 'miranda@car.de'
      this.loginForm.password = '123'
    }
    */
  },
}
</script>
