<template>
  <div class="absolute z-10 top-0 pt-16 left-0 right-0 bottom-0 text-white">
    <div class="create-request-photos-hint" v-if="hintVisible">
      <p>
        <strong>Fotos von deinem Fahrzeug</strong> und dem Schaden helfen der Werkstatt, ein Angebot
        zu erstellen.
      </p>
      <p>Wir zeigen dir gleich ein paar <strong>Beispielbilder</strong>.</p>
      <button @click="onHintConfirm()">Alles klar!</button>
    </div>

    <div class="absolute z-20 top-0 left-0 w-full h-full bg-slate-200 overflow-y-auto">
      <Camera @grab="onGrab"> </Camera>
      <div
        class="absolute z-50 w-full h-full top-0 left-0 flex flex-col justify-center items-center pointer-events-none"
      >
        <div class="text-xl p-1 rounded animate-pulse">
          {{ photoStep.title }}
        </div>
        <img class="animate-pulse w-full" :src="publicPath + 'examples/' + photoStep.icon" />
      </div>
    </div>

    <div
      class="absolute z-20 bottom-4 left-4 w-14 h-14 p-1 rounded-full bg-fiasco-blue"
      @click="goBack()"
    >
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>

    <div class="absolute z-20 bottom-20 w-full flex justify-center">
      <div
        class="w-16 h-16 border-gray-dark border-2 rounded-full relative"
        v-if="cameraPhotos.length > 0"
        @click="collectionDetailsVisible = true"
      >
        <img
          class="object-cover rounded-full h-full w-full"
          v-if="cameraPhotos.length > 0"
          :src="cameraPhotos[cameraPhotos.length - 1]"
        />
        <div
          class="absolute top-0 right-0 w-5 h-5 text-xs bg-white text-gray-dark flex items-center justify-center font-bold rounded-full"
        >
          {{ cameraPhotos.length }}
        </div>
      </div>
    </div>

    <button
      :disabled="cameraPhotos.length < 1"
      class="absolute z-20 bottom-4 right-4 w-14 h-14 p-1 rounded-full bg-fiasco-purple disabled:opacity-50"
      @click="goNext()"
    >
      <img src="@/assets/gfx/arrow-next.png" />
    </button>

    <transition name="fade">
      <div
        class="absolute top-0 left-0 z-30 w-full h-full flex flex-col justify-center"
        v-if="collectionDetailsVisible"
      >
        <div
          class="absolute z-0 top-0 left-0 w-full h-full bg-transparent-black"
          @click="collectionDetailsVisible = false"
        ></div>
        <div class="mx-4 bg-slate-200 rounded p-4 relative z-1">
          <img
            class="w-8 h-8 absolute top-2 right-2 opacity-50"
            src="@/assets/gfx/icon_close_black.png"
            @click="collectionDetailsVisible = false"
          />
          <h3 class="my-4 text-xl text-center text-gray-dark">Meine Fotos zum Schaden</h3>
          <div class="grid grid-cols-3">
            <div
              class="m-2 w-16 h-16 rounded border-2 relative"
              v-for="(item, index) in cameraPhotos"
              :key="index + 'col'"
            >
              <img class="w-full h-full object-cover" :src="item" />
              <div
                class="create-request-photos-collection-item-btn-del"
                @click="removePhotoByIndex(index)"
              >
                <img
                  class="absolute -top-2 -right-2 w-5 h-5 bg-white rounded-full"
                  src="@/assets/gfx/icon_close_black.png"
                />
              </div>
            </div>
            <div
              class="create-request-photos-collection-item"
              v-for="n in untakenPictures"
              :key="n"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import Camera from "./Camera.vue"

export default {
  name: "CreateRequestPhotos",
  components: {
    Camera,
  },
  data() {
    return {
      hintVisible: false,
      photoIndex: 0,
      deletePhotoRequestIndex: -1,
      deletePhotoRequest: null,
      collectionDetailsVisible: false,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      cameraStarted: false,
      alternativeCamera: false,
      publicPath: process.env.BASE_URL,
    }
  },
  methods: {
    goBack() {
      if (this.customer) {
        this.$router.push({ name: "CreateRequestPhotosExample" })
      }
      if (this.user) {
        this.$router.push({ name: "station-main" })
      }
    },
    goNext() {
      this.$router.push({ name: "CreateRequestCar" })
    },
    onGrab(photo) {
      this.$store.commit("requests/ADD_CAMERA_PHOTO", photo)
      // this.photos = this.cameraPhotos
      // this.photos.push(photo)
      // this.$store.commit('requests/SET_CAMERA_PHOTOS', this.photos)
    },
    removePhotoByIndex(index) {
      this.$store.commit("requests/REMOVE_CAMERA_PHOTO", index)
    },
    removePhoto() {
      console.log(this.deletePhotoRequestIndex)
      this.$store.commit("requests/REMOVE_CAMERA_PHOTO", this.deletePhotoRequestIndex)
      this.deletePhotoRequestIndex = -1
    },
    onHintConfirm() {
      this.hintVisible = false
      this.examplesVisible = true
    },
    cancelDeletePhoto() {
      console.log("cancelDeletePhoto")
      this.deletePhotoRequestIndex = -1
      console.log(this.deletePhotoRequestIndex)
    },
    showCollection() {
      if (this.cameraPhotos.length > 0) {
        this.collectionDetailsVisible = true
      }
    },
    onStarted(stream) {
      console.log("On Started Event", stream)
      this.cameraStarted = true
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream)
      this.cameraStarted = false
    },
    stopCamera() {
      this.$refs.webcam.stop()
    },
    startCamera() {
      console.log("On Start")
      this.$refs.webcam.start()
    },
    onError(error) {
      console.log("On Error Event", error)
    },
    onCameras(cameras) {
      this.devices = cameras
      console.log("On Cameras Event", cameras)
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId
      this.camera = deviceId
      console.log("On Camera Change Event", deviceId)
    },
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId)
    },
    untakenPictures() {
      return this.cameraPhotos.length < 4 ? 4 - this.cameraPhotos.length : 0
    },
    photoStep() {
      const step = {
        title: "",
        icon: "",
      }
      if (this.cameraPhotos.length === 0) {
        step.title = "Diagonal von vorne"
        step.icon = "photo-template-diagonal-front.png"
      }
      if (this.cameraPhotos.length === 1) {
        step.title = "Diagonal von hinten"
        step.icon = "photo-template-diagonal-rear.png"
      }
      if (this.cameraPhotos.length === 2) {
        step.title = "Beschädigte Teil(e) 1-2m Abstand"
        step.icon = "photo-template-part.png"
      }
      if (this.cameraPhotos.length > 2) {
        step.title = "Beschädigte Teil(e) nah"
        step.icon = "photo-template-damage.png"
      }
      return step
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      user: "users/user",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      selectedRequest: "requests/selectedRequest",
      cameraPhotos: "requests/cameraPhotos",
    }),
  },
  watch: {
    camera: function (id) {
      this.deviceId = id
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices
      console.log(tail)
      if (first) {
        this.camera = first.deviceId
        this.deviceId = first.deviceId
        this.startCamera()
      }
    },
  },
  mounted() {
    // The value in this variable is read from local state, which can be unprepared if the browser was refreshed.
    if (!this.selectedRequest) {
      this.$router.push({ name: "intro" })
    }

    // this.initCam()
    /*
    this.cameraPhotos.forEach(element => {
      this.photos.push(element)
    })
    */
  },
  beforeUnmount() {
    // this.stopCamera()
  },
}
</script>
