<template>
  <div class="h-full overflow-y-auto pt-16 text-white">
    <h2 class="text-center text-xl mt-4" v-if="!resultVisible">Fahrzeugschein scannen</h2>
    <h2 class="text-center text-xl mt-4" v-if="resultVisible">Scan fertig!</h2>

    <div class="text-center m-4" v-if="!resultVisible">
      <p class="mb-2">Aufklappen und Innenseite scannen:</p>
      <img class="rounded" src="@/assets/gfx/placeholder_kfz_schein.jpg" />
    </div>

    <div class="w-16 h-16 rounded-full bg-fiasco-purple m-2 p-3 mx-auto" v-if="!resultVisible">
      <label for="file-select" class="input-camera profile-btn-avatar">
        <img class="input-camera-icon btn-icon" src="@/assets/gfx/icon_camera.png" />
      </label>
      <input
        id="file-select"
        class="w-0 h-0"
        type="file"
        capture="environment"
        accept="image/jpeg,image/png,application/pdf"
        @change="onFileChange"
      />
    </div>
    <div class="mx-4" v-if="previewPhoto">
      <img class="rounded max-w-full" :src="previewPhoto" />
    </div>
    <div class="m-2" v-if="scanResult.errorMsg">
      <p>Fehler:</p>
      <p>{{ scanResult.errorMsg }}</p>
    </div>
    <div class="px-4" v-if="resultVisible">
      <div class="text-center p-1 my-2 bg-fiasco-yellow text-gray-dark rounded">
        Bitte Daten überprüfen:
      </div>
      <label class="text-sm opacity-80 block mb-1 ml-4">FIN</label>
      <input v-model="scanResult.vin" class="p-2 rounded-full mb-2 w-full text-gray-dark" />
      <label class="text-sm opacity-80 block mb-1 ml-4">Nummernschild</label>
      <input v-model="scanResult.numberPlate" class="p-2 rounded-full mb-2 w-full text-gray-dark" />
      <label class="text-sm opacity-80 block mb-1 ml-4">Erstzulassung (JJJJ-MM-TT)</label>
      <input
        v-model="scanResult.firstRegistration"
        class="p-2 rounded-full mb-2 w-full text-gray-dark"
      />
      <label class="text-sm opacity-80 block mb-1 ml-4">Vorname</label>
      <input v-model="scanResult.firstName" class="p-2 rounded-full mb-2 w-full text-gray-dark" />
      <label class="text-sm opacity-80 block mb-1 ml-4">Nachname</label>
      <input v-model="scanResult.lastName" class="p-2 rounded-full mb-2 w-full text-gray-dark" />
      <label class="text-sm opacity-80 block mb-1 ml-4">Adresse</label>
      <input v-model="scanResult.address" class="p-2 rounded-full mb-2 w-full text-gray-dark" />
      <label class="text-sm opacity-80 block mb-1 ml-4">PLZ</label>
      <input v-model="scanResult.zip" class="p-2 rounded-full mb-2 w-full text-gray-dark" />
      <label class="text-sm opacity-80 block mb-1 ml-4">Ort</label>
      <input v-model="scanResult.city" class="p-2 rounded-full mb-24 w-full text-gray-dark" />
    </div>

    <!--div class="create-request-registration-scan-subnav">
      <button class="secondary" @click="close()">Abbrechen</button>
      <button @click="commitVin" v-if="scanResult.vin">Daten übernehmen</button>
    </div-->

    <button
      class="absolute bottom-4 left-4 w-14 h-14 p-1 rounded-full bg-transparent border-2 border-white"
      @click="close()"
    >
      <img src="@/assets/gfx/arrow-prev.png" />
    </button>

    <button
      class="absolute bottom-4 right-4 w-14 h-14 p-1 rounded-full bg-fiasco-purple disabled:opacity-50"
      @click="commitVin()"
      :disabled="!scanResult.vin"
    >
      <img src="@/assets/gfx/arrow-next.png" />
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
const pdfjsLib = require("pdfjs-dist/build/pdf.js")
const pdfjsWorker = require("pdfjs-dist/build/pdf.worker.entry")
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

export default {
  name: "CreateRequestVin",
  data() {
    return {
      resultString: "",
      selectedFile: false,
      editCar: {
        vin: "",
        kilometers: 0,
        owner: {},
      },
      previewPhoto: "",
      scanResult: {
        vin: null,
        numberPlate: null,
        contructionYear: null,
        zip: null,
      },
      customerData: {},
      resultVisible: false,
    }
  },
  methods: {
    close() {
      // this.$emit("close");
      this.$router.push({ name: "CreateRequestCar" })
    },

    async onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      this.$store.commit("SET_BUSY", true)

      let document = null

      try {
        if (files[0].type == "application/pdf") {
          const pdf = files[0]
          document = await this.convertPdfToPng(pdf)
        } else {
          document = files[0]
        }

        let blob = await this.$store.dispatch("fileToSizedBlob", { file: document })
        this.previewPhoto = blob

        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)

        this.scanK()
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit(
          "error/SET_ERROR_MESSAGE",
          "Die ausgewählte Datei konnte nicht verarbeitet werden"
        )
        this.$store.commit("error/SET_ERROR_CODE", 0)

        this.$store.commit("SET_BUSY", false)

        // console.error("could not process selected file", err);
        // this.errorMsg = "Datei konnte nicht verarbeitet werden";
        // this.$store.commit("SET_BUSY", false);
      }
    },

    async convertPdfToPng(file) {
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader()
        pdfjsLib.disableWorker = true
        let currentPage = 1
        fileReader.onload = async (e) => {
          try {
            const uint8array = new Uint8Array(e.target.result)
            const pdf = await pdfjsLib.getDocument({ data: uint8array }).promise
            const page = await pdf.getPage(currentPage)
            const viewport = page.getViewport({ scale: 1.5 })
            const canvas = document.createElement("canvas")
            const ctx = canvas.getContext("2d")
            canvas.height = viewport.height
            canvas.width = viewport.width

            await page.render({ canvasContext: ctx, viewport: viewport }).promise
            canvas.toBlob(async (blob) => {
              const image = new File([blob], `page-${currentPage}.png`, {
                lastModified: new Date(),
                type: "image/png",
              })
              resolve(image)
            })
          } catch (err) {
            reject(err)
          }
        }
        fileReader.onerror = reject
        fileReader.readAsArrayBuffer(file)
      })
    },

    resetForm: function () {
      this.errorMsg = null
      this.scanResult.vin = null
      this.scanResult.numberPlate = null
      this.scanResult.zip = null
      this.scanResult.city = null
      this.scanResult.address = null
      this.scanResult.firstRegistration = null
    },

    async scanK() {
      this.resetForm()
      this.$store.commit("SET_BUSY", true)

      const payload = {
        image: this.previewPhoto,
      }

      let res
      try {
        res = await this.$store.dispatch("scanK", payload)

        console.log(payload)

        console.log(res)
        this.$store.commit("SET_BUSY", false)
        if (res.data) {
          console.log("SCAN DONE")

          if (res.data.datum_erstzulassung_lang) {
            if (res.data.datum_erstzulassung_lang.length > 0) {
              if (res.data.datum_erstzulassung_lang[0].probability > 0.8) {
                this.scanResult.firstRegistration = res.data.datum_erstzulassung_lang[0].value
              }
            }
          }
          if (res.data.fin) {
            if (res.data.fin.length > 0) {
              if (res.data.fin[0].probability > 0.9) {
                this.scanResult.vin = res.data.fin[0].value
              }
            }
          }
          if (res.data.hsn_tsn) {
            if (res.data.hsn_tsn.length > 0) {
              if (res.data.hsn_tsn[0].probability > 0.9) {
                this.scanResult.hsnTsn = res.data.hsn_tsn[0].value
              }
            }
          }
          if (res.data.kennzeichen) {
            if (res.data.kennzeichen.length > 0) {
              if (res.data.kennzeichen[0].probability > 0.8) {
                const rawNumberPlate = res.data.kennzeichen[0].value
                const position = rawNumberPlate.search(/\d/)
                const formatted =
                  rawNumberPlate.substring(0, position) + " " + rawNumberPlate.substring(position)
                this.scanResult.numberPlate = formatted // res.data.kennzeichen[0].value
              }
            }
          }
          if (res.data.plz) {
            if (res.data.plz.length > 0) {
              if (res.data.plz[0].probability > 0.8) {
                this.scanResult.zip = res.data.plz[0].value
              }
            }
          }

          if (res.data.name) {
            if (res.data.name.length > 0) {
              if (res.data.name[0].probability > 0.8) {
                this.scanResult.lastName = res.data.name[0].value
              }
            }
          }
          if (res.data.vorname) {
            if (res.data.vorname.length > 0) {
              if (res.data.vorname[0].probability > 0.8) {
                this.scanResult.firstName = res.data.vorname[0].value
              }
            }
          }
          if (res.data.strasse) {
            if (res.data.strasse.length > 0) {
              if (res.data.strasse[0].probability > 0.8) {
                this.scanResult.address = res.data.strasse[0].value
              }
            }
          }
          if (res.data.ort) {
            if (res.data.ort.length > 0) {
              if (res.data.ort[0].probability > 0.8) {
                this.scanResult.city = res.data.ort[0].value
              }
            }
          }
        } else {
          this.errorMsg = "Konnte nicht scannen."
        }
        if (this.scanResult.vin) {
          // this.commitVin()

          this.customerData.firstName = this.scanResult.firstName
          this.customerData.lastName = this.scanResult.lastName
          this.customerData.city = this.scanResult.city
          this.customerData.address = this.scanResult.address
          this.customerData.zip = this.scanResult.zip
          this.resultVisible = true
          // existing values will not be overridden:

          console.log("SETTING CUSTOMER DATA:")
          console.log(this.customerData)
          this.$store.commit("customers/SET_CUSTOMER_DATA", this.customerData)
        } else {
          this.errorMsg = "Daten nicht erkennbar."
        }
      } catch (e) {
        console.log(e)
        this.$store.commit("SET_BUSY", false)
        this.errorMsg = "Konnte nicht scannen."
      }
    },
    commitVin() {
      this.editCar.customer = this.customer?._id
      this.editCar.vin = this.scanResult.vin
      this.editCar.numberPlate = this.scanResult.numberPlate
      this.editCar.firstRegistration = this.scanResult.firstRegistration
      this.editCar.hsnTsn = this.scanResult.hsnTsn

      this.editCar.owner.firstName = this.scanResult.firstName
      this.editCar.owner.lastName = this.scanResult.lastName
      this.editCar.owner.address = this.scanResult.address
      this.editCar.owner.zip = this.scanResult.zip
      this.editCar.owner.city = this.scanResult.city
      this.$store.commit("requests/ADD_CAMERA_PHOTO", this.previewPhoto) // previewPhoto has already been processed by 'fileToSizedBlob'
      this.$store.commit("cars/SET_CREATE_REQUEST_CAR", this.editCar)
      console.log(this.createRequestCar)
      this.$router.push({ name: "CreateRequestDamageIntro" })
      // this.$emit("commit");
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      selectedRequest: "requests/selectedRequest",
      createRequestCar: "cars/createRequestCar",
    }),
  },
  mounted() {
    console.log("mounted registration Scanner")
  },
}
</script>
