import axios from "axios"

const state = {
  selectedCar: null,
  createRequestCar: null,
  customerCars: [],
  editCarVisible: false,
  VIN_WHITELIST: "1234567890ABCDEFGHJKLMNPRSTUVWXYZ",
}

// getters
const getters = {
  customerCars: (state) => state.customerCars,
  selectedCar: (state) => state.selectedCar,
  createRequestCar: (state) => state.createRequestCar,
  VIN_WHITELIST: (state) => state.VIN_WHITELIST,
  editCarVisible: (state) => state.editCarVisible,
}

// actions
const actions = {
  async getByCustomer(context, payload) {
    console.log("get car using customer")

    let response
    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "cars/customer/" + payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.log("cars/getByCustomer network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_CUSTOMER_CARS", response.data)
      return response
    } else {
      console.error("cars/getByCustomer error", response)
      throw new Error("Fehler beim Laden der Fahrzeugdaten")
    }
  },
  async create(context, payload) {
    console.log("create car", payload)

    let response
    try {
      response = await axios.put(
        context.rootGetters.API.baseUrl + "cars/",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.log("cars/create network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }
    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("cars/create error", response)
      throw new Error("Fehler beim Erstellen eines Fahrzeugdatensatzes")
    }
  },
  async update(context, payload) {
    console.log("update car", payload)

    let response
    try {
      response = await axios.patch(
        context.rootGetters.API.baseUrl + "cars/" + payload._id,
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("cars/update network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("cars/update error", response)
      throw new Error("Ein Fahrzeugdatensatz konnte nicht aktualisiert werden")
    }
  },
  async uploadCameraPhoto(context, payload) {
    console.log("uploadCameraPhoto")
    let response

    try {
      response = await axios.post(
        context.rootGetters.API.baseUrl + "cars/upload/camera",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("cars/uploadCameraPhoto network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("cars/uploadCameraPhoto error", response)
      throw new Error("Fehler beim Senden eines Fotos an den FIASCO-Server")
    }
  },
}

// mutations
const mutations = {
  SET_CUSTOMER_CARS(state, data) {
    state.customerCars = data
  },
  SET_SELECTED_CAR(state, data) {
    state.selectedCar = data
  },
  SET_CREATE_REQUEST_CAR(state, data) {
    state.createRequestCar = data
  },
  SET_EDIT_CAR_VISIBLE(state, data) {
    state.editCarVisible = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
