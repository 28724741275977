<template>
  <div class="intro screen">
    <div class="intro-inner">
      <div class="intro-nav">
        <template v-if="status == 'logging_in'">
          <p class="center">Daten laden...</p>
        </template>
        <template v-if="status == 'login_error'">
          <p class="center">
            Versuchen Sie es erneut mit dem Link, der Ihnen per E-Mail zugesandt wurde, oder
            versuchen Sie, sich erneut zu registrieren.
          </p>
        </template>
      </div>
    </div>
    <div class="intro-legals">
      <div class="intro-legals-item" @click="openCustomerInfo()">Kundeninformationen</div>
      <div class="intro-legals-item" @click="openImprint()">Impressum</div>
      <div class="intro-legals-item" @click="openPrivacy()">Datenschutz</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CustomerActionVerify",
  data() {
    return {
      status: "",
    }
  },
  methods: {
    goMain() {
      this.$router.push({ name: "main" })
    },

    openImprint() {
      this.$store.commit("SET_IMPRINT_VISIBLE", true)
    },

    openPrivacy() {
      this.$store.commit("SET_PRIVACY_VISIBLE", true)
    },

    openCustomerInfo() {
      this.$store.commit("SET_CUSTOMER_INFO_VISIBLE", true)
    },

    async verifyCustomer() {
      this.$store.commit("customers/SET_LOGIN_ERROR", null)
      this.$store.commit("SET_BUSY", true)

      const token = this.$route.params?.token
      let response
      try {
        response = await this.$store.dispatch("customers/authWithToken", { token })

        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        console.error("requestMagicLink error:", err)
        this.$store.commit("SET_BUSY", false)
        this.status = "login_error"

        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }

      this.$store.commit("SET_BUSY", false)

      if (!response) {
        this.$store.commit("customers/SET_LOGIN_ERROR", "Could not authenticate against the server")
        this.status = "login_error"
        return
      }

      this.status = "logged_in"

      this.$router.push({ name: "customerActionIntro" })
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
    }),
  },
  mounted() {
    console.log("CustomerActionVerify MOUNTED")

    this.verifyCustomer()
  },
}
</script>
