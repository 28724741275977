<template>
  <div class="intro screen">
    <div class="intro-nav">
      <template>
        <div class="flex justify-center">
          <div class="block rounded-lg shadow-lg bg-gray max-w-sm text-center">
            <div class="py-3 px-6 border-b text-white">Herzlich Willkommen!</div>
            <div class="p-6">
              <p class="text-white text-base mb-4">
                Danke, dass Sie sich für uns entschieden haben!
              </p>
              <button
                type="button"
                class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                @click="goToRequest()"
              >
                stellen Sie Ihren ersten Antrag
              </button>
            </div>
            <!-- <div class="py-3 px-6 border-t border-gray-300 text-gray-600">
                2 days ago
              </div> -->
          </div>
        </div>
      </template>
    </div>

    <div class="intro-legals">
      <div class="intro-legals-item" @click="openCustomerInfo()">Kundeninformationen</div>
      <div class="intro-legals-item" @click="openImprint()">Impressum</div>
      <div class="intro-legals-item" @click="openPrivacy()">Datenschutz</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CustomerActionIntro",
  data() {
    return {}
  },
  methods: {
    goMain() {
      this.$router.push({ name: "main" })
    },

    openImprint() {
      this.$store.commit("SET_IMPRINT_VISIBLE", true)
    },

    openPrivacy() {
      this.$store.commit("SET_PRIVACY_VISIBLE", true)
    },

    openCustomerInfo() {
      this.$store.commit("SET_CUSTOMER_INFO_VISIBLE", true)
    },
    goToRequest() {
      this.$router.push({ name: "CreateRequestPhotos" })
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
    }),
  },
  mounted() {},
}
</script>
