import moment from "moment"

export default {
  methods: {
    formatDate(date) {
      moment.locale("de")
      return moment(date).format("ll")
    },
    formatDateTime(date) {
      moment.locale("de")
      return moment(date).format("lll")
    },
  },
}
