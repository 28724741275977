<template>
  <div
    class="absolute top-0 left-0 z-30 w-full h-full overflow-y-auto bg-transparent-black text-gray-dark"
    v-if="selectedOffer"
  >
    <div class="absolute z-0 top-0 left-0 w-full h-full" @click="goBack()"></div>
    <div class="bg-slate-200 p-2 m-4 rounded-lg relative">
      <img
        class="w-8 absolute z-10 top-0 right-0 opacity-50"
        src="@/assets/gfx/icon_close_black.png"
        @click="goBack()"
      />
      <h3 class="text-xl text-center">Termin vereinbaren mit:<br />{{ customerStation.name }}</h3>
      <div class="flex my-4" v-if="selectedOffer.contactPerson">
        <s3-image
          class="w-14 h-14 rounded-full mr-2 object-cover"
          :image="selectedOffer.contactPerson.s3Image"
          v-if="selectedOffer.contactPerson.s3Image"
        />
        <div class="details-item-caption">
          <div class="details-item-caption-label">Ansprechpartner:</div>
          <div class="details-item-caption-value">
            {{ selectedOffer.contactPerson.firstName }}
            {{ selectedOffer.contactPerson.lastName }}
          </div>
        </div>
      </div>

      <div class="text-lg my-1">
        <div v-if="dateSuggestion.type == 'REPAIR'">Reparatur am</div>
        <div v-if="dateSuggestion.type == 'INSPECTION'">Besichtigung am</div>
      </div>
      <div class="text-lg font-bold">
        {{ formatDateTime(dateSuggestion.date) }}
      </div>

      <div class="my-1" v-if="mode == 'SUGGEST'">
        <div v-if="dateSuggestion.delivery == 'STATION'">Hol- und Bringservice</div>
        <div v-if="dateSuggestion.delivery == 'CUSTOMER'">Ich bringe das Fahrzeug</div>
      </div>

      <div class="my-1" v-if="mode == 'ACCEPT'">
        <div class="mt-4">Wie kommt das Fahrzeug zur Werkstatt?</div>
        <div class="flex items-center my-1" @click="editDelivery = 'CUSTOMER'">
          <input
            class="form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-fiasco-blue checked:border-fiasco-blue-dark focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer flex-shrink-0"
            type="radio"
            name="editDelivery"
            id="editDelivery"
            v-model="editDelivery"
            value="CUSTOMER"
          />
          <span :class="{ 'opacity-50': editDelivery != 'CUSTOMER' }">
            Ich bringe das Fahrzeug
          </span>
        </div>
        <div class="flex items-center my-1" @click="editDelivery = 'STATION'">
          <input
            class="form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-fiasco-blue checked:border-fiasco-blue-dark focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer flex-shrink-0"
            type="radio"
            name="editDelivery"
            id="editDelivery"
            v-model="editDelivery"
            value="STATION"
          />
          <span :class="{ 'opacity-50': editDelivery != 'STATION' }">
            Ich benötige Hol- und Bringservice</span
          >
        </div>
      </div>

      <div class="offer-contact-form">
        <div class="my-2">
          <label class="block text-sm pl-4 opacity-50">Mein Vorname:</label>
          <input
            class="bg-white rounded-full h-12 w-full px-2"
            type="text"
            v-model="form.firstName"
          />
        </div>
        <div class="mb-2">
          <label class="block text-sm pl-4 opacity-50">Mein Nachname:</label>
          <input
            class="bg-white rounded-full h-12 w-full px-2"
            type="text"
            v-model="form.lastName"
          />
        </div>
        <!--div class="offer-contact-form-item" v-if="contactMode.phone || contactMode.whatsapp || contactMode.sms || contactMode.telegram">
          <label>Meine Telefonnummer:</label>
          <input type="text"/>
        </div-->

        <div class="offer-contact-form-openers">
          <div class="flex items-center my-4" @click="legalsAccepted = !legalsAccepted">
            <input
              class="form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-fiasco-blue checked:border-fiasco-blue-dark focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer flex-shrink-0"
              type="radio"
              name="legalsAccepted"
              id="legalsAccepted"
              v-model="legalsAccepted"
              value="true"
            />

            <span class="text-sm" :class="{ 'opacity-50': !legalsAccepted }">
              Ich bin einverstanden, dass meine Kontaktdaten an die angegebene Werkstatt übermittelt
              werden.
            </span>
          </div>
        </div>
        <!---------DOCUMENT -------->
        <div class="offer-contact-form-openers">
          <DocumentsList />
        </div>
      </div>

      <button
        class="w-full h-14 bg-fiasco-purple text-white rounded-full disabled:opacity-50"
        @click="sendDate()"
        :disabled="!sendAvailable"
      >
        <span v-if="mode == 'ACCEPT'"> Termin bestätigen </span>
        <span v-if="mode == 'SUGGEST'"> Senden </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import dateDisplayMixin from "../mixins/dateDisplay"
import DocumentsList from "./DocumentsList"
export default {
  name: "OfferContact",
  components: { DocumentsList },
  data() {
    return {
      photoIndex: 0,
      opener: "SOONEST",
      legalsAccepted: false,
      offer: null,
      editDelivery: "CUSTOMER",
      form: {
        firstName: "",
        lastName: "",
        email: "",
      },
      customerMessage: "",
    }
  },
  props: {
    mode: String,
    dateSuggestion: Object,
  },
  mixins: [dateDisplayMixin],
  methods: {
    goBack() {
      this.$emit("close")
    },
    async sendDate() {
      if (this.mode === "SUGGEST") {
        console.log(this.selectedOffer)
        const editOffer = JSON.parse(JSON.stringify(this.selectedOffer))
        const editDateSuggestion = JSON.parse(JSON.stringify(this.dateSuggestion))
        editOffer.dateSuggestions.forEach((suggestion) => {
          if (suggestion.createdBy === "STATION") {
            suggestion.customerApproval = "DECLINED"
          }
        })
        editDateSuggestion.createdBy = "CUSTOMER"
        editOffer.dateSuggestions.push(editDateSuggestion)
        const payload = {
          offer: editOffer,
          action: "counter-date",
        }
        this.$store.commit("SET_BUSY", true)
        try {
          await this.$store.dispatch("offers/update", payload)
          await this.$store.dispatch("offers/getById", this.selectedRequest.offer)
          this.goBack()
          this.$store.commit("SET_BUSY", false)
          this.$store.commit("error/SET_ERROR", false)
        } catch (err) {
          this.$store.commit("SET_BUSY", false)
          this.$store.commit("error/SET_ERROR", true)
          this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
          this.$store.commit("error/SET_ERROR_CODE", err.status)
          throw new Error("Failed to send data", err)
        }
      }
      if (this.mode === "ACCEPT") {
        const editOffer = JSON.parse(JSON.stringify(this.selectedOffer))
        editOffer.dateSuggestions.forEach((suggestion) => {
          if (suggestion._id === this.dateSuggestion._id) {
            suggestion.customerApproval = "ACCEPTED"
            suggestion.delivery = this.editDelivery
          }
        })
        this.$store.commit("SET_BUSY", true)
        try {
          await this.$store.dispatch("offers/update", editOffer)
          await this.$store.dispatch("offers/getById", this.selectedRequest.offer)
          this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
          this.editRequest.status =
            this.dateSuggestion.type === "REPAIR" ? "REPAIR_AGREED" : "INSPECTION_AGREED"
          const statusHistory = {
            status: this.editRequest.status,
            customer: this.customer._id,
            changedAt: new Date(),
          }
          this.editRequest.statusHistory.unshift(statusHistory)
          await this.$store.dispatch("requests/update", this.editRequest)
          await this.$store.dispatch("requests/getByCustomer", this.customer._id)
          this.$store.commit("SET_BUSY", false)
          this.goBack()
          this.$store.commit("SET_BUSY", false)
          this.$store.commit("error/SET_ERROR", false)
        } catch (err) {
          this.$store.commit("SET_BUSY", false)
          this.$store.commit("error/SET_ERROR", true)
          this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
          this.$store.commit("error/SET_ERROR_CODE", err.status)
          throw new Error("Failed to send data", err)
        }
      }
    },
    async sendMessage() {
      const payload = {
        message: this.customerMessage,
        requestId: this.selectedRequest._id,
        fiascoId: this.selectedRequest.fiascoId,
      }
      this.$store.commit("SET_BUSY", true)
      try {
        await this.$store.dispatch("requests/sendMessage", payload)
        await this.$store.dispatch("requests/getByCustomer", this.customer._id)

        const statusPayload = {
          _id: this.selectedRequest._id,
          status: this.mode === "INSPECT" ? "INSPECTION_REQUESTED" : "REPAIR_REQUESTED",
          statusHistory: JSON.parse(JSON.stringify(this.selectedRequest.statusHistory)),
        }
        const statusHistory = {
          status: statusPayload.status,
          customer: this.customer._id,
          changedAt: new Date(),
        }
        statusPayload.statusHistory.unshift(statusHistory)
        await this.$store.dispatch("requests/update", statusPayload)
        await this.$store.dispatch("requests/getByCustomer", this.customer._id)
        this.goBack()
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to send message", err)
      }
    },
  },
  /*
  this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
      this.editRequest.status = status
      let statusHistory = {
        status: this.editRequest.status,
        user: this.userId,
        changedAt: new Date()
      }
      this.editRequest.statusHistory.unshift(statusHistory)
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('requests/update', this.editRequest).then(() => {
        this.$store.dispatch('requests/getActive').then(() => {
          this.$store.commit('SET_BUSY', false)
        })
      })
  */
  watch: {
    opener() {
      this.customerMessage = this.messageTemplate
    },
    soonestDate() {
      this.customerMessage = this.messageTemplate
    },
  },
  computed: {
    messageTemplate() {
      const goal = this.mode === "INSPECT" ? "Besichtigung" : "Reparatur"
      let messageString
      if (this.opener === "SOONEST") {
        messageString =
          "Danke für das Angebot! Ich hätte gerne einen Terminvorschlag so bald wie möglich für eine " +
          goal +
          "."
      }
      if (this.opener === "DATE") {
        messageString =
          "Danke für das Angebot! Ich hätte gerne einen Terminvorschlag für eine " +
          goal +
          " ab dem " +
          this.formatDate(this.soonestDate) +
          "."
      }
      return messageString
    },
    sendAvailable() {
      // let available = (this.contactMode.phone || this.contactMode.email || this.contactMode.sms || this.contactMode.whatsapp || this.contactMode.telegram) && this.legalsAccepted
      const available = this.legalsAccepted
      return available
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestStatusName: "requests/requestStatusName",
      selectedOffer: "offers/selectedOffer",
      offerMaxPrice: "offers/maxPrice",
      customerStation: "stations/customerStation",
    }),
  },
  mounted() {
    /*
    this.offer = this.activeOffers.find(o => o._id === this.$route.params.id)
    */
    if (this.customer.firstName) {
      this.form.firstName = this.customer.firstName
    }
    if (this.customer.lastName) {
      this.form.lastName = this.customer.lastName
    }
    if (this.customer.firstName) {
      this.form.email = this.customer.email
    }
    this.customerMessage = this.messageTemplate
  },
}
</script>
