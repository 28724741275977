<template>
  <div class="create-request-owner create-request-content">
    <div v-if="customer._id">
      <h2>Gleich fertig!</h2>
      <p>Ist die Anfrage für dich oder eine andere Person?</p>
    </div>

    <div class="popup-form-owner-tabs">
      <div
        class="popup-form-owner-tab"
        :class="{ active: !deviatingOwner }"
        @click="deviatingOwner = false"
      >
        <div class="popup-form-owner-tab-marker" />
        <span>Ich bin Fahrzeughalter</span>
      </div>
      <div
        class="popup-form-owner-tab"
        :class="{ active: deviatingOwner }"
        @click="deviatingOwner = true"
      >
        <div class="popup-form-owner-tab-marker" />
        <span>Abweichender Fahrzeughalter</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateRequestOwner",
  data() {
    return {
      editRequest: null,
      deviatingOwner: false,
    }
  },
  methods: {
    selectDummyCar(carType) {
      this.$store.commit("SET_DAMAGE_DUMMY_CAR", carType)
      this.$router.push({ name: "CreateRequestDamage" })
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      damageTypes: "requests/damageTypes",
      damageLocations: "requests/damageLocations",
      damageLocationName: "requests/damageLocationName",
      damageTypeName: "requests/damageTypeName",
      selectedRequest: "requests/selectedRequest",
      damageDummyCar: "damageDummyCar",
    }),
  },
  watch: {
    carSelected() {
      console.log("WATCH carSelected")
      console.log(this.carSelected)
      /*
      if (!this.carSelected) {
        if (this.scene) {
          this.scene.dispose();
        }
      }
      */
    },
  },
  mounted() {
    this.getEditData()
  },
}
</script>
