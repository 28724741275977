import axios from "axios"

const state = {
  selectedRequest: null,
  requestPending: false,
  cameraPhotos: [],
  documentPhotos: [],
  voiceMessage: null,
  lastUpdate: null,
  requests: [],
  inbox: [],
  offered: [],
  requestStates: [],
  damageLocations: [
    {
      location: "FENDER_F_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Kotflügel vorne rechts",
    },
    {
      location: "FENDER_F_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Kotflügel vorne links",
    },
    {
      location: "FENDER_B_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Kotflügel hinten rechts",
    },
    {
      location: "FENDER_B_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Kotflügel hinten links",
    },
    {
      location: "DOOR_F_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Tür vorne rechts",
    },
    {
      location: "DOOR_F_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Tür vorne links",
    },
    {
      location: "DOOR_B_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Tür hinten rechts",
    },
    {
      location: "DOOR_B_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Tür hinten links",
    },
    {
      location: "SIDE_B_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Seitenwand hinten rechts",
    },
    {
      location: "SIDE_B_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Seitenwand hinten links",
    },
    {
      location: "ENGINE_HOOD",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Motorhaube",
    },
    {
      location: "TAILGATE",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Heckklappe",
    },
    {
      location: "BUMPER_F",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Stoßstange vorne",
    },
    {
      location: "BUMPER_B",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Stoßstange hinten",
    },
    {
      location: "A_PILLAR_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "A-Säule rechts",
    },
    {
      location: "A_PILLAR_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "A-Säule links",
    },
    {
      location: "SILL_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Einstieg links",
    },
    {
      location: "SILL_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Einstieg rechts",
    },
    {
      location: "ROOF",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Dach",
    },
    {
      location: "Rim_F_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Felge vorne rechts",
    },
    {
      location: "RIM_F_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Felge vorne links",
    },
    {
      location: "RIM_B_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Felge hinten rechts",
    },
    {
      location: "RIM_B_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Felge hinten links",
    },
    {
      location: "FUEL_FILLER_CAP",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Tankklappe",
    },
    {
      location: "REAR_MIRROR_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Spiegel links",
    },
    {
      location: "REAR_MIRROR_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Spiegel rechts",
    },
    {
      location: "WINDSCHIELD",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Windschutzscheibe",
    },
    {
      location: "REAR_WINDOW",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Heckscheibe",
    },
    {
      location: "HEADLIGHT_R",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Scheinwerfer rechts",
    },
    {
      location: "HEADLIGHT_L",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Scheinwerfer links",
    },
    {
      location: "TAILLIGHT_R",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Rücklicht rechts",
    },
    {
      location: "TAILLIGHT_L",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Rücklicht links",
    },
    {
      location: "ROOF_WINDOW",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Dachfenster",
    },
    {
      location: "TRUNK",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Heckklappe",
    },
  ],
  damageTypes: [
    {
      type: "PAINT",
      displayName: "Lackschaden",
    },
    {
      type: "DENT",
      displayName: "Delle",
    },
    {
      type: "PAINT_DENT",
      displayName: "Lackschaden mit Delle",
    },
    {
      type: "CRACK",
      displayName: "Bruch oder Riss",
    },
  ],
}

// getters
const getters = {
  requests: (state) => state.requests,
  inbox: (state) => state.inbox,
  offered: (state) => state.offered,
  requestPending: (state) => state.requestPending,
  lastUpdate: (state) => state.lastUpdate,
  activeRequests: (state) => state.activeRequests,
  openRequests: (state) =>
    state.requests.filter(
      (o) => o.status !== "FIXED" && (o.status !== "INVOICE_SENT") & (o.status !== "INVOICE_PAID")
    ),
  fixedAndReadyRequests: (state) => state.requests.filter((o) => o.status === "FIXED"),
  fixedRequests: (state) =>
    state.requests.filter((o) => o.status === "INVOICE_SENT" || o.status === "INVOICE_PAID"),
  selectedRequest: (state) => state.selectedRequest,
  requestStates: (state) => state.requestStates,
  damageLocations: (state) => state.damageLocations,
  cameraPhotos: (state) => state.cameraPhotos,
  documentPhotos: (state) => state.documentPhotos,
  uploadPhotos: (state) => {
    return state.cameraPhotos.concat(state.documentPhotos)
  },
  voiceMessage: (state) => state.voiceMessage,
  requestsByStatus: (state) => (status) => {
    return state.requests.filter((o) => o.status === status && o.type === "DIRECT")
  },
  requestStatusName: (state) => (status) =>
    state.requestStates.find((o) => o.status === status)?.displayName,
  requestStatusActionName: (state) => (status) =>
    state.requestStates.find((o) => o.status === status).actionName,
  damageTypes: (state) => state.damageTypes,
  damageTypeName: (state) => (damageType) =>
    damageType ? state.damageTypes.find((o) => o.type === damageType).displayName : "",
  damageLocationName: (state) => (damageLocation) =>
    damageLocation
      ? state.damageLocations.find((o) => o.location === damageLocation).displayName
      : "",
  hasCalculation: () => (request) => {
    return (
      request.status === "QUALIFIED" ||
      request.status === "FIASCO_CALCULATION_REQUESTED" ||
      request.status === "FIASCO_CALCULATION_FEEDBACK" ||
      request.status === "FIASCO_CALCULATING" ||
      request.status === "CALCULATION_DONE" ||
      request.status === "FIASCO_CALCULATION_SENT"
    )
  },
  nextSteps: () => (status) => {
    switch (status) {
      case "REQUESTED":
        return ["FEEDBACK", "QUALIFIED"]
      case "FEEDBACK":
        return ["REQUESTED", "QUALIFIED"]
      case "QUALIFIED":
        return [
          "FIASCO_CALCULATION_REQUESTED",
          "FIASCO_CALCULATION_FEEDBACK",
          "FIASCO_CALCULATION_QUALIFIED",
          "CALCULATION_DONE",
        ]
      case "FIASCO_CALCULATION_REQUESTED":
        return [
          "QUALIFIED",
          "FIASCO_CALCULATING",
          "FIASCO_CALCULATION_QUALIFIED",
          "FIASCO_CALCULATION_SENT",
        ]
      case "FIASCO_CALCULATION_FEEDBACK":
        return [
          "QUALIFIED",
          "FIASCO_CALCULATING",
          "FIASCO_CALCULATION_QUALIFIED",
          "FIASCO_CALCULATION_SENT",
        ]
      case "FIASCO_CALCULATING":
        return ["FIASCO_CALCULATION_FEEDBACK", "FIASCO_CALCULATION_SENT"]
      case "FIASCO_CALCULATION_QUALIFIED":
        return ["FIASCO_CALCULATION_FEEDBACK", "FIASCO_CALCULATING"]
      case "FIASCO_CALCULATION_SENT":
        return ["CALCULATION_DONE", "OFFERED"]
      case "CALCULATION_DONE":
        return ["OFFERED"]
      case "OFFERED":
        return ["ACCEPTED", "DECLINED"]
      case "ACCEPTED":
        return ["INSPECTION_REQUESTED", "INSPECTION_AGREED", "REPAIR_REQUESTED", "REPAIR_AGREED"]
      case "INSPECTION_REQUESTED":
        return ["INSPECTION_REQUESTED", "INSPECTION_AGREED", "INSPECTED"]
      case "INSPECTION_AGREED":
        return ["INSPECTION_AGREED", "INSPECTED"]
      case "INSPECTED":
        return ["REPAIR_REQUESTED", "REPAIR_AGREED"]
      case "REPAIR_REQUESTED":
        return ["REPAIR_AGREED", "IN_REPAIR"]
      case "REPAIR_AGREED":
        return ["IN_REPAIR"]
      case "IN_REPAIR":
        return ["FIXED"]
      case "FIXED":
        return ["INVOICE_SENT", "INVOICE_PAID"]
      case "INVOICE_SENT":
        return ["INVOICE_PAID"]
    }
  },
  recentMessage: (state) => {
    const filteredMessages = state.selectedRequest.messages.filter((o) => o.type === "PUBLIC")
    return filteredMessages[filteredMessages.length - 1]
  },
}

// actions
const actions = {
  async getByCustomer(context, payload) {
    let response

    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "requests/customer/" + payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("requests/getByCustomer network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_ALL", response.data)
      return response
    } else {
      console.error("requests/getByCustomer error", response)
      throw new Error("Anfragen konnten nicht geladen werden")
    }
  },
  async getByUser(context, payload) {
    if (!payload.params) {
      payload.params = {}
    }
    const params = new URLSearchParams()
    for (const key in payload.params) {
      params.append(key, payload.params[key])
    }

    let response
    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "requests/user/" + payload.id + "?" + params,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("requests/getByUser network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      console.log("getbyuser", payload)
      if (payload.params.folder === "INBOX") {
        context.commit("SET_INBOX", response.data)
      }
      if (payload.params.folder === "OFFERS") {
        context.commit("SET_OFFERED", response.data)
      }
      return response
    } else {
      console.error("requests/getByUser error", response)
      throw new Error("Anfragen konnten nicht geladen werden")
    }
  },
  async getById(context, id) {
    let response

    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "requests/" + id,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("requests/getById network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_SELECTED_REQUEST", response.data)
      return response
    } else {
      console.error("requests/getById error", response)
      if (response.status == 404) {
        throw new Error("Die Anfrage wurde nicht gefunden")
      } else {
        throw new Error("Die Anfrage konnte nicht geladen werden")
      }
    }
  },
  async create(context, payload) {
    console.log("create request")

    let response
    try {
      response = await axios.put(
        context.rootGetters.API.baseUrl + "requests",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.log(err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error(response)
      throw new Error("error creating request")
    }
  },
  async update(context, payload) {
    console.log("update request")
    delete payload.messages

    let response
    try {
      response = await axios.patch(
        context.rootGetters.API.baseUrl + "requests/" + payload._id,
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("requests/update network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("requests/update error", response)
      throw new Error("Die Anfragedaten konnte nicht gespeichert werden")
    }
  },
  async getRequestStates(context) {
    let response
    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "requests/request-states",
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("requests/getRequestStates network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_REQUEST_STATES", response.data)
      return response
    } else {
      console.error("requests/getRequestStates error", response)
      throw new Error("Unerwartete Antwort vom FIASCO-Server")
    }
  },
  async sendMessage(context, payload) {
    console.log("sendMessage request", payload)

    let response
    try {
      response = await axios.put(
        context.rootGetters.API.baseUrl + "requests/" + payload.requestId + "/message",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("requests/sendMessage network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("requests/sendMessage error", response)
      throw new Error("Die Nachricht konnte nicht gesendet werden")
    }
  },
  async deletePhoto(context, payload) {
    console.log("deletePhoto")

    let response
    try {
      response = await axios.patch(
        context.rootGetters.API.baseUrl + "requests/photo/delete",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("requests/deletePhoto network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      console.log(response)
      console.log("DELETED")
    } else {
      console.error("requests/deletePhoto error", response)
      throw new Error("Das Foto konnte nicht gelöscht werden")
    }
  },
  async uploadCameraPhoto(context, payload) {
    console.log("uploadCameraPhoto")

    let response
    try {
      response = await axios.post(
        context.rootGetters.API.baseUrl + "requests/upload/camera",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("requests/uploadCameraPhoto network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("requests/uploadCameraPhoto error", response)
      throw new Error("Das Foto konnte nicht hochgeladen werden")
    }
  },
  async uploadVoiceMessage(context, payload) {
    console.log("uploadVoiceMessage")

    let response
    try {
      response = await axios.post(
        context.rootGetters.API.baseUrl + "requests/upload/audio",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("requests/uploadVoiceMessage network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("requests/uploadVoiceMessage error", response)
      throw new Error("Fehler beim Speichern der Sprachnachricht auf dem Server")
    }
  },
  async getLastUpdate(context, payload) {
    let response
    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "requests/last-update/customer/" + payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("requests/getLastUpdate network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      if (response.data && response.data.count > 0) {
        context.commit("SET_LAST_UPDATE", response.data[0].updatedAt)
      }
    } else {
      console.error("requests/getLastUpdate error", response)
      throw new Error("Anfragedaten konnten nicht vom Server geladen werden")
    }
  },

  async sendToCloud(context, payload) {
    console.log("sendToCloud:", payload)

    // convert photos to base64
    console.log("download photos")
    const photos = await Promise.all(
      payload.request.s3Images.map(async (image) => {
        try {
          const base64Data = await context.dispatch("getS3ImageData", image, { root: true })
          console.log("base64Data", base64Data)
          // const base64Data = res Buffer.from(res, 'binary').toString('base64');
          console.log(`Base64 length for image ${image}: ${base64Data.length}`);
          return {
            data: base64Data,
          };
        } catch (error) {
          console.error(`Error fetching image ${image}:`, error);
          return {
            data: ''
          };
        }
      })
    );
    console.log("photo count:", photos.length)

    let damageRequestData = {
      referenceId: `FI-INT:${payload.request._id}:${payload.request.fiascoId}`,
      vin: payload.request.car.vin,
      mileage: payload.request.car.kilometers,
      firstRegistrationDate: payload.request.car.firstRegistration,
      licensePlate: payload.request.car.numberPlate,
      damagesToConsider: "",
      damagesToExclude: "",
      paintSystem: payload.baseCalculation.paintSystem,
      replacementPartsSurcharge: payload.baseCalculation.replacementPartsSurcharge / 100,
      paintMaterialPercentage: payload.baseCalculation.additionalCosts.paintMaterialPercentage / 100,
      paintMaterialVw: 0,
      smallPartsPercentage: payload.baseCalculation.additionalCosts.smallPartsPercentage / 100,
      laborRateMechanics: payload.baseCalculation.hourlyWageMechanics,
      laborRateElectronics: payload.baseCalculation.hourlyWageElectronics,
      laborRateBody: payload.baseCalculation.hourlyWageBody,
      laborRateHailDamage: payload.baseCalculation.hourlyWageHailDamage,
      laborRatePaint: payload.baseCalculation.hourlyWagePaint,
      // TODO: add real recipient
      calculationAudaNetRecipient: "CommId:DE00000",
      calculationEmailRecipient: "calcrequest@dev.fiasco.de",
      photos: photos,
    }
    console.log("damageRequestData", damageRequestData)
    return axios
      .post(
        context.rootGetters.API.baseURL + "requests/cloud",
        damageRequestData,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        if (!response.data.success) {
          console.log("sendToCloud ERROR (1)", {
            payload: damageRequestData,
            config: context.rootGetters.axiosConfig,
          })
        }
        return response
      })
      .catch((error) =>
        console.log("sendToCloud ERROR (2)", {
          payload: damageRequestData,
          config: context.rootGetters.axiosConfig,
          error: error,
        })
      )
  },
}

// mutations
const mutations = {
  SET_ALL(state, data) {
    state.requests = data
  },
  SET_INBOX(state, data) {
    state.inbox = data
  },
  SET_OFFERED(state, data) {
    console.log("SET_OFFERED")
    console.log(data)
    state.offered = data
  },
  SET_SELECTED_REQUEST(state, data) {
    state.selectedRequest = data
  },
  SET_REQUEST_STATES(state, data) {
    state.requestStates = data
  },
  SET_CAMERA_PHOTOS(state, data) {
    console.log("SET_CAMERA_PHOTOS")
    state.cameraPhotos = data
  },
  ADD_CAMERA_PHOTO(state, data) {
    console.log("ADD_CAMERA_PHOTO")
    state.cameraPhotos.push(data)
  },
  REMOVE_CAMERA_PHOTO(state, index) {
    console.log("REMOVE_CAMERA_PHOTO")
    state.cameraPhotos.splice(index, 1)
  },
  SET_DOCUMENT_PHOTOS(state, data) {
    state.documentPhotos = data
  },
  SET_VOICE_MESSAGE(state, data) {
    console.log("SET_VOICE_MESSAGE")
    state.voiceMessage = data
  },
  SET_REQUEST_PENDING(state, data) {
    state.requestPending = data
  },
  SET_LAST_UPDATE(state, data) {
    state.lastUpdate = data
  },
  /*
  UPDATE_REQUEST (state, data) {
    state.requests = state.requests.filter(o => o.fiascoId !== data.fiascoId)
    state.requests.push(data)
    console.log('UPDATE_REQUEST')
    console.log(state.requests)
  }
  */
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
