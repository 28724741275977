<template>
  <div class="h-full pt-16 text-white">
    <div class="p-4">
      <h2 class="text-xl text-center">Mein Fahrzeug:</h2>
      <form class="form" @submit.prevent="confirm()">
        <div class="mb-2">
          <label class="pl-4 opacity-80" :class="{ 'opacity-100': editCar.vin.length === 17 }">
            Fahrzeug-Identifikationsnummer:*
            <div
              class="ml-2 mb-2 text-sm px-2 inline-block rounded-lg"
              :class="{ 'bg-green': editCar.vin.length === 17 }"
            >
              {{ editCar.vin.length }} / 17 Stellen
            </div>
          </label>
          <input
            type="text"
            class="p-2 rounded-full text-gray-dark w-full block bg-white h-12 text-lg"
            placeholder="z.B.: JH4DA9460MS032070"
            v-model="editCar.vin"
          />
        </div>
        <!--div class="form-line">
          <label>Kilometerstand*</label>
          <input type="number" v-model="editCar.kilometers"/>
        </div-->
        <p class="text-sm">* benötigt</p>
        <div class="mb-2 mt-4">
          <label class="pl-4 text-sm opacity-80">Kennzeichen</label>
          <input
            type="text"
            class="p-2 rounded-full text-gray-dark w-full block bg-white"
            v-model="editCar.numberPlate"
            placeholder="AS-CO 123"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm opacity-80">Erstzulassung</label>
          <input
            type="date"
            class="p-2 rounded-full text-gray-dark w-full block bg-white"
            v-model="editCar.firstRegistration"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm opacity-80">Hersteller</label>
          <input
            class="p-2 rounded-full text-gray-dark w-full block bg-white"
            type="text"
            v-model="editCar.manufacturer"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm opacity-80">Modell</label>
          <input
            type="text"
            class="p-2 rounded-full text-gray-dark w-full block bg-white mb-4"
            v-model="editCar.model"
          />
        </div>
        <br />
        <br />
        <br />

        <button
          class="absolute bottom-4 border-2 border-white left-4 w-14 h-14 p-1 rounded-full bg-transparent"
          @click="goBack()"
        >
          <img src="@/assets/gfx/arrow-prev.png" />
        </button>

        <button
          class="absolute bottom-4 right-4 w-14 h-14 p-1 rounded-full bg-fiasco-purple disabled:opacity-50"
          @click="confirm()"
          :disabled="editCar.vin.length !== 17"
        >
          <img src="@/assets/gfx/arrow-next.png" />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateRequestEnterCar",
  data() {
    return {
      code: "Code",
      editCar: {
        vin: "",
        model: "",
        manufacturer: "",
        firstRegistration: "",
        numberPlate: "",
        kilometers: 0,
        owner: {},
      },
      isDecoded: false,
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestCar" })
      // this.$emit("close");
    },
    confirm() {
      if (this.customer) {
        this.editCar.customer = this.customer._id
      }
      console.log("confirm editCar:")
      console.log(this.editCar)
      this.$store.commit("cars/SET_CREATE_REQUEST_CAR", this.editCar)
      console.log(this.createRequestCar)
      // this.$emit("commit");
      this.$router.push({ name: "CreateRequestDamageIntro" })
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      createRequestCar: "cars/createRequestCar",
    }),
  },
}
</script>
