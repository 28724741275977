const state = {
  error: false,
  errorMessage: "",
  errorCode: null,
  toastError: false,
}

// getters
const getters = {
  error: (state) => state.error,
  toastError: (state) => state.toastError,
  errorMessage: (state) => state.errorMessage,
  errorCode: (state) => state.errorCode,
}

// mutations
const mutations = {
  SET_ERROR(state, data) {
    state.error = data
  },
  SET_ERROR_MESSAGE(state, data) {
    state.errorMessage = data
  },
  SET_ERROR_CODE(state, data) {
    state.errorCode = data
  },
  SET_TOAST_ERROR(state, data) {
    state.toastError = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
