<template>
  <div class="relative">
    <div v-if="error" class="h-screen bg-orange flex justify-center items-center">
      <div class="bg-white py-8 px-4 rounded-lg flex flex-col justify-between items-center z-50">
        <h2 class="text-xl text-center">Achtung!</h2>

        <p class="py-2" v-if="errorMessage">{{ errorMessage }}</p>
        <p class="py-2" v-if="!errorMessage">
          Etwas ist schiefgegangen. Bitte versuche es später erneut.
        </p>

        <button class="rounded-lg w-full bg-fiasco-blue text-white px-4 py-2" @click="removeError">
          Schließen
        </button>
        <!---- <button class="rounded-lg w-full bg-orange text-white px-4 py-2 " @click="removeError">
   Erneut versuchen
 </button>
 <p class="py-2">{{ errorMessage }}</p>-->
      </div>
    </div>
    <div
      v-if="toastError"
      class="absolute bg-red-100 border z-50 border-red-400 text-red-700 px-4 py-3 rounded m-2 top-2 left-2 right-2"
      role="alert"
    >
      <strong class="font-bold">Achtung!</strong>
      <span class="block sm:inline" v-if="errorMessage">
        {{ errorMessage }}
      </span>
      <span class="block sm:inline" v-if="!errorMessage">
        Etwas ist schiefgegangen. Bitte versuche es später erneut.
      </span>

      <span
        type="button"
        class="absolute cursor-pointer top-0 bottom-0 right-0 px-4 py-3"
        @click="closeNotification()"
      >
        <svg
          class="fill-current h-6 w-6 text-red-500"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <path
            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "ErrorHandler",
  data() {
    return {}
  },
  methods: {
    removeError() {
      this.$store.commit("error/SET_ERROR", false)
      console.log("error removed", this.$route)
      if (this.$route.name === "offer") this.$router.push({ name: "main" })
    },

    closeNotification() {
      console.log("close")
      this.$store.commit("error/SET_TOAST_ERROR", false)
    },
  },
  watch: {
    "$store.state.toastError": {
      handler: function () {
        if (this.toastError) {
          setTimeout(() => {
            this.$store.commit("error/SET_TOAST_ERROR", false)
          }, 3000)
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      busy: "busy",
      error: "error/error",
      toastError: "error/toastError",
      errorMessage: "error/errorMessage",
      errorCode: "error/errorCode",
    }),
  },
}
</script>

<style></style>
