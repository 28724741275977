<template>
  <div class="h-full pt-16 text-white overflow-y-auto">
    <div class="w-full absolute bottom-32 flex justify-end p-4 flex-col">
      <button
        class="bg-fiasco-blue p-2 rounded-full text-white w-full h-14 my-2"
        @click="goStation()"
      >
        <span v-if="!whitelabel">An meine Werkstatt senden</span>
        <span v-if="whitelabel == 'mobilityconcept'">An Mobility Concept senden</span>
      </button>
      <button
        v-if="user.rights.includes('REQUEST_FIASCO_CALCULATION')"
        class="bg-fiasco-purple p-2 rounded-full text-white w-full h-14 my-2"
        @click="goFiasco()"
      >
        Kalkulation anfordern
      </button>
    </div>

    <div
      class="absolute bottom-4 left-4 w-14 h-14 p-1 rounded-full bg-transparent border-2 border-white"
      @click="goBack()"
    >
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateStationRequestType",
  data() {
    return {
      contactPopupVisible: true,
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestInterview" })
    },
    goStation() {
      this.$store.commit("stations/SET_REQUEST_MODE", "STATION")
      this.$router.push({ name: "CreateStationRequestReady" })
    },
    goFiasco() {
      this.$store.commit("stations/SET_REQUEST_MODE", "FIASCO")
      this.$router.push({ name: "CreateStationRequestReady" })
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      loginError: "customers/loginError",
      whitelabel: "whitelabel",
      user: "users/user",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      customerStation: "stations/customerStation",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      cameraPhotos: "requests/cameraPhotos",
      documentPhotos: "requests/documentPhotos",
      uploadPhotos: "requests/uploadPhotos",
      createRequestCar: "cars/createRequestCar",
      voiceMessage: "requests/voiceMessage",
    }),
  },
}
</script>
