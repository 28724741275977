<template>
  <div class="menu absolute top-0 z-40 left-0 w-screen h-full" v-if="customer || user">
    <div class="absolute top-0 left-0 w-full h-full bg-slate-800 opacity-10" @click="close()"></div>
    <div class="absolute top-0 left-0 w-80 h-full bg-slate-200">
      <div class="w-full h-32 flex items-center justify-center px-8 bg-fiasco-blue-dark">
        <img
          class="w-8 opacity-75 absolute top-2 left-2"
          src="@/assets/gfx/icon_close.png"
          @click="close()"
        />
        <img class="menu-logo mt-8" src="@/assets/gfx/fiasco-logo.png" @click="showMenu()" />
      </div>
      <div
        class="absolute left-0 bottom-0 top-32 w-full z-10 flex flex-col justify-between overflow-y-auto"
      >
        <div>
          <template v-if="customer">
            <div v-if="customer.email" class="text-gray text-sm m-4">
              Angemeldet als
              <span>
                {{ customer.email }}
              </span>
            </div>
          </template>
          <template v-if="user">
            <div v-if="user.email" class="text-gray text-sm m-4">
              Angemeldet als
              <span>
                {{ user.email }}
              </span>
            </div>
          </template>
          <div class="p-4">
            <div
              v-if="customer"
              class="bg-fiasco-purple rounded-full h-12 px-4 text-white flex items-center justify-center mb-4 relative"
              @click="goProfile()"
            >
              <img class="w-8 absolute left-2 top-2" src="@/assets/gfx/icon_profile_invert.png" />
              Mein Profil
            </div>
            <div
              class="bg-fiasco-blue rounded-full h-12 px-4 text-white flex items-center justify-center mb-4"
            >
              <a href="https://fiasco.de/" target="_blank">Über FIASCO</a>
            </div>
            <div
              class="bg-fiasco-blue rounded-full h-12 px-4 text-white flex items-center justify-center mb-4"
            >
              <a href="mailto:support@fiasco.de" target="_blank">Feedback</a>
            </div>
            <div
              class="bg-fiasco-blue rounded-full h-12 px-4 text-white flex items-center justify-center mb-4"
              @click="logout()"
            >
              Abmelden
            </div>
          </div>
        </div>

        <div class="p-4 text-gray" v-if="customerStation">
          <div class="text-gray mb-4">
            <div class="text-sm">Deine Werkstatt:</div>
            <div class="text-xl">
              {{ customerStation.name }}
            </div>
            <div class="text-sm">
              {{ customerStation.address }}
            </div>
            <div class="text-sm">{{ customerStation.zip }} {{ customerStation.city }}</div>
            <div class="flex mt-2 invert opacity-40">
              <a v-if="customerStation.phone" :href="'tel:' + customerStation.phone">
                <img
                  class="w-8 h-8 p-1 mr-4 rounded-full border border-white object-contain"
                  src="@/assets/gfx/icon_phone_white.png"
                />
              </a>
              <a v-if="customerStation.web" :href="customerStation.web" target="_blank">
                <img
                  class="w-8 h-8 p-1 2 mr-4 rounded-full border border-white object-contain"
                  src="@/assets/gfx/icon_web.png"
              /></a>
              <a v-if="customerStation.instagram" :href="customerStation.instagram" target="_blank">
                <img
                  class="w-8 h-8 p-1 mr-4 rounded-full border border-white object-contain"
                  src="@/assets/gfx/icon-insta.png"
                />
              </a>
              <a v-if="customerStation.facebook" :href="customerStation.facebook" target="_blank">
                <img
                  class="w-8 h-8 p-1 mr-4 rounded-full border border-white object-contain"
                  src="@/assets/gfx/icon-fb.png"
                />
              </a>
              <a v-if="customerStation.twitter" :href="customerStation.twitter" target="_blank">
                <img
                  class="w-8 h-8 p-1 mr-4 rounded-full border border-white object-contain"
                  src="@/assets/gfx/icon-twitter.png"
                />
              </a>
            </div>
          </div>
          <div class="text-sm mb-2" @click="openCustomerInfo()" v-if="customer">
            Kundeninformationen
          </div>
          <div class="text-sm mb-2" @click="openImprint()">Impressum</div>
          <div class="text-sm mb-2" @click="openPrivacy()">Datenschutz</div>
          <div class="opacity-50 text-xs mt-4">v 2022-05-17 1704</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  // eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
  name: "Menu",
  data() {
    return {
      menuVisible: false,
    }
  },
  methods: {
    goRequest() {
      this.$router.push({ name: "request-offers" })
    },
    goProfile() {
      this.$store.commit("SET_PROFILE_VISIBLE", true)
    },
    close() {
      this.$emit("close")
    },
    logout() {
      this.$emit("logout")
    },
    openImprint() {
      this.$store.commit("SET_IMPRINT_VISIBLE", true)
    },
    openPrivacy() {
      this.$store.commit("SET_PRIVACY_VISIBLE", true)
    },
    openCustomerInfo() {
      this.$store.commit("SET_CUSTOMER_INFO_VISIBLE", true)
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      whitelabel: "whitelabel",
      user: "users/user",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      offers: "offers/offers",
      requestStatusName: "requests/requestStatusName",
      customerStation: "stations/customerStation",
      profileVisible: "profileVisible",
    }),
  },
}
</script>
