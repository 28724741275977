import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import "./assets/css/index.css"

import S3Image from "./components/S3Image.vue"
import QrReader from "vue3-qr-reader"
import SimpleCamera from "simple-vue-camera"

const app = createApp(App)
  .use(store)
  .use(router)
  .use(QrReader)
  .component("simple-camera", SimpleCamera)
  .component("S3Image", S3Image)

router.isReady().then(() => {
  app.mount("#app")
})
