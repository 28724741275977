<template>
  <div v-if="documents.length != 0" class="">
    <div class="title flex items-center justify-center text-lg my-2">Dokumente unterschreiben</div>
    <div class="text-sm opacity-50 flex mx-2">
      <!---- info icon--->
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 mr-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
      <p>
        Die Werkstatt bietet an, folgende Dokumente digital zu unterschreiben. Das ist nicht
        notwendig, aber beschleunigt die Bearbeitung.
      </p>
    </div>
    <div class="my-2 px-1">
      <div
        class="my-4 p-2 border border-rounded"
        v-for="(document, index) in documents"
        :key="index"
      >
        <div @click="preview(document)" class="w-full text-sm">
          {{ document.name }}
        </div>
        <div class="flex items-center justify-end">
          <div
            class="h-10 w-10 pointer-cursor border border-gr rounded-full p-2 shrink-0 mx-2"
            @click="onDownload(document)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="stroke-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
          </div>
          <button
            class="bg-fiasco-blue px-2 h-10 font-normal rounded-lg text-white"
            v-if="!document.signed"
            @click="onSignDocument(document)"
          >
            Signieren
          </button>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            class="h-10 w-10 text-fiasco-blue"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import download from "downloadjs"
import { PDFDocument, rgb } from "pdf-lib"
import { mapGetters } from "vuex"
export default {
  name: "DocumentsList",
  data() {
    return {
      documents: [],
    }
  },
  methods: {
    preview(document) {
      console.log("preview doc", document)
    },
    async onSignDocument(doc) {
      console.log("signing document", this.customer)

      const d = new Date()
      const signature =
        this.customer.firstName + " " + this.customer.lastName + "\n At " + d.toUTCString()

      this.$store.commit("SET_BUSY", true)
      try {
        const s3URL = await this.$store.dispatch("getS3Image", doc.key)

        const existingPdfBytes = await fetch(s3URL).then((res) => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const pages = pdfDoc.getPages()
        for (let page = 1; page < pages.length; page++) {
          const rectsPerPage = doc.rectangles.filter((r) => r.page === page)
          if (rectsPerPage.length !== 0) {
            const docPage = pages[page - 1]
            for (const r in rectsPerPage) {
              const height = docPage.getSize().height
              docPage.drawText(signature, {
                x: rectsPerPage[r].startX - 50,
                y: height - rectsPerPage[r].startY,
                size: 10,
                color: rgb(0.95, 0.1, 0.1),
              })
            }
          }
        }
        const pdfBytes = await pdfDoc.save()
        const file = new Blob([pdfBytes], {
          type: "application/pdf",
        })
        this.uploadDoc(file, doc._id)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }

      this.$store.commit("error/SET_ERROR", false)
    },
    // eslint-disable-next-line camelcase
    async uploadDoc(doc, doc_id) {
      console.log("value")

      const formData = new FormData()
      formData.append("file", doc)
      formData.append("date", new Date())
      formData.append("originalDocumentId", doc_id)
      // Display the values
      for (const value of formData.values()) {
        console.log(value)
      }
      const payload = {
        offerId: this.selectedOffer._id,
        data: formData,
      }
      try {
        await this.$store.dispatch("offers/uploadSignedDocument", payload)
        this.$store.commit("error/SET_TOAST_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_TOAST_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }
    },
    async onDownload(doc) {
      console.log("download doc", doc)
      this.$store.commit("SET_BUSY", true)
      try {
        const s3URL = await this.$store.dispatch("getS3Image", doc.key)
        const existingPdfBytes = await fetch(s3URL).then((res) => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const pdfBytes = await pdfDoc.save()
        // maybe for unsigned documents we need to download it with rectanges
        download(pdfBytes, doc.name, "application/pdf")

        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)

        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }
      this.$store.commit("SET_BUSY", false)
    },
    async getStationDocuments() {
      this.$store.commit("SET_BUSY", true)
      try {
        await this.$store.dispatch("stations/getById", this.selectedOffer.station)
        this.documents = this.createDocumentsList(this.selectedStation.documents)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)

        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }
      this.$store.commit("SET_BUSY", false)
    },
    createDocumentsList(documents) {
      const docList = []
      for (const doc in documents) {
        const document = documents[doc]
        if (this.selectedOffer.documents.some((d) => d.originalDocId === documents[doc]._id)) {
          document.signed = true
          document.key = this.selectedOffer.documents.find(
            (d) => d.originalDocId === documents[doc]._id
          ).signedDocKey
        } else {
          document.signed = false
        }
        docList.push({ ...document })
      }
      return docList
    },
  },
  watch: {
    selectedOffer: function () {
      this.documents = this.createDocumentsList(this.selectedStation.documents)
    },
  },
  computed: {
    ...mapGetters({
      selectedStation: "stations/selectedStation",
      customer: "customers/customer",
      selectedOffer: "offers/selectedOffer",
    }),
  },

  mounted() {
    console.log("document list mounted", this.selectedOffer.documents)
    this.getStationDocuments()
  },
}
</script>
