<template>
  <div
    class="popup-imprint absolute top-0 left-0 z-50 w-full h-full bg-transparent-black text-white"
  >
    <div class="absolute z-0 top-0 left-0 w-full h-full" @click="close()"></div>
    <div class="m-4 rounded max-w-xl relative p-2 bg-slate-200 text-gray-dark h-full">
      <img
        class="absolute top-1 right-1 w-12 h-12 p-2 opacity-50"
        src="@/assets/gfx/icon_close_black.png"
        @click="close()"
      />
      <div class="w-full h-full overflow-y-auto">
        <h2 class="text-xl my-1">Impressum</h2>

        <p class="my-2">Angaben gemäß § 5 TMG</p>

        <p class="my-2">
          FIASCO GmbH<br />
          Auf der Höhe 2<br />
          71394 Kernen im Remstal
        </p>

        <p class="my-2">Vertreten durch die Geschäftsführer Harun Coşkun und Önder Aslan</p>

        <p class="my-2">
          Amtsgericht Stuttgart<br />
          HRB 779779
        </p>

        <p class="my-2">
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br />
          DE343872868
        </p>

        <p class="my-2">
          <b>Kontakt:</b><br />
          E-Mail: <a href="mailto:info@fiasco.de">info@fiasco.de</a><br />
          Telefon: <a href="tel:+4971511678230">+49 7151 - 167823 - 0</a><br />
        </p>

        <h3 class="text-lg my-1">Haftungsausschluss</h3>

        <h4>Haftung für Inhalte</h4>

        <p class="my-2">
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
          Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als
          Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
          allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
          jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
          hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach
          den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
          erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
          entfernen.
        </p>

        <br />

        <h4>Haftung für Links</h4>

        <p class="my-2">
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
          Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
          Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
          mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
          Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
          ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>

        <h4>Urheberrecht</h4>

        <p class="my-2">
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
          dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
          der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
          Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
          nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
          dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
          beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
          trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
          Inhalte umgehend entfernen.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "PopupImprint",
  data() {
    return {
      test: false,
    }
  },
  methods: {
    close() {
      this.$store.commit("SET_IMPRINT_VISIBLE", false)
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      recentMessage: "requests/recentMessage",
      requestStatusName: "requests/requestStatusName",
      selectedOffer: "offers/selectedOffer",
      customerStation: "stations/customerStation",
    }),
  },
}
</script>
