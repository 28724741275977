<template>
  <div class="intro screen register">
    <img
      class="absolute top-2 w-full h-16 object-contain z-10"
      v-if="!whitelabel"
      src="@/assets/gfx/fiasco-logo-white.png"
    />
    <div class="max-w-sm w-full flex flex-col p-4">
      <h3>Registrieren</h3>
      <div v-if="error" class="intro-retry">
        <div class="intro-retry-error">
          <div>{{ error }}</div>
        </div>
        <button @click="goIntro()">Zur Startseite</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Intro",
  data() {
    return {
      error: null,
    }
  },
  methods: {
    goIntro() {
      this.$router.push({ name: "intro" })
    },
    async onSubmit() {
      const token = this.$route.path.split("/").at(-1)
      const payload = {
        token,
        status: "CLICKED",
      }
      this.error = null

      this.$store.commit("SET_BUSY", true)

      try {
        await this.$store.dispatch("stations/verifyToken", payload)

        if (this.customerStation) {
          this.$store.commit("customers/TEMP_LOGIN")
          this.$store.commit("customers/TEMP_TOKEN", token)
          this.$emit("login")
        } else {
          this.error = "Der Link ist leider ungültig."
        }
      } catch (err) {
        this.error = "Der Link ist leider ungültig."
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }
      this.$store.commit("SET_BUSY", false)
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerStation: "stations/customerStation",
      whitelabel: "whitelabel",
    }),
  },
  mounted() {
    this.onSubmit()
    console.log("REGI EXPRESSLINK MOUNTED")
  },
}
</script>
