<template>
  <div class="h-full pt-16 px-4 text-white overflow-y-auto" v-if="editRequest">
    <div class="p-4" v-if="!user">
      <label class="pl-4 opacity-80">Schadensursache <sup>*</sup></label>
      <!--div class="create-request-interview-line-option"
        v-for="(item, index) in caseDescriptions" :key="index + 'ACED'"
        @click="selectedCaseDescription = item">
        {{item}}
      </div-->
      <select
        v-model="selectedCaseDescription"
        @change="onCaseDescriptionChange()"
        class="accident-type p-2 rounded-full text-gray-dark w-full block bg-white h-12 text-lg"
      >
        <option v-for="(item, index) in caseDescriptions" :key="index + 'ACED'">
          {{ item }}
        </option>
      </select>
      <input
        type="text"
        placeholder="Sonstige Ursache"
        v-model="editRequest.title"
        v-if="selectedCaseDescription === 'Sonstiges'"
        @input="onInput()"
        class="p-2 rounded-full text-gray-dark w-full block bg-white h-12 text-lg my-2"
      />
    </div>

    <div class="mb-1" v-if="!user">
      <label class="pl-4 opacity-80 block"
        >Wo steht das Fahrzeug? (PLZ des aktuellen Standorts) <sup>*</sup></label
      >
      <div class="input-line-additional">
        <input
          type="number"
          placeholder="70123"
          v-model="editRequest.carLocation.zip"
          @input="onInput()"
          class="zip p-2 rounded-full text-gray-dark w-full block bg-white h-12 text-lg"
        />
        <span class="critical-hint" v-if="zipScanned">Bitte prüfen</span>
      </div>
    </div>
    <p v-if="!user" class="text-sm opacity-50 mx-4">* benötigt</p>

    <p v-if="!user" class="my-4 text-center">Zusatzinfos (optional)</p>

    <div class="mb-4" v-if="!user">
      <label class="pl-4 opacity-80">Schadenart</label>
      <select
        v-model="selectedDamageLiability"
        @change="onDamageLiabilityChange()"
        class="damage-liability p-2 rounded-full text-gray-dark w-full block bg-white h-12 text-lg"
      >
        <option
          v-for="item in damageLiabilities"
          :key="item.value"
          :value="item.value"
          class="p-2 text-gray-dark"
        >
          {{ item.label }}
        </option>
      </select>
    </div>

    <div class="mb-4">
      <label class="pl-4 opacity-80">Kilometerstand</label>
      <input
        v-model="editKilometers"
        @input="onInput()"
        type="number"
        class="car-kilometers p-2 rounded-full text-gray-dark w-full block bg-white h-12 text-lg"
      />
    </div>

    <div class="flex m-4 items-center">
      <div
        class="w-6 h-6 mr-2 flex justify-center items-center rounded-full border border-fiasco-blue-dark bg-white"
        @click="toggleSmartRepair()"
      >
        <div class="w-5 h-5 bg-fiasco-blue rounded-full p-1" v-if="editRequest.smartRepairAccepted">
          <img src="@/assets/gfx/icon-check.png" />
        </div>
      </div>
      <div>
        <div>
          <span class="smart-repair bg-yellow rounded p-1 text-fiasco-blue-dark font-bold"
            >Smart Repair</span
          >
          auch erlauben
        </div>
        <div class="text-sm underline my-1">
          Mehr erfahren im
          <a
            href="https://www2.fiasco.de/10-praktische-tipps-um-als-autofahrer-geld-zu-sparen-2/"
            target="_blank"
            >FIASCO Blog</a
          >
        </div>
      </div>
    </div>
    <div class="flex m-4 items-center" v-if="user">
      <div
        class="w-6 h-6 mr-2 flex justify-center items-center rounded-full border border-fiasco-blue-dark bg-white"
        @click="editRequest.colorMatchingAllowed = !editRequest.colorMatchingAllowed"
      >
        <div
          class="w-5 h-5 bg-fiasco-blue rounded-full p-1"
          v-if="editRequest.colorMatchingAllowed"
        >
          <img src="@/assets/gfx/icon-check.png" />
        </div>
      </div>
      <div>Einlackierung berücksichtigen</div>
      <!--input
        type="checkbox"
        id="color-matching-allowed"
        v-model="editRequest.colorMatchingAllowed"
      /-->
    </div>
    <div class="my-4">
      <label class="pl-4 opacity-80">Meine Nachricht (mind. 4 Zeichen)</label>
      <textarea
        placeholder="Genaue Beschreibung, Zustand des Fahrzeugs..."
        v-model="editRequest.description"
        @input="onInput()"
        class="p-2 rounded-xl text-gray-dark w-full block bg-white h-24 text-lg"
      ></textarea>
    </div>
    <!--div class="create-request-interview-voice">
      <label for="audio-file-select" class="input-mic">
        <img class="input-mic-icon" src="@/assets/gfx/icon_voice.png">
      </label>
      <input id="audio-file-select" class="input-mic" type="file" accept="audio/*" @change="onAudioFileSelect"/>
      <div class="create-request-interview-voice-caption">Meine Sprachnachricht (1:00 min)</div>
      <button @click="onAudioFileSelect()">select</button>
      <button @click="startVoiceRecord()">start</button>
      <br>
      <button @click="stopVoiceRecord()">stop</button>
      <audio controls v-if="audioSource">
        <source :src="audioSource" type="audio/ogg">
      </audio>
    </div-->
    <p v-if="audioError">{{ audioError }}</p>
    <div class="flex w-full items-center">
      <div class="w-14 h-14 rounded-full bg-fiasco-blue m-2 p-3 shrink-0">
        <label for="doc-camera-select" class="input-camera mr-2">
          <img class="input-camera-icon" src="@/assets/gfx/icon_camera.png" />
        </label>
        <input
          id="doc-camera-select"
          class="w-0 h-0"
          type="file"
          capture="environment"
          accept="image/*"
          @change="onDocumentFileSelect"
        />
      </div>
      <div class="w-14 h-14 rounded-full bg-fiasco-blue m-2 p-3 shrink-0">
        <label for="file-select" class="input-camera mr-2">
          <img class="input-camera-icon" src="@/assets/gfx/icon_gallery.png" />
        </label>
        <input
          id="file-select"
          class="w-0 h-0"
          type="file"
          accept="image/*"
          @change="onDocumentFileSelect"
        />
      </div>
      Foto von Dokument hinzufügen
    </div>
    <div class="mb-24">
      <img
        class="create-request-interview-doc-preview"
        :src="item"
        v-for="(item, index) in editDocumentPhotos"
        :key="index + 'doc'"
      />
      <!--img class="request-edit-image-btn-delete" src="@/assets/gfx/icon_trash_white.png" @click="deleteRequestImage = item"-->
    </div>

    <button
      class="absolute bottom-4 left-4 w-14 h-14 p-1 rounded-full bg-fiasco-blue-dark border-2 border-white"
      @click="goBack()"
    >
      <img src="@/assets/gfx/arrow-prev.png" />
    </button>

    <button
      class="absolute bottom-4 right-4 w-14 h-14 p-1 rounded-full bg-fiasco-purple disabled:opacity-50"
      :disabled="!nextAvailable"
      @click="goNext()"
    >
      <img src="@/assets/gfx/arrow-next.png" />
    </button>

    <!--div class="create-request-interview-wizard">
    <h2 class="center">fiASCO-Fragen</h2>
      <div class="create-request-interview-wizard-item" v-if="questions[0].answer === 0">
        <div class="create-request-interview-wizard-item-question">
          {{questions[questionIndex].title}}
        </div>
        <div class="create-request-interview-wizard-item-answer">
          <div class="create-request-interview-wizard-item-answer-option" @click="questions[questionIndex].answer = 1">
            Ja
          </div>
          <div class="create-request-interview-wizard-item-answer-option" @click="questions[questionIndex].answer = 2">
            Nein
          </div>
        </div>
      </div>
      <div class="create-request-interview-wizard-message" v-if="questions[0].answer !== 0">
        Danke! Die fiASCO Fragen sind beantwortet.
      </div>
    </div-->
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateRequestCar",
  data() {
    return {
      questionIndex: 0,
      editRequest: null,
      editKilometers: 0,
      audioError: null,
      editDocumentPhotos: [],
      mediaRecorder: null,
      recordStopRequested: false,
      recordStopped: false,
      recorderState: null,
      audioSource: null,
      caseDescriptions: [
        "Parkschaden/Streifschaden",
        "Kollision mit einem weiteren Fahrzeug",
        "Kollision mit Tier (z.B. Reh)",
        "Kollision mit Gegenstand (z.B. Pfosten, Stein)",
        "Hagel/Unwetter",
        "Fremdkörper (z.B. Steinschlag)",
        "Sonstiges",
      ],
      damageLiabilities: [
        { label: "Keine Angabe", value: "" },
        { label: "Haftpflicht-Schaden", value: "PartialCoverage" },
        { label: "Vollkasko-Schaden", value: "Liability" },
        { label: "Selbstverschuldet", value: "FullCoverage" },
      ],
      selectedDamageLiability: null,
      selectedCaseDescription: null,
      questions: [
        {
          title: "Ist der Schaden selbstverschuldet?",
          answer: 0,
        },
        {
          title: "Ist der Schaden selbstverschuldet?",
          answer: 0,
        },
        {
          title: "Möchtest du einen Anwalt hinzuziehen?",
          answer: 0,
        },
      ],
      zipScanned: false,
    }
  },
  created: function () {
    this.selectedDamageLiability = ""
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestDamage" })
    },
    goNext() {
      if (this.customer) {
        this.$router.push({ name: "CreateRequestReady" })
      }
      if (this.user) {
        this.$router.push({ name: "CreateStationRequestType" })
      }
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
      this.editRequest.smartRepairAccepted = false
      /*
      if (this.customer.zip) {
        this.editRequest.carLocation.zip = this.customer.zip;
        this.zipScanned = true;
      }
      */
      this.selectedCaseDescription = this.editRequest.title
      if (this.createRequestCarData) {
        if (this.createRequestCarData.kilometers) {
          this.editKilometers = this.createRequestCarData.kilometers
        }
      }
    },
    onInput() {
      if (this.createRequestCarData) {
        const editCar = JSON.parse(JSON.stringify(this.createRequestCarData))
        this.editKilometers = Number(this.editKilometers).toString()
        editCar.kilometers = this.editKilometers
        this.$store.commit("cars/SET_CREATE_REQUEST_CAR", editCar)
        console.log(this.createRequestCarData)
      }
      this.$store.commit(
        "requests/SET_SELECTED_REQUEST",
        JSON.parse(JSON.stringify(this.editRequest))
      )
    },
    onAudioFileSelect(e) {
      this.audioError = null
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const reader = new FileReader()
      reader.onload = (e) => {
        console.log(e.target.result)
        this.$store.commit("requests/SET_VOICE_MESSAGE", e.target.result)
      }
      if (files[0].size < 3000 * 1024) {
        reader.readAsDataURL(files[0])
      } else {
        this.audioError = "Datei zu groß oder Aufnahme zu lang."
      }
    },
    onDocumentFileSelect(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const reader = new FileReader()
      reader.onload = (e) => {
        console.log(e.target.result)
        this.editDocumentPhotos.push(e.target.result)
        this.$store.commit("requests/SET_DOCUMENT_PHOTOS", this.editDocumentPhotos)
      }
      reader.readAsDataURL(files[0])
    },
    initVoiceRecord() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log("getUserMedia supported.")
        const that = this
        navigator.mediaDevices
          .getUserMedia(
            // constraints - only audio needed for this app
            {
              audio: true,
            }
          )
          // Success callback
          .then(function (stream) {
            that.mediaRecorder = new MediaRecorder(stream)
            let chunks = []
            that.mediaRecorder.ondataavailable = function (e) {
              console.log(e)
              chunks.push(e.data)
            }
            that.mediaRecorder.onstop = function (e) {
              console.log(e)
              console.log("recorder stopped")
              const blob = new Blob(chunks, { type: "audio/webm;codecs=opus" })
              chunks = []
              /*
                that.audioSource = window.URL.createObjectURL(blob)
                that.$store.commit('requests/SET_VOICE_MESSAGE', that.audioSource)
                // audio.src = audioURL
                */
              console.log(blob)
              const reader = new FileReader()
              reader.onload = (e) => {
                that.audioSource = window.URL.createObjectURL(blob)
                that.$store.commit("requests/SET_VOICE_MESSAGE", e.target.result)
              }
              reader.readAsDataURL(blob)
            }
          })
          // Error callback
          .catch(function (err) {
            console.log("The following getUserMedia error occurred: " + err)
          })
      } else {
        console.log("getUserMedia not supported on your browser!")
      }
    },
    startVoiceRecord() {
      this.mediaRecorder.start(1000)
      this.recorderState = this.mediaRecorder.state
      console.log("recorder started")
    },
    stopVoiceRecord() {
      this.mediaRecorder.stop()
      this.recorderState = this.mediaRecorder.state
      console.log("recorder stopped")
    },
    onCaseDescriptionChange() {
      console.log("onCaseDescriptionChange")
      console.log(this.selectedCaseDescription)
      if (this.selectedCaseDescription !== "Sonstiges") {
        this.editRequest.title = this.selectedCaseDescription
      } else {
        this.editRequest.title = ""
      }
      this.onInput()
    },

    onDamageLiabilityChange() {
      if (this.selectedDamageLiability !== "Keine Angabe") {
        this.editRequest.damageLiability = this.selectedDamageLiability
      } else {
        this.editRequest.damageLiability = ""
      }
      console.warn("NEW LIABILITY:", this.editRequest.damageLiability)
      this.onInput()
    },
    toggleSmartRepair() {
      console.log("toggleSmartRepair")
      this.editRequest.smartRepairAccepted = !this.editRequest.smartRepairAccepted
      this.$forceUpdate()
      console.log(this.editRequest)
    },
  },
  computed: {
    nextAvailable() {
      if (!this.user) {
        if (this.selectedRequest.title) {
          if (this.createRequestCarData) {
            if (
              this.selectedRequest.title.length < 4 ||
              this.selectedRequest?.carLocation?.zip?.toString().length < 4 // ZIP codes have different lengths in different countries => DE = 5, AT = 4
            ) {
              return false
            }
          } else {
            if (
              this.selectedRequest.title.length < 4 ||
              this.selectedRequest?.carLocation?.zip?.toString().length < 4 // ZIP codes have different lengths in different countries => DE = 5, AT = 4
            ) {
              return false
            }
          }
        } else {
          return false
        }
      } else {
        if (this.createRequestCarData) {
          if (!this.createRequestCarData.kilometers) return false
        }
      }
      return true
    },
    ...mapGetters({
      API: "API",
      whitelabel: "whitelabel",
      axiosConfig: "axiosConfig",
      busy: "busy",
      user: "users/user",
      customer: "customers/customer",
      selectedRequest: "requests/selectedRequest",
      createRequestCarData: "cars/createRequestCar",
      cameraPhotos: "requests/cameraPhotos",
    }),
  },
  mounted() {
    this.getEditData()
    window.scrollTo(0, 0)
    // this.initVoiceRecord()
  },
}
</script>
