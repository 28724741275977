<template>
  <img v-if="s3Url" :src="s3Url" />
</template>

<script>
export default {
  name: "S3Avatar",
  props: {
    image: String,
  },
  data() {
    return {
      s3Url: null,
    }
  },
  methods: {
    async getUrl() {
      try {
        this.s3Url = await this.$store.dispatch("getS3Image", this.image)
      } catch (err) {
        this.s3Url = ""
        console.error("Could not load S3Avatar image", { image: this.image, error: err })
      }
    },
  },
  mounted() {
    this.getUrl()
  },
}
</script>
