<template>
  <div class="w-full h-full flex items-center flex-col justify-center relative">
    <img
      class="absolute top-2 w-full h-16 object-contain z-10"
      v-if="!whitelabel"
      src="@/assets/gfx/fiasco-logo-white.png"
    />
    <div class="max-w-sm w-full flex flex-col p-4">
      <p class="text-white text-xl text-center mb-8">
        Melde deinen KfZ Schaden<br />jetzt an deine Werkstatt!
      </p>

      <button
        class="rounded-full bg-fiasco-purple text-white text-lg mb-4 h-12"
        @click="goRegister()"
      >
        Neu hier?
      </button>
      <button
        class="rounded-full border-2 border-white text-white text-lg mb-4 h-12"
        @click="goMagicLink()"
      >
        Login
      </button>
    </div>
    <div class="flex absolute bottom-2 w-full justify-center">
      <div class="mx-2 text-white text-xs opacity-50" @click="openCustomerInfo()">
        Kundeninformationen
      </div>
      <div class="mx-2 text-white text-xs opacity-50" @click="openImprint()">Impressum</div>
      <div class="mx-2 text-white text-xs opacity-50" @click="openPrivacy()">Datenschutz</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Intro",
  data() {
    return {
      allow_resend_yet: false,
      allow_resent_timer: null,
      loginForm: {
        email: "",
      },
    }
  },
  methods: {
    goMagicLink() {
      this.$router.push({ name: "magicLinkRequest" })
    },

    goRegister() {
      this.$router.push({ name: "registerMenu" })
    },

    openImprint() {
      this.$store.commit("SET_IMPRINT_VISIBLE", true)
    },

    openPrivacy() {
      this.$store.commit("SET_PRIVACY_VISIBLE", true)
    },

    openCustomerInfo() {
      this.$store.commit("SET_CUSTOMER_INFO_VISIBLE", true)
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      loginError: "customers/loginError",
      whitelabel: "whitelabel",
      onboardingVisible: "onboardingVisible",
    }),
  },
  mounted() {
    console.log("INTRO MOUNTED")

    if (this.customer?._id) {
      this.$router.push({ name: "main" })
    }

    if (this.$route.params.partner === "rep2go") {
      // todo: check exists
      this.$store.commit("SET_WHITELABEL", this.$route.params.partner)
    }
    if (this.$route.params.partner === "mobilityconcept") {
      this.$store.commit("SET_WHITELABEL", this.$route.params.partner)
    }
    if (this.$route.name === "Imprint") {
      this.$store.commit("SET_IMPRINT_VISIBLE", true)
    }
    if (this.$route.name === "Privacy") {
      this.$store.commit("SET_PRIVACY_VISIBLE", true)
    }

    // if (this.$route.name === "intro") {
    //   this.$store.commit("SET_ONBOARDING_VISIBLE", true);
    // }
  },
}
</script>
