import axios from "axios"

function storeLoginState(context, customer, token) {
  context.commit("LOGIN", customer)
  context.commit("SET_TOKEN", token, { root: true })
  localStorage.setItem("fiasco-token", token)
}

function clearLoginState(context) {
  context.commit("LOGIN", null)
  context.commit("SET_TOKEN", null, { root: true })
}

// private method used by `authWithMagicToken` and `login`.
async function doLogin(context, endpoint, token) {
  clearLoginState(context)

  let response
  try {
    response = await axios.post(context.rootGetters.API.baseUrl + endpoint, {
      token,
    })
  } catch (err) {
    console.error(`doLogin: Error sending request to endpoint ${endpoint}`)
    // throw err
    // throw new Error("Error : custumers 28")
    return false
  }

  if (!response?.data?.success) {
    console.error("doLogin: Did not receive a successful response", response)
    // throw new Error(`Did not receive a successful response: ${response}`)
    return false
  }

  console.log("doLogin: successful")
  storeLoginState(context, response.data.customer, response.data.token)

  return true
}

const state = {
  customer: null,
  customers: [],
  loginError: null,
  expressLinkToken: null,
}

// getters
const getters = {
  customer: (state) => state.customer,
  customers: (state) => state.customers,
  loginError: (state) => state.loginError,
  expressLinkToken: (state) => state.expressLinkToken,
}

// Actions
const actions = {
  // payload fields:
  // token: string
  async authWithToken(context, payload) {
    const endpoint = "customers/customer-action"

    let response
    try {
      response = await axios.post(context.rootGetters.API.baseUrl + endpoint, {
        token: payload.token,
      })
    } catch (err) {
      console.error(`requestEmailLoginLink: Error sending request to endpoint ${endpoint}`)
      // throw err
      return false
    }

    if (!response?.data?.success) {
      console.error("requestEmailLoginLink: Did not receive a successful response", response)
      // throw new Error(`Did not receive a successful response: ${response}`)
      return false
    }

    return true
  },

  // payload fields:
  //   email: string containing an email address of a customer.
  async requestEmailLoginLink(context, payload) {
    const endpoint = "customers/send-magic-link"

    let response
    try {
      response = await axios.post(context.rootGetters.API.baseUrl + endpoint, {
        email: payload.email,
        station: payload.station,
      })
    } catch (err) {
      console.error(`requestEmailLoginLink: Error sending request to endpoint ${endpoint}`, err)
      // throw err
      return false
    }

    if (!response?.data?.success) {
      console.error("requestEmailLoginLink: Did not receive a successful response", response)
      // throw new Error(`Did not receive a successful response: ${response}`)
      return false
    }

    // For "unknown" addresses (= new customer) return false here (that's how it is
    // implemented in CreateRequestIntro.vue).
    if (response?.data?.new) return false

    return true
  },

  // arguments:
  //   token: magic link token.
  async authWithMagicToken(context, token) {
    const endpoint = "customers/magic-link-auth"
    return doLogin(context, endpoint, token)
  },

  // arguments:
  //   token: signed JWT.
  async login(context, token) {
    const endpoint = "customers/auth"
    return doLogin(context, endpoint, token)
  },

  async getById(context, payload) {
    let response

    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "customers/getById/" + payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("customers/getById network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server")
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("LOGIN", response.data)
      return response
    } else {
      console.error("customers/getById error", response)
      throw new Error("Kundendaten konnten nicht geladen werden")
    }
  },
  async getAll(context) {
    let response
    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "customers",
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("customers/getAll network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_ALL", response.data)
      return response
    } else {
      console.error("customers/getAll error", response)
      throw new Error("Kundendaten konnten nicht geladen werden")
    }
  },

  async update(context, payload) {
    console.log("update customer")

    let response
    try {
      response = await axios.patch(
        context.rootGetters.API.baseUrl + "customers/" + payload._id,
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("customer/update network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server")
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error(response)
      throw new Error("Kundendaten konnten nicht aktualisiert werden")
    }
  },

  async uploadCameraPhoto(context, payload) {
    console.log("uploadCameraPhoto")

    let response

    try {
      response = await axios.post(
        context.rootGetters.API.baseUrl + "customers/upload/camera",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("customers/uploadCameraPhoto network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server")
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("customers/uploadCameraPhoto error", response)
      throw new Error("Kundendaten konnten nicht aktualisiert werden")
    }
  },

  async register(context, payload) {
    console.log("register customer")

    let response
    try {
      response = await axios.put(context.rootGetters.API.baseUrl + "customers/register", payload)
    } catch (err) {
      console.error("customers/register network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server")
    }

    if (response.status === 200 && response?.data !== null) {
      return {
        success: true,
        data: response,
      }
    } else {
      console.error("customers/register error", response)
      throw new Error("Unerwartete Antwort vom FIASCO-Server")
    }
  },

  async registerAndLogin(context, payload) {
    console.log("register and login customer")

    clearLoginState(context)

    let response
    try {
      response = await axios.put(context.rootGetters.API.baseUrl + "customers/register", payload)
    } catch (err) {
      console.error("customers/registerAndLogin network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (!response?.data?.success || !response?.data?.customer || !response?.data?.token) {
      const err = new Error("Unerwartete Antwort vom FIASCO-Server")
      err.response = response
      throw err
    }

    storeLoginState(context, response.data.customer, response.data.token)

    return response.data
  },

  async resendRegister(context, payload) {
    console.log("resendRegister customer")

    let response

    try {
      response = await axios.post(
        context.rootGetters.API.baseUrl + "customers/register-resend",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("customers/resendRegister network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      return {
        success: true,
        data: response,
      }
    } else {
      console.error("customers/resendRegister error", response)
      throw new Error("Daten konnten nicht geladen werden")
    }
  },

  async verify(context, payload) {
    console.log("verify customer")

    let response

    try {
      response = await axios.post(context.rootGetters.API.baseUrl + "customers/verify", payload)
    } catch (err) {
      console.error("customers/verify network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }

    if (response.status === 200 && response?.data !== null) {
      return {
        success: true,
        data: response,
      }
    } else {
      console.error("customers/verify error", response)
      throw new Error("Die Verifizierung ist fehlgeschlagen")
    }
  },

  async create(context, payload) {
    console.log("create customer", payload)

    let response

    try {
      response = await axios.put(
        context.rootGetters.API.baseUrl + "customers",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (err) {
      console.error("customers/create network error", err)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", err)
    }
    if (response.status === 200 && response?.data !== null) {
      return response.data
    } else {
      console.error("customers/create error", response)
      throw new Error("Der Kundendatensatz konnte nicht erstellt werden")
    }
  },
}

// mutations
const mutations = {
  LOGIN(state, payload) {
    state.customer = payload
    localStorage.removeItem("fiasco-user")
    if (payload) {
      localStorage.setItem("fiasco-customer", JSON.stringify(payload))
    }
  },

  SET_EMAIL_VERIFIED(state) {
    state.customer.emailVerified = true
    localStorage.setItem("fiasco-customer", JSON.stringify(state.customer))
  },

  TEMP_LOGIN(state) {
    console.log("TEMP LOGIN")
    state.customer = {
      email: "",
    }
    // localStorage.setItem('fiasco-customer', JSON.stringify(payload))
  },
  TEMP_TOKEN(state, token) {
    console.log("TEMP TOKEN")
    state.expressLinkToken = token
  },

  SET_CUSTOMER_DATA(state, payload) {
    if (!state.customer) {
      state.customer = {}
    }
    if (payload.firstName && !state.customer.firstName) {
      state.customer.firstName = payload.firstName
    }
    if (payload.lastName && !state.customer.lastName) {
      state.customer.lastName = payload.lastName
    }
    if (payload.city && !state.customer.city) {
      state.customer.city = payload.city
    }
    if (payload.address && !state.customer.address) {
      state.customer.address = payload.address
    }
    if (payload.zip && !state.customer.zip) {
      state.customer.zip = payload.zip
    }
    if (payload.email && !state.customer.email) {
      state.customer.email = payload.email
    }
  },
  FORCE_CUSTOMER_DATA(state, payload) {
    if (payload.firstName) {
      state.customer.firstName = payload.firstName
    }
    if (payload.lastName) {
      state.customer.lastName = payload.lastName
    }
    if (payload.city) {
      state.customer.city = payload.city
    }
    if (payload.address) {
      state.customer.address = payload.address
    }
    if (payload.zip) {
      state.customer.zip = payload.zip
    }
    if (payload.email) {
      state.customer.email = payload.email
    }
  },

  LOGOUT(state) {
    console.log("LOGOUT")
    state.customer = null
    localStorage.removeItem("fiasco-token")
    localStorage.removeItem("fiasco-customer")
  },
  SET_LOGIN_ERROR(state, data) {
    state.loginError = data
  },
  SET_ALL(state, data) {
    state.customers = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
