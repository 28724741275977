import axios from "axios"

const state = {
  userId: null,
  user: null,
  selectedUser: null,
  loginError: null,
  stationUsers: [],
  users: [],
  roles: ["ADMIN", "FIASCO_ADMIN", "FIASCO_EDITOR", "STATION_ADMIN", "STATION_WORKER"],
  rights: [],
  /*

  - Notizen der Fakturierung zur jeweiligen Parteien
  (ob der Kunde bezahlen muss noch oder der Kunde usw.)
  -
  */
}

// getters
const getters = {
  user: (state) => state.user,
  users: (state) => state.users,
  stationUsers: (state) => state.stationUsers,
  loginError: (state) => state.loginError,
  userId: (state) => state.userId,
  selectedUser: (state) => state.selectedUser,
  colleagues: (state) => {
    let colleagues = []
    if (state.user.station) {
      colleagues = state.users.filter((o) =>
        o.station ? o.station._id === state.user.station : false
      )
    }
    return colleagues
  },
  rights: (state) => {
    let rights = []
    if (state.user.isFiasco) {
      rights = state.rights
    } else {
      rights = state.rights.filter((o) => !o.fiascoOnly)
    }
    return rights
  },
  roles: (state) => state.roles,
  rightsByGroup: (state) => (group) => {
    let rights = []
    if (state.user.isFiasco) {
      rights = state.rights.filter((o) => o.group === group)
    } else {
      rights = state.rights.filter((o) => !o.fiascoOnly).filter((o) => o.group === group)
    }
    return rights
  },
}

// actions
const actions = {
  // not used anywhere
  async getRights(context) {
    let response
    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "user/rights",
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("users/getRights network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }
    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_RIGHTS", response.data)
    } else {
      console.error("users/getRights error", response)
      throw new Error("Benutzerrechte konnten nicht abgerufen werden")
    }
  },

  // not used anywhere
  async getByStation(context, payload) {
    console.log("getByStation")

    let response
    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "user/station/" + payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("users/getByStation network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }
    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_STATION_USERS", response.data)
    } else {
      console.error("users/getByStation error", response)
      throw new Error("Fehler beim Laden aller Werkstatt-Benutzer")
    }
  },

  async getLogs(context) {
    let response
    try {
      response = await axios.get(context.rootGetters.API.baseUrl + "user/log")
    } catch (error) {
      console.error("users/getLogs network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }
    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_LOGS", response.data)
    } else {
      console.error("users/getLogs error", response)
      throw new Error("Fehler beim Laden der Log-Daten")
    }
  },

  async login(context, payload) {
    let response
    try {
      response = await axios.post(context.rootGetters.API.baseUrl + "user/auth", {
        email: payload.email,
        password: payload.password,
      })
    } catch (error) {
      console.error("users/login network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (!response.data?.success) {
      console.error("users/login error", response)
      context.commit("SET_LOGIN_ERROR", response.data.message)

      throw new Error("Fehler bei Anmeldung")
    }

    const payload1 = {
      user: response.data.user,
      token: response.data.token,
    }
    console.log("LOGIN SUCCESSFUL")
    context.commit("LOGIN", payload1.user)
    context.commit("SET_TOKEN", payload1.token, { root: true })
    localStorage.setItem("fiasco-token", payload1.token)
    return response
  },

  // not used anywhere
  async createLog(context, payload) {
    console.log("create log", payload)
    let response
    try {
      response = await axios.put(context.rootGetters.API.baseUrl + "user/log", payload)
    } catch (error) {
      console.error("users/createLog network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }
    if (response.status === 200 && response?.data !== null) {
      context.dispatch("getLogs", response)
    } else {
      console.error("users/createLog error", response)
      throw new Error("Fehler beim Senden von Daten an den Server")
    }
  },
}

// mutations
const mutations = {
  LOGIN(state, payload) {
    console.log("LOGIN")
    state.userId = payload._id
    state.user = payload
    localStorage.removeItem("fiasco-customer")
    localStorage.setItem("fiasco-user", JSON.stringify(payload))
  },
  LOGOUT(state) {
    console.log("LOGOUT")
    state.userId = null
    state.user = null
    localStorage.removeItem("fiasco-token")
    localStorage.removeItem("fiasco-user")
  },
  SET_LOGIN_ERROR(state, data) {
    state.loginError = data
  },
  SET_USER_ID(state, data) {
    state.userId = data
  },
  SET_RIGHTS(state, data) {
    state.rights = data
  },
  SET_STATION_USERS(state, data) {
    state.stationUsers = data
  },
  SET_USER(state, data) {
    state.user = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
