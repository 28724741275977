import axios from "axios"

const state = {
  stations: [],
  customerStation: null,
  requestMode: "STATION",
}

// getters
const getters = {
  stations: (state) => state.stations,
  customerStation: (state) => state.customerStation,
  requestMode: (state) => state.requestMode,
  selectedStation: (state) => state.selectedStation,
}

// actions
const actions = {
  async getAll(context) {
    let response
    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "stations",
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("stations/getAll network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_ALL", response.data)
      return response
    } else {
      console.error("stations/getAll error", response)
      throw new Error("Fehler beim Laden der Werkstatt-Daten")
    }
  },
  async getById(context, payload) {
    let response

    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "stations/id/" + payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("stations/getById network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_SELECTED_STATION", response.data)
      return response
    } else {
      console.error("stations/getById network error", response)
      throw new Error("Fehler beim Laden der Werkstatt-Daten")
    }
  },
  async getByCode(context, payload) {
    let response

    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "stations/code/" + payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("stations/getByCode network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }
    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_CUSTOMER_STATION", response.data)
      return response
    } else {
      console.error("stations/getByCode error", response)
      throw new Error("Fehler beim Laden der Werkstatt-Daten")
    }
  },
  async verifyToken(context, payload) {
    let response

    try {
      response = await axios.patch(
        context.rootGetters.API.baseUrl + "stations/code/expressLink",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("stations/verifyToken network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }
    if (!response.data?.success || !response.data?.station) {
      console.error("stations/verifyToken: response not successful", response)
      throw new Error("Die Anfrage konnte nicht verarbeitet werden")
    }
    context.commit("SET_CUSTOMER_STATION", response.data.station)
  },

  async getCustomerStation(context, payload) {
    let response

    try {
      response = await axios.get(
        context.rootGetters.API.baseUrl + "stations/id/" + payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("stations/getCustomerStation network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_CUSTOMER_STATION", response.data)
      return response
    } else {
      console.error("stations/getCustomerStation error", response)
      throw new Error("Fehler beim Laden der Werkstatt-Daten")
    }
  },
  async create(context, payload) {
    console.log("create station")

    let response

    try {
      response = await axios.put(
        context.rootGetters.API.baseUrl + "stations/",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("stations/create network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      return response
    } else {
      console.error("stations/create error", response)
      throw new Error("Fehler beim Erstellen eines Werkstatt-Datensatzes")
    }
  },
  async update(context, payload) {
    console.log("update station")

    let response

    try {
      response = await axios.patch(
        context.rootGetters.API.baseUrl + "stations/" + payload._id,
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("stations/update network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }

    if (response.status === 200 && response?.data !== null) {
      context.commit("SET_CUSTOMER_STATION", response.data)
      return response
    } else {
      console.error("stations/update error", response)
      throw new Error("Fehler beim Speichern der Werkstatt-Daten")
    }
  },
  async deletePoster(context, payload) {
    console.log("deletePoster")

    let response

    try {
      response = await axios.patch(
        context.rootGetters.API.baseUrl + "stations/poster/delete",
        payload,
        context.rootGetters.axiosConfig
      )
    } catch (error) {
      console.error("stations/deletePoster network error", error)
      throw new Error("Fehler bei Verbindung zum FIASCO-Server", error)
    }
    if (response.status === 200 && response?.data !== null) {
      console.log("poster deleted", response)
    } else {
      console.error("stations/deletePoster error", response)
      throw new Error("Das Banner-Bild konnte nicht gelöscht werden")
    }
  },
}

// mutations
const mutations = {
  SET_ALL(state, data) {
    state.stations = data
  },
  SET_SELECTED_STATION(state, data) {
    state.selectedStation = data
  },
  SET_CUSTOMER_STATION(state, data) {
    state.customerStation = data
  },
  SET_REQUEST_MODE(state, data) {
    state.requestMode = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
