<template>
  <div class="h-full pt-16 px-2 text-white">
    <div class="h-full">
      <h3 class="text-2xl text-center my-4">Deine Werkstatt braucht mindestens 4&nbsp;Fotos:</h3>
      <div class="grid grid-cols-2 bg-fiasco-blue-dark p-2 rounded-md">
        <div class="p-2">
          <img src="@/assets/gfx/photo-template-diagonal-front.jpg" />
          <p>Fahrzeug diagonal von vorn</p>
        </div>
        <div class="p-2">
          <img src="@/assets/gfx/photo-template-diagonal-rear.jpg" />
          <p>Fahrzeug diagonal von hinten</p>
        </div>
        <div class="p-2">
          <img src="@/assets/gfx/photo-template-part.jpg" />
          <p>Beschädigtes Teil (bzw. alle beschädigten Teile), 1-2m Abstand</p>
        </div>
        <div class="p-2">
          <img src="@/assets/gfx/photo-template-damage.jpg" />
          <p>Schaden in Nahaufname (50cm Abstand)</p>
        </div>
      </div>
    </div>

    <div
      class="absolute bottom-4 left-4 w-14 h-14 p-1 rounded-full bg-transparent border-2 border-white"
      @click="goBack()"
    >
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>

    <div
      class="absolute bottom-4 right-4 w-14 h-14 p-1 rounded-full bg-fiasco-purple"
      @click="goNext()"
    >
      <img src="@/assets/gfx/arrow-next.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateRequestPhotosExample",
  data() {
    return {
      examplesIndex: 0,
      examplePhotos: [
        {
          title: "Beispiel: Gesamtes Fahrzeug",
          description: "Das ganze Fahrzeug muss sichbar sein.",
          image: "2.jpg",
          placeholder: "car-placeholder-front.png",
        },
        {
          title: "Beispiel: Beschädigtes Teil",
          description: "Das ganze Teil muss sichbar sein.",
          image: "3.jpg",
          placeholder: "car-placeholder-diagonal-front.jpg",
        },
        {
          title: "Beispiel: Nahaufnahme",
          description: "Etwa 50cm Abstand.",
          image: "4.jpg",
          placeholder: "car-placeholder-back.png",
        },
      ],
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "main" })
    },
    goNext() {
      this.$router.push({ name: "CreateRequestPhotos" })
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      damageTypes: "requests/damageTypes",
      damageLocations: "requests/damageLocations",
      damageLocationName: "requests/damageLocationName",
      damageTypeName: "requests/damageTypeName",
      selectedRequest: "requests/selectedRequest",
      damageDummyCar: "damageDummyCar",
    }),
  },
  mounted() {
    console.log("CREATE REQUEST PHOTOS EXAMPLE MOUNTED")

    // The value in this variable is read from local state, which can be unprepared if the browser was refreshed.
    if (!this.selectedRequest) {
      this.$router.push({ name: "intro" })
    }
  },
}
</script>
