/* eslint-disable no-throw-literal */
<template>
  <div class="profile absolute top-0 left-0 w-screen h-full z-50" v-if="editCustomer">
    <div class="absolute top-0 left-0 w-80 h-full bg-slate-200 overflow-y-auto">
      <img
        class="w-8 opacity-40 invert absolute top-2 left-2"
        src="@/assets/gfx/icon_close.png"
        @click="close()"
      />
      <div class="flex items-center h-14 px-4 justify-center">
        <h2 class="center text-xl opacity-75">Mein Profil</h2>
      </div>

      <div class="p-4">
        <div class="p-4 flex justify-center items-center" @click="goEditMode()" v-if="!editMode">
          <button class="bg-fiasco-purple block mr-2 rounded-full w-12 h-12 p-2">
            <img src="@/assets/gfx/icon_edit.png" />
          </button>
          <div class="text-sm opacity-50">Daten bearbeiten</div>
        </div>
        <!--div class="profile-avatar-outer" v-if="customer._id">
          <img
            class="profile-avatar"
            :src="API.imageUrl + 'customers/' + editCustomer.image"
            v-if="editCustomer.image && !editCustomer.s3Image"
          />
          <s3-image
            class="profile-avatar"
            v-if="editCustomer.s3Image"
            :image="editCustomer.s3Image"
          />

          <div
            class="profile-avatar"
            v-if="!editCustomer.image && !editCustomer.s3Image"
          />
          <label
            for="file-select"
            class="input-camera profile-btn-avatar"
            v-if="editMode"
          >
            <img class="input-camera-icon btn-icon" src="@/assets/gfx/icon_camera.png" />
          </label>
          <input
            id="file-select"
            class="input-camera profile-btn-avatar"
            type="file"
            capture="user"
            accept="image/*"
            @change="onFileSelect"
            v-if="editMode"
          />
          <label
            for="file-select"
            class="input-camera profile-btn-avatar"
            v-if="editMode"
          >
            <img
              class="input-camera-icon gallery btn-icon"
              src="@/assets/gfx/icon_gallery.png"
            />
          </label>
          <input
            id="file-select"
            class="input-camera profile-btn-avatar"
            type="file"
            accept="image/*"
            @change="onFileSelect"
            v-if="editMode"
          />
        </div-->
        <div class="mb-2">
          <label class="pl-4 text-sm opacity-50">Vorname</label>
          <input
            type="text"
            v-model="editCustomer.firstName"
            class="p-2 rounded-full text-gray-dark w-full block bg-white disabled:bg-transparent disabled:border"
            :disabled="!editMode"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm opacity-50">Nachname</label>
          <input
            type="text"
            v-model="editCustomer.lastName"
            class="p-2 rounded-full text-gray-dark w-full block bg-white disabled:bg-transparent disabled:border"
            :disabled="!editMode"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm opacity-50">E-Mail</label>
          <input
            type="email"
            v-model="editCustomer.email"
            class="p-2 rounded-full text-gray-dark w-full block bg-white disabled:bg-transparent disabled:border"
            :disabled="customer.email.length > 3 || !editMode"
          />
          <div
            class="flex items-center my-2 px-4 text-sm"
            v-if="!editMode && customer.emailVerified"
          >
            <img class="w-4 mr-2 invert opacity-50" src="@/assets/gfx/icon_check_white.png" />
            E-Mail-Adresse bestätigt.
          </div>
          <div class="text-sm px-4 my-1" v-if="!editMode && !customer.emailVerified">
            Bitte E-Mail-Adresse bestätigen.
          </div>
          <div class="text-sm px-4 my-1" v-if="editMode && customer.email.length > 3">
            Deine E-Mail-Adresse kann nicht geändert werden. Lege für eine neue E-Mail ein neues
            Konto an.
          </div>
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm opacity-50">PLZ</label>
          <input
            type="text"
            class="p-2 rounded-full text-gray-dark w-full block bg-white disabled:bg-transparent disabled:border"
            v-model="editCustomer.zip"
            :disabled="!editMode"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm opacity-50">Stadt</label>
          <input
            type="text"
            class="p-2 rounded-full text-gray-dark w-full block bg-white disabled:bg-transparent disabled:border"
            v-model="editCustomer.city"
            :disabled="!editMode"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm opacity-50">Adresse</label>
          <input
            type="text"
            class="p-2 rounded-full text-gray-dark w-full block bg-white disabled:bg-transparent disabled:border"
            v-model="editCustomer.address"
            :disabled="!editMode"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm opacity-50">Mobil Nr.</label>
          <input
            type="text"
            class="p-2 rounded-full text-gray-dark w-full block bg-white disabled:bg-transparent disabled:border"
            v-model="editCustomer.phone"
            :disabled="!editMode"
          />
        </div>
        <div class="mt-4 mb-2" v-if="editMode">
          <button
            :disabled="!submitAvailable"
            class="bg-fiasco-purple text-white rounded-full h-14 w-full mb-2"
            @click="updateCustomer()"
          >
            Speichern
          </button>
        </div>
        <div class="mb-2" v-if="editMode && customer._id">
          <button class="rounded-full text-gray border h-14 w-full" @click="editMode = false">
            Abbrechen
          </button>
          {{ error }}
        </div>

        <div class="mt-4" v-if="!editMode && customer.email">
          <h2>Meine Fahrzeuge</h2>
          <div class="profile-cars">
            <div
              class="mb-2 border border-slate-400 rounded-lg p-2"
              v-for="item in customerCars"
              :key="item._id"
              @click="onCarClick(item)"
            >
              <!--img class="create-request-car-pool-item-avatar" :src="API.imageUrl + 'cars/' + item.image"-->
              <div class="create-request-car-pool-item-caption">
                <div v-if="item.manufacturer || item.model">
                  {{ item.manufacturer }} &nbsp; {{ item.model }}&nbsp;
                </div>
                <div v-if="item.firstRegistration">Erstzulassung: {{ item.firstRegistration }}</div>
                <div v-if="item.numberPlate">Kennzeichen: {{ item.numberPlate }}</div>
                <div>FIN: {{ item.vin }}</div>
              </div>
            </div>
            <!--button @click="createCar()">Fahrzeug hinzufügen</!--button-->
          </div>
        </div>
      </div>
    </div>
    <car-edit v-if="editCarVisible" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import CarEdit from "./CarEdit.vue"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Profile",
  data() {
    return {
      menuVisible: false,
      editMode: false,
      editCustomer: null,
      avatarUpload: null,
      miniAvatarUpload: null,
      error: null,
    }
  },
  components: {
    CarEdit,
  },
  methods: {
    close() {
      this.$store.commit("SET_PROFILE_VISIBLE", false)
    },

    goEditMode() {
      this.editMode = !this.editMode
    },

    async updateCustomer() {
      if (this.customer.email.length < 3) {
        this.registerCustomerAndLogin().then(() => {
          this.editMode = false
          this.close()
        })
      } else {
        this.$store.commit("SET_BUSY", true)
        try {
          await this.$store.dispatch("customers/update", this.editCustomer)
          await this.$store.dispatch("customers/getById", this.customer._id)
          this.getEditData()
          this.$store.commit("SET_BUSY", false)
          this.$store.commit("error/SET_TOAST_ERROR", false)
        } catch (err) {
          this.$store.commit("SET_BUSY", false)
          this.$store.commit("error/SET_TOAST_ERROR", true)
          this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
          this.$store.commit("error/SET_ERROR_CODE", err.status)
          throw new Error("Failed to update custumer", err)
        }
      }
    },

    async registerCustomerAndLogin() {
      console.log("registerCustomerAndLogin")
      const payload = {
        email: this.editCustomer.email,
        station: this.customerStation._id,
        firstName: this.editCustomer.firstName,
        lastName: this.editCustomer.lastName,
        phone: this.editCustomer.phone,
        address: this.editCustomer.address,
        city: this.editCustomer.city,
        zip: this.editCustomer.zip,
      }

      let response
      try {
        response = await this.$store.dispatch("customers/registerAndLogin", payload)
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        if (err?.response?.status === 400) {
          this.emailAlreadyExists = true
          // eslint-disable-next-line no-throw-literal
          throw "Diese E-Mail ist schon registriert."
        } else {
          console.error("registerCustomerAndLogin error:", err)
          // eslint-disable-next-line no-throw-literal
          throw "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später noch einmal."
        }
      }

      if (!response.success) {
        console.error("registerCustomerAndLogin error: unexpected response", response)
        // eslint-disable-next-line no-throw-literal
        throw "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später noch einmal."
      }

      console.log("new customer registered")
      return response
    },

    getEditData() {
      this.editCustomer = JSON.parse(JSON.stringify(this.customer))
    },

    async onFileSelect(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.$store.commit("SET_BUSY", true)
      try {
        const blob = await this.$store.dispatch("fileToSizedBlob", { file: files[0] })
        this.avatarUpload = blob
        this.uploadAvatar()
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to load file", err)
      }
    },

    async uploadAvatar() {
      console.log("uploading avatar")
      const payload = {
        key: "images/customers/" + this.editCustomer._id,
        image: this.avatarUpload,
      }
      try {
        const res = await this.$store.dispatch("uploadS3CameraPhoto", payload)
        console.log(res)
        this.editCustomer.s3Image = res
        this.updateCustomer()
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to upload avatar", err)
      }
    },

    async uploadMiniAvatar() {
      console.log("uploading mini avatar")
      try {
        const payload = {
          // id: this.editCustomer._id,
          photo: this.miniAvatarUpload,
        }
        const res = await this.$store.dispatch("customers/uploadCameraPhoto", payload)
        this.editCustomer.smallImage = res.data
        this.updateCustomer()
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to upload mini avatar", err)
      }
    },

    onCarClick(car) {
      console.log(car)
      this.$store.commit("cars/SET_SELECTED_CAR", car)
      this.$store.commit("cars/SET_EDIT_CAR_VISIBLE", true)
    },

    async createCar() {
      const payload = {
        model: "Mein Auto",
        customer: this.customer._id,
      }
      this.$store.commit("SET_BUSY", true)
      try {
        const res = await this.$store.dispatch("cars/create", payload)
        console.log("created car:", res)
        await this.$store.dispatch("cars/getByCustomer", this.customer._id)
        this.$store.commit(
          "cars/SET_SELECTED_CAR",
          this.customerCars.find((o) => o._id === res)
        )
        this.$store.commit("cars/SET_EDIT_CAR_VISIBLE", true)

        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to create car", err)
      }
    },
  },
  computed: {
    submitAvailable() {
      if (!this.customer._id) return false

      return this.editCustomer.email.includes("@") && this.editCustomer.email.length > 4
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      requests: "requests/requests",
      offers: "offers/offers",
      editCarVisible: "cars/editCarVisible",
      customerStation: "stations/customerStation",
    }),
  },
  mounted() {
    this.getEditData()
    if (!this.customer._id) {
      this.editMode = true
    }
  },
}
</script>
