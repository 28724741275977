<template>
  <div class="h-full pt-16 px-4 text-white overflow-y-auto">
    <div class="create-request-ready-content" v-if="requestSent">
      <img class="create-request-ready-fox" src="@/assets/gfx/fox-victory-transparent.png" />
      <h2 class="text-2xl text-center mb-2">Geschafft!</h2>

      <img
        class="w-14 h-14 block mx-auto mb-4 p-2 object-contain border-2 rounded-full"
        src="@/assets/gfx/icon_mail_white.png"
      />

      <div v-if="requestSent">
        <p class="text-center">Deine Anfrage wurde versendet!</p>
        <p class="text-center">
          Wir haben dir eine E-Mail mit weiteren Informationen zum Ablauf gesendet.
        </p>
      </div>

      <!--button class="transparent" v-if="!requestSent && !requestSaved" @click="requestSaved = true">An alle Werkstätten senden</button>
      <button class="transparent" v-if="!requestSent && !requestSaved" @click="requestSaved = true">An Hersteller-Werkstätten senden</button-->
      <button
        v-if="requestSent"
        @click="goMain()"
        class="w-full h-14 rounded-full bg-fiasco-blue my-4"
      >
        Zurück zur Übersicht
      </button>
    </div>

    <div
      class="mx-2 mt-6 p-2 rounded-lg bg-slate-200 text-gray-dark mb-24"
      v-if="contactPopupVisible && customer"
    >
      <div v-if="!customer._id">
        <h2 class="text-lg text-center">Einen Moment!</h2>
        <p>Wir brauchen noch deine Kontaktdaten, damit die Anfrage gespeichert werden kann:</p>

        <div class="my-2">
          <label class="text-sm">E-Mail</label>
          <input
            type="text"
            class="w-full h-8 rounded-full block"
            placeholder="ich@email.de"
            v-model="editCustomer.email"
            disabled
          />
        </div>
      </div>

      <div v-if="customer._id">
        <h2 class="text-lg text-center">Gleich fertig!</h2>
        <p>Ist die Anfrage für dich oder eine andere Person?</p>
      </div>

      <div class="my-4">
        <div class="flex items-center mb-4" @click="deviatingOwner = false">
          <div
            :class="{ 'bg-fiasco-blue': !deviatingOwner }"
            class="w-5 h-5 rounded-full bg-slate-400 shrink-0 mr-2"
          />
          <span class="opacity-50" :class="{ 'opacity-100': !deviatingOwner }">
            Ich bin Fahrzeughalter</span
          >
        </div>
        <div
          class="flex items-center"
          :class="{ active: deviatingOwner }"
          @click="deviatingOwner = true"
        >
          <div
            :class="{ 'bg-fiasco-blue': deviatingOwner }"
            class="w-5 h-5 rounded-full bg-slate-400 shrink-0 mr-2"
          />
          <span class="opacity-50" :class="{ 'opacity-100': deviatingOwner }">
            Abweichender Fahrzeughalter</span
          >
        </div>
      </div>

      <div v-if="!deviatingOwner">
        <div class="mb-2">
          <label class="pl-4 text-sm">Vorname <sup>*</sup></label>
          <input
            class="firstname w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editCustomer.firstName"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm">Nachname <sup>*</sup></label>
          <input
            class="lastname w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editCustomer.lastName"
          />
        </div>
        <p class="pl-4 text-sm opacity-50">* Pflichtfelder</p>
      </div>

      <div class="popup-form-optional" v-if="!customer._id || deviatingOwner">
        <h3 v-if="deviatingOwner && !customer._id">Meine Daten</h3>
        <div class="popup-form-line" v-if="deviatingOwner && !customer._id">
          <label class="pl-4 text-sm">Vorname</label>
          <input
            class="w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editCustomer.firstName"
          />
        </div>
        <div class="popup-form-line" v-if="deviatingOwner && !customer._id">
          <label class="pl-4 text-sm">Nachname</label>
          <input
            class="w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editCustomer.lastName"
          />
        </div>
        <div class="popup-form-line" v-if="!customer._id && !deviatingOwner">
          <label class="pl-4 text-sm">Telefonnr. für Rückfragen</label>
          <input
            class="w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editCustomer.phone"
          />
        </div>
        <div class="popup-form-line" v-if="deviatingOwner">
          <label class="pl-4 text-sm">Telefonnr. für Rückfragen</label>
          <input
            class="w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editRequest.phone"
          />
        </div>

        <div v-if="!customer._id">
          <div class="mb-2">
            <label class="pl-4 text-sm">Adresse</label>
            <input
              class="w-full h-12 px-4 rounded-full block"
              type="text"
              v-model="editCustomer.address"
            />
          </div>
          <div class="mb-2">
            <label class="pl-4 text-sm">PLZ</label>
            <input
              class="w-full h-12 px-4 rounded-full block"
              type="text"
              v-model="editCustomer.zip"
            />
          </div>
          <div class="mb-2">
            <label class="pl-4 text-sm">Ort</label>
            <input
              class="w-full h-12 px-4 rounded-full block"
              type="text"
              v-model="editCustomer.city"
            />
          </div>
        </div>
        <div v-if="deviatingOwner">
          <h3 class="text-lg text-center mt-4">Fahrzeughalter</h3>
          <div class="mb-2">
            <label class="pl-4 text-sm">Vorname <sup>*</sup></label>
            <input
              class="firstname w-full h-12 px-4 rounded-full block"
              type="text"
              v-model="editOwner.firstName"
            />
          </div>
          <div class="mb-2">
            <label class="pl-4 text-sm">Nachname <sup>*</sup></label>
            <input
              class="lastname w-full h-12 px-4 rounded-full block"
              type="text"
              v-model="editOwner.lastName"
            />
          </div>
          <p class="pl-4 text-sm opacity-50">* Pflichtfelder</p>
          <div class="mb-2">
            <label class="pl-4 text-sm">Adresse</label>
            <input
              class="w-full h-12 px-4 rounded-full block"
              type="text"
              v-model="editOwner.address"
            />
          </div>
          <div class="mb-2">
            <label class="pl-4 text-sm">PLZ</label>
            <input
              class="w-full h-12 px-4 rounded-full block"
              type="text"
              v-model="editOwner.zip"
            />
          </div>
          <div class="mb-2">
            <label class="pl-4 text-sm">Ort</label>
            <input
              class="w-full h-12 px-4 rounded-full block"
              type="text"
              v-model="editOwner.city"
            />
          </div>
        </div>
      </div>
      <div class="popup-form-error" v-if="error">
        {{ error }}
      </div>

      <div class="flex w-full mt-4 mb-2" :class="{ inactive: !submitAvailable }">
        <div class="w-14 h-14 p-1 rounded-full bg-fiasco-blue" @click="goBack()">
          <img src="@/assets/gfx/arrow-prev.png" />
        </div>
        <button
          v-if="!requestSent"
          :disabled="!submitAvailable"
          class="bg-fiasco-purple p-2 rounded-full text-white flex-grow ml-2 disabled:opacity-50"
          @click="onSubmit()"
        >
          <span v-if="!whitelabel">An meine Werkstatt senden</span>
          <span v-if="whitelabel == 'mobilityconcept'">An Mobility Concept senden</span>
        </button>
        <!--button class="btn-icon round small" @click="closePopup()">
          <img src="@/assets/gfx/icon_check_white.png">
        </button-->
      </div>
    </div>

    <div class="popup" v-if="emailAlreadyExists">
      <h2>Diese E-Mail-Adresse ist schon registriert.</h2>
      <p>
        Bitte gehe zur Startseite und fordere deinen Anmelde-Link an. Sobald du angemeldet bist,
        kannst du die Anfrage erneut unter dieser E-Mail-Adresse stellen.
      </p>
      <button @click="goIntro()">Zur Startseite</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateRequestReady",
  data() {
    return {
      contactPopupVisible: true,
      requestSent: false,
      error: null,
      deviatingOwner: false,
      editRequest: {},
      emailAlreadyExists: false,
      editCustomer: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        city: "",
        zip: "",
        _id: "",
      },
      editOwner: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        zip: "",
      },
    }
  },
  methods: {
    goIntro() {
      this.$router.push({ name: "intro" })
    },

    goMain() {
      this.$router.push({ name: "main" })
    },

    goBack() {
      this.$router.push({ name: "CreateRequestInterview" })
    },

    closePopup() {
      this.contactPopupVisible = false
      // this.$store.commit("SET_BUSY", false);
    },

    async onSubmit() {
      this.$store.commit("SET_BUSY", true)
      if (this.customer._id) {
        await this.updateCustomer()
        console.log("updated customer")
        await this.createCar()
        await this.sendRequest()
      } else {
        this.error = null
        try {
          await this.registerCustomerAndLogin()
          await this.createCar()
          await this.sendRequest()
        } catch (err) {
          this.$store.commit("error/SET_ERROR", true)
          this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
          this.$store.commit("error/SET_ERROR_CODE", err.status)
          this.error = err

          this.$store.commit("SET_BUSY", false)
          throw new Error("Failed to get data from api", err)
        }

        this.$store.commit("SET_BUSY", false)
        // this.$store.commit("SET_BUSY", false);
      }
    },

    async registerCustomerAndLogin() {
      const payload = {
        email: this.editCustomer.email,
        station: this.customerStation._id,
        firstName: this.editCustomer.firstName,
        lastName: this.editCustomer.lastName,
        phone: this.editCustomer.phone,
        address: this.editCustomer.address,
        city: this.editCustomer.city,
        zip: this.editCustomer.zip,
      }

      let response
      try {
        response = await this.$store.dispatch("customers/registerAndLogin", payload)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        if (err?.response?.status === 400) {
          this.emailAlreadyExists = true
          // eslint-disable-next-line no-throw-literal
          throw "Diese E-Mail ist schon registriert."
        } else {
          console.error("registerCustomerAndLogin error:", err)
          // eslint-disable-next-line no-throw-literal
          throw "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später noch einmal."
        }
      }

      if (!response.success) {
        console.error("registerCustomerAndLogin error: unexpected response", response)
        // eslint-disable-next-line no-throw-literal
        throw "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später noch einmal."
      }

      this.editRequest.customer = this.customer._id
      try {
        await this.$store.dispatch("requests/getRequestStates")

        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }

      console.log("new customer registered")
      return response
    },

    async createCar() {
      if (this.createRequestCar) {
        console.log("creating new car")
        const editCar = JSON.parse(JSON.stringify(this.createRequestCar))
        editCar.customer = this.customer._id
        console.dir(editCar)
        if (this.deviatingOwner) {
          editCar.owner.firstName = this.editOwner.firstName
          editCar.owner.lastName = this.editOwner.lastName
          editCar.owner.address = this.editOwner.address
          editCar.owner.city = this.editOwner.city
          editCar.owner.zip = this.editOwner.zip
        } else {
          editCar.owner.firstName = this.editCustomer.firstName
          editCar.owner.lastName = this.editCustomer.lastName
          editCar.owner.address = this.editCustomer.address
          editCar.owner.city = this.editCustomer.city
          editCar.owner.zip = this.editCustomer.zip
        }

        try {
          const res = await this.$store.dispatch("cars/create", editCar)
          this.$store.commit("cars/SET_CREATE_REQUEST_CAR", null)
          this.editRequest.car = res.data
          this.$store.commit("requests/SET_SELECTED_REQUEST", this.editRequest)

          await this.$store.dispatch("cars/getByCustomer", this.customer._id)
          this.$store.commit("error/SET_ERROR", false)
          return res
        } catch (err) {
          this.$store.commit("error/SET_ERROR", true)
          this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
          this.$store.commit("error/SET_ERROR_CODE", err.status)
          this.$store.commit("SET_BUSY", false)
          throw new Error("Failed to get data from api", err)
        }
      } else {
        console.log("using existing car")
        if (this.deviatingOwner) {
          const editCar = {
            _id: this.editRequest.car,
            deviatingOwner: true,
            owner: {
              firstName: this.editOwner.firstName,
              lastName: this.editOwner.lastName,
              address: this.editOwner.address,
              city: this.editOwner.city,
              zip: this.editOwner.zip,
            },
          }

          try {
            await this.$store.dispatch("cars/update", editCar)
            await this.$store.dispatch("cars/getByCustomer", this.customer._id)
            this.$store.commit("error/SET_ERROR", false)
            return true
          } catch (err) {
            this.$store.commit("error/SET_ERROR", true)
            this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
            this.$store.commit("error/SET_ERROR_CODE", err.status)

            this.$store.commit("SET_BUSY", false)
            throw new Error("Failed to get data from api", err)
          }
        } else {
          return new Promise((resolve) => {
            return resolve(1)
          })
        }
      }
    },

    async sendRequest() {
      console.log("sendRequest")
      this.contactPopupVisible = false
      this.$store.commit("SET_BUSY", true)
      this.editRequest.status = "REQUESTED"
      this.editRequest.type = "DIRECT"
      this.editRequest.expressLinkToken = this.expressLinkToken

      try {
        const res = await this.$store.dispatch("requests/create", this.editRequest)
        console.log(res)
        console.log("uploadPhotos")
        console.log(this.uploadPhotos)
        const requestId = res.data
        if (this.uploadPhotos.length > 0) {
          const images = []
          let photoIndex = 0

          // Upload each photo to S3 and store its key in the images array.
          try {
            for await (const photo of this.uploadPhotos) {
              const photoUploadPayload = {
                image: photo,
                key: "images/requests/" + requestId + "-" + photoIndex,
              }
              const uploadRes = await this.$store.dispatch(
                "uploadS3CameraPhoto",
                photoUploadPayload
              )
              console.debug("photo uploaded, key =", uploadRes)
              images.push(uploadRes)
              console.debug("added key to images", images)
              photoIndex++
            }
          } catch (err) {
            this.$store.commit("error/SET_ERROR", true)
            this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
            this.$store.commit("error/SET_ERROR_CODE", err.status)
            this.$store.commit("SET_BUSY", false)
            throw new Error("Failed to upload pictures to s3", err)
          }

          console.debug("image keys", images)

          // Update the request with the storage keys.
          try {
            const updateRequestWithPhotosPayload = {
              _id: requestId,
              s3Images: images,
            }

            await this.$store.dispatch("requests/update", updateRequestWithPhotosPayload)
          } catch (err) {
            this.$store.commit("error/SET_ERROR", true)
            this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
            this.$store.commit("error/SET_ERROR_CODE", err.status)
            this.$store.commit("SET_BUSY", false)
            throw new Error("Failed to upload pictures to s3", err)
          }

          console.log("All photos have been processed successfully")
          console.log("And the voice message is:")
          console.log(this.voiceMessage)
          if (this.voiceMessage) {
            try {
              const voiceMessageUpload = {
                audio: this.voiceMessage,
              }
              const voiceMessageRes = await this.$store.dispatch(
                "requests/uploadVoiceMessage",
                voiceMessageUpload
              )

              const updateRequestWithVoiceMessagePayload = {
                _id: requestId,
                voiceMessage: voiceMessageRes.data,
              }
              await this.$store.dispatch("requests/update", updateRequestWithVoiceMessagePayload)
            } catch (err) {
              this.$store.commit("error/SET_ERROR", true)
              this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
              this.$store.commit("error/SET_ERROR_CODE", err.status)
              this.$store.commit("SET_BUSY", false)
              throw new Error("Error uploading the voice message", err)
            }
          }

          this.finalizeRequestCreation(requestId)
        } else {
          // illegal, photos are needed
          this.closePopup()
        }
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }
      this.$store.commit("SET_BUSY", false)
    },

    async updateCustomer() {
      let res
      try {
        res = await this.$store.dispatch("customers/update", this.editCustomer)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }

      return res
    },

    async finalizeRequestCreation(requestId) {
      console.log(requestId)
      // DISABLED auto-message due to user rigts
      /*
      if (this.customer.selectedStation.contactPerson) {
        let payload = {
          message: "Vielen Dank für die Anfrage! Wir melden uns bald bei dir.",
          requestId: requestId,
          userId: this.customer.selectedStation.contactPerson,
        };
        this.$store.dispatch("requests/sendMessage", payload);
      }
      */
      this.$store.commit("SET_BUSY", true)
      this.$store.commit("requests/SET_REQUEST_PENDING", false)

      try {
        await this.$store.dispatch("requests/getByCustomer", this.customer._id)
        this.requestSent = true
        this.closePopup()
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)

        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }
      this.$store.commit("SET_BUSY", false)
    },

    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
      if (this.customer._id) {
        this.editCustomer._id = this.customer._id
      }
      this.editCustomer.email = this.customer.email
      this.editCustomer.phone = this.customer.phone
      this.editCustomer.firstName = this.customer.firstName
      this.editCustomer.lastName = this.customer.lastName
      this.editCustomer.address = this.customer.address
      this.editCustomer.city = this.customer.city
      this.editCustomer.zip = this.customer.zip
      // sync owner data if present
      let car
      if (this.createRequestCar) {
        car = this.createRequestCar
      } else {
        car = this.customerCars.find((o) => o._id === this.editRequest.car)
      }
      this.editOwner.firstName = car.owner?.firstName
      this.editOwner.lastName = car.owner?.lastName
      this.editOwner.address = car.owner?.address
      this.editOwner.city = car.owner?.city
      this.editOwner.zip = car.owner?.zip
      // }
    },
  },

  computed: {
    submitAvailable() {
      if (this.customer?._id) return true

      const firstNameEntered =
        (this.deviatingOwner && this.editOwner.firstName) ||
        (!this.deviatingOwner && this.editCustomer.firstName)
      const lastNameEntered =
        (this.deviatingOwner && this.editOwner.lastName) ||
        (!this.deviatingOwner && this.editCustomer.lastName)

      return firstNameEntered && lastNameEntered
    },

    ...mapGetters({
      API: "API",
      busy: "busy",
      loginError: "customers/loginError",
      expressLinkToken: "customers/expressLinkToken",
      whitelabel: "whitelabel",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      customerStation: "stations/customerStation",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      cameraPhotos: "requests/cameraPhotos",
      documentPhotos: "requests/documentPhotos",
      uploadPhotos: "requests/uploadPhotos",
      createRequestCar: "cars/createRequestCar",
      voiceMessage: "requests/voiceMessage",
    }),
  },

  mounted() {
    this.getEditData()
    console.log("MOUNTED voice msg:", this.voiceMessage)
  },
}
</script>
