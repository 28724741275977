<template>
  <div class="w-full h-full flex items-center flex-col justify-center">
    <img
      class="absolute top-2 w-full h-16 object-contain z-10"
      v-if="!whitelabel"
      src="@/assets/gfx/fiasco-logo-white.png"
    />
    <div class="max-w-sm w-full flex flex-col p-4">
      <h3 class="text-white text-lg mb-4 text-center">Registrieren per QR Code</h3>

      <div class="relative p-4 aspect-square">
        <div class="absolute top-0 left-0 w-8 h-8 border-t-4 border-l-4 border-fiasco-purple"></div>
        <div
          class="absolute top-0 right-0 w-8 h-8 border-t-4 border-r-4 border-fiasco-purple"
        ></div>
        <div
          class="absolute bottom-0 right-0 w-8 h-8 border-b-4 border-r-4 border-fiasco-purple"
        ></div>
        <div
          class="absolute bottom-0 left-0 w-8 h-8 border-b-4 border-l-4 border-fiasco-purple"
        ></div>
        <div class="rounded w-full aspect-square overflow-hidden">
          <qr-stream
            @decode="onDecode"
            @init="onScannerReady"
            v-if="!isDecoded && !$route.params.code"
          />
        </div>
      </div>

      <div class="my-4 text-white" v-if="customerStation">
        <h2>Code erkannt!</h2>
        <p>Deine Werkstatt:</p>
        <h2>{{ customerStation.name }}</h2>
      </div>

      <p v-if="error" class="p-2 rounded mx-2 text-center my-2 bg-red-700 text-white">
        {{ error }}
      </p>

      <button
        class="rounded-full border-2 border-white text-white text-lg my-4 h-12 shrink-0"
        @click="goBack()"
      >
        Abbrechen
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Intro",
  data() {
    return {
      error: null,
      loginForm: {
        email: "",
        inviteCode: "",
      },
      isDecoded: false,
      invitationInfo: {
        system: "",
        inviteCode: "",
        stationName: "",
        staionId: "",
      },
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "registerMenu" })
    },
    goMain() {
      this.$router.push({ name: "main" })
    },
    async onScannerReady(promise) {
      console.log("onScannerReady")
      // show loading indicator

      try {
        const { capabilities } = await promise
        console.log(capabilities)
        this.$store.commit("SET_BUSY", false)
        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          // user denied camera access permisson
        } else if (error.name === "NotFoundError") {
          // no suitable camera device installed
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
        // this.$store.commit('SET_BUSY', false)
      }
    },
    async onDecode(decodedString) {
      console.log(decodedString)
      console.log(process.env.VUE_APP_BASE_URL_STATION)
      if (
        decodedString.includes(process.env.VUE_APP_BASE_URL_STATION) ||
        decodedString.includes("localhost")
      ) {
        // this.invitationInfo = JSON.parse(decodedString)
        this.loginForm.inviteCode = decodedString.replace(
          `${process.env.VUE_APP_BASE_URL_STATION}/`,
          ""
        )
        try {
          await this.$store.dispatch("stations/getByCode", this.loginForm.inviteCode)
          this.$store.commit("SET_BUSY", false)
          if (this.customerStation) {
            this.isDecoded = true
            /*
              this.loginForm.inviteCode = this.invitationInfo.inviteCode
              this.$store.commit('SET_BUSY', false)
            */

            if (this.customerStation) {
              console.log(this.customerStation)
              this.$store.commit("customers/TEMP_LOGIN")
              this.$emit("login")
            } else {
              this.error = "Keine Werkstatt für diesen Code gefunden."
            }
          } else {
            this.error = "Keine Werkstatt für diesen Code gefunden."
          }
        } catch (err) {
          this.$store.commit("SET_BUSY", false)
          this.$store.commit("error/SET_ERROR", true)
          this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
          this.$store.commit("error/SET_ERROR_CODE", err.status)
          throw new Error("Failed to fetch station", err)
        }
      } else {
        this.error = "Das ist kein FIASCO QR Code."
      }
    },
  },
  computed: {
    submitAvailable() {
      return (
        this.loginForm.inviteCode.length > 3 &&
        this.loginForm.email.includes("@") &&
        this.loginForm.email.length > 4
      )
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerStation: "stations/customerStation",
      whitelabel: "whitelabel",
    }),
  },
  async mounted() {
    console.log("REGI QR MOUNTED")
    if (this.$route.params.code) {
      this.loginForm.inviteCode = this.$route.params.code
      try {
        await this.$store.dispatch("stations/getByCode", this.loginForm.inviteCode)
        if (this.customerStation) {
          this.isDecoded = true
          this.$store.commit("customers/TEMP_LOGIN")
          this.$emit("login")
        }
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("SET_BUSY", false)
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        throw new Error("Failed to fetch station", err)
      }
    } else {
      this.$store.commit("SET_BUSY", true)
    }
  },
}
</script>
